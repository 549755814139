const DB_URL = "https://marty-serial-numbers-map-default-rtdb.firebaseio.com/";

export const mapMartySnToRicSn = async (martySn: string): Promise<string> => {
    try {
        const res = await fetch(`${DB_URL}${martySn}.json`);
        const data = await res.json();
        return data as string;
    } catch (error) {
        console.warn(error);
        return "";
    }
};