import NavMenu from "../NavMenu";
import "./styles.css";
import { ReactComponent as BetaSignSVG } from "../../assets/beta-sign.svg";
import modalState from "../../state-observables/modal/ModalState";
import BugReportModal from "../../components/modals/BugReportModal";
import InstallButton from "../../components/InstallButton";
import { createElement } from "react";

type LayoutProps = {
  children: React.ReactNode;
};

export default function Layout({ children }: LayoutProps) {
  return (
    <main className="main-container">
      <section className="main-navigation-container">
        <NavMenu />
      </section>
      <section className="main-main-content-container">{children}</section>
      <div
        className="beta-sign-icon-container"
        onClick={() =>
          modalState.setModal(createElement(BugReportModal), "Anonymous Bug Report")
        }
      >
        <BetaSignSVG />
      </div>
      <InstallButton />
    </main>
  );
}
