import { CalibrationComponentType } from "../../../navigation/CalibrationNavigator";
import {
  getCalibrationImage,
  getCalibrationStrings,
  Steps,
} from "../../../utils/helpers/calibration-screen";
import SimpleButton from "../../buttons/SimpleButton";
import "../../../screens/Calibration/styles.css";
import { useState } from "react";
import martyConnector from "../../../MartyConnector/MartyConnector";
interface EyeCalibrationScreenProps extends CalibrationComponentType {}

export default function EyeCalibration({
  currentScreenSetter,
}: EyeCalibrationScreenProps) {
  const [isBusy, setBusy] = useState<boolean>(false);
  const onSaveCalibration = async () => {
    try {
      setBusy(true);
      await martyConnector.calibrate("set", "eyes");
      setBusy(false);
    currentScreenSetter("done-calibration");
    } catch {
      console.warn(`Failed to execute calibration step`);
    }
  };
  return (
    <div className="calibration-screen-container">
      <div className="calibration-screen-marty-image-container">
        <img src={getCalibrationImage(Steps.STEP_EYES_01)} alt="marty-image" />
      </div>
      <div className="calibration-screen-middle-text-container">
        <p className="calibration-screen-middle-text-title">
          {getCalibrationStrings(Steps.STEP_EYES_01).title}
        </p>
        <p className="calibration-screen-middle-text-subtitle">
          {getCalibrationStrings(Steps.STEP_EYES_01).hint}
        </p>
        {!isBusy ? (
          <></>
        ) : (
          <p className="calibration-screen-middle-text-subtitle">
            Communicating with your Marty...
          </p>
        )}
      </div>
      <div className="calibration-screen-bottom-buttons-container">
        <SimpleButton title="SAVE CALIBRATION FOR EYES" onClick={onSaveCalibration} />
      </div>
    </div>
  );
}
