import "./styles.css";

import { setStoredAnalyticsConsent } from "../../../utils/analytics/cookies/analytics-consent";

import { PALE_BLUE, BLACK_075, MAIN_BLUE } from "../../../styles/colors";
import SimpleButton from "../../buttons/SimpleButton";
import modalState from "../../../state-observables/modal/ModalState";
import openNewTab from "../../../utils/open-new-tab";
import { AnalyticsConsent, DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist";
import { noConsentDbConfig } from "../../../dbConfigs/configs";
import NoConsentAnalyticsDatabase from "@robotical/analytics-gatherer/dist/database/NoConsentDatabase";

const AnalyticsConsentModal = () => {
  const onConsent = (consentDecision: AnalyticsConsent) => {
    trackConsentChoise(consentDecision);
    const dbManager = DatabaseManager.getInstance();
    // store answer
    setStoredAnalyticsConsent(consentDecision);
    // toggle analytics accordingly
    dbManager.consent = consentDecision; // dbManager.consent will always be defined as "positive" if the serial number is registered in the warranty service, and the corresponding account has agreed to analytics
    // close modal
    modalState.closeModal();
  };

  const trackConsentChoise = (choise: AnalyticsConsent) => {
    const dbManager = DatabaseManager.getInstance();
    dbManager.initializeOrGetDatabase(DatabaseEnum.NO_CONSENT_ANALYTICS, noConsentDbConfig, DatabaseEnum.NO_CONSENT_ANALYTICS)
      .then(database => {
        const databaseTyped = database as NoConsentAnalyticsDatabase;
        databaseTyped.analyticsConsentClicked(choise);
      })
      .catch(err => console.warn("err", err));
  }

  return (
    <div className="analytics-consent-modal-container">
      <div className="analytics-consent-modal-info-container">
        <p className="analytics-consent-modal-info-paragraph">
          To help us make Marty better, we’d like to gather some info about your
          Marty and what features of the app you use. Using this app requires us
          to store a session cookie in your device storage. The cookie doesn’t
          contain any personal information and simply lets our app remember your
          device between sessions.
        </p>
        <p className="analytics-consent-modal-info-paragraph">
          All data is strictly anonymous, confidential, and will never be shared
          outside of Robotical.
        </p>
        <p className="analytics-consent-modal-info-paragraph">
          Any questions, let us know at hello@robotical.io.
        </p>
        <p className="analytics-consent-modal-info-paragraph">
          <b style={{ color: "black" }}>If your Marty is registered with our warranty service, the data collection consent is managed through your <a style={{ color: MAIN_BLUE }} href="https://service-program-dashboard.robotical.io" target="_blank" rel="noreferrer">Warranty Dashboard</a></b>.
        </p>
      </div>
      <div className="analytics-consent-modal-buttons-container">
        <div className="analytics-consent-modal-buttons-yes-button">
          <SimpleButton
            title="YES, I ACCEPT!"
            onClick={() => onConsent("positive")}
            titleSize="2.5rem"
          />
        </div>
        <div className="analytics-consent-modal-buttons-no-button">
          <SimpleButton
            title="NO, I DON'T ACCEPT"
            onClick={() => onConsent("negative")}
            titleSize="1.5rem"
          />
        </div>
        <div className="analytics-consent-modal-buttons-info-button">
          <SimpleButton
            title="MORE INFORMATION"
            onClick={() =>
              openNewTab("https://marty-webapp.web.app/privacy-policy")
            }
            titleSize="1.5rem"
            textColour={BLACK_075}
            colour={PALE_BLUE}
          />
        </div>
      </div>
    </div>
  );
};

export default AnalyticsConsentModal;
