import { FunctionComponent, SVGProps } from "react";
import "./styles.css";

interface NavButtonProps {
  title: string;
  ImageComponent: FunctionComponent<SVGProps<SVGSVGElement>>;
  onClick?: () => void;
  resizeTitle?: boolean;
}

const NavButton = ({ title, ImageComponent, onClick, resizeTitle }: NavButtonProps) => {
  return (
    <div className="nav-button-container" onClick={onClick}>
      <div className="nav-button-image-container">
        <ImageComponent />
      </div>
      <p className={resizeTitle ? `nav-button-title-resize` : `nav-button-title`}>{title}</p>
    </div>
  );
};

export default NavButton;
