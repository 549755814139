import randomHashGenerator from "../helpers/randomHashGenerator";

const PROJECT_ID = "/sound-streaming-analytics-default-rtdb";
const REGION = "europe-west1";

export const DB_URL = `https://${PROJECT_ID}.${REGION}.firebasedatabase.app/`;

export const instanceTitleBuilder = () => {
    return randomHashGenerator();
};


