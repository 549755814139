import { useEffect } from "react";
import Layout from "../../layout/Layout";
import openNewTab from "../../utils/open-new-tab";
import "./styles.css";
import {
  Card,
  CardTitleContainer,
  CardTitleText,
  CardTextContainer,
  CardText,
  RedText,
  CardImage,
  BottomRowContainer,
  BottomText,
  Container
} from "./styles";

import {ReactComponent as ColouredCardsImg} from "../../assets/unplugged-screen/colourCards.svg";
import {ReactComponent as UnpluggedButtonImg} from "../../assets/unplugged-screen/UnpluggedButtonHowTo.svg";
import {ReactComponent as UserGuidesButtonImg} from "../../assets/unplugged-screen/UserGuideButton.svg";
import { AppDatabase, DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist";
import { appConfig } from "../../dbConfigs/configs";

interface UnpluggedScreenProps {}

export default function UnpluggedScreen({}: UnpluggedScreenProps) {
  useEffect(() => {
    // Analytics
    const dbManager = DatabaseManager.getInstance();
    dbManager.initializeOrGetDatabase(DatabaseEnum.APP, appConfig, DatabaseEnum.APP)
        .then((db) => {
            (db as AppDatabase).storeVisitedScreen("unplugged");
        }).catch((err) => console.log(err));
  }, []);

  return (
    <Layout>
      <Container >
          <Card>
            <CardTitleContainer>
              <CardTitleText>What is Unplugged Mode?</CardTitleText>
            </CardTitleContainer>
            <CardTextContainer>
              <CardText>
                Unplugged Mode allows you to control Marty’s movements without
                the need for a device, using coloured cards and Marty’s Colour
                Sensor! By placing the cards down in your desired sequence, you
                can create custom pathways for Marty to follow. Each robot has 5
                card colours to choose from, see them here:
              </CardText>
              <CardImage>
                <ColouredCardsImg width="100%" height="100%" />
              </CardImage>
            </CardTextContainer>
          </Card>
          <Card>
            <CardTitleContainer>
              <CardTitleText>How to use Unplugged Mode?</CardTitleText>
            </CardTitleContainer>
            <CardTextContainer>
              <CardText>
                <RedText> Step 1:</RedText> &nbsp;Ensure Marty is switched on.
                If not, press the power button to switch Marty on.
              </CardText>
              <CardText>
                <RedText> Step 2:</RedText> &nbsp;Turn Unplugged mode on by
                pressing the “A” button
              </CardText>
              <CardText>
                <RedText> Step 3:</RedText> &nbsp;Simply set the cards down in
                your desired sequence and place Marty on the first card to get
                Marty moving!
              </CardText>
              <CardImage>
                <UnpluggedButtonImg width="600px" height="100%" />
              </CardImage>
            </CardTextContainer>
          </Card>
          <BottomRowContainer>
            <BottomText>
              For more information, see our user guide: &nbsp; &nbsp;
            </BottomText>
              <UserGuidesButtonImg width="100%" height="80%"  onClick={() => openNewTab("https://userguides.robotical.io/martyv2/userguides/unplugged")}/>
          </BottomRowContainer>
      </Container>
    </Layout>
  );
}
