import React from 'react';
import styles from './styles.module.css';

const AddMoreRobotsInfo: React.FC = () => {
  return (
    <div className={styles.infoContainer}>
      <h2 className={styles.title}>Effortlessly add multiple robots to the same warranty account by following these <b>simple steps</b> 🤖✨</h2>
      <p className={styles.paragraph}>Turn on your Marty, click the <b>"Add Another Robot"</b> button and select it from the list of available devices. </p>
      <p className={styles.paragraph}>Repeat this process for each additional robot you wish to associate with the warranty account.</p>
    </div>
  );
};

export default AddMoreRobotsInfo;
