import { useState } from "react";
import SimpleButton from "../../components/buttons/SimpleButton";
import { ConfigurationComponentType } from "../../navigation/ConfigurationNavigator";
import "./styles.css";

interface WifiConfStep1aScreenProps extends ConfigurationComponentType {}

export default function WifiConfStep1aScreen({
  currentScreenSetter,
}: WifiConfStep1aScreenProps) {
  const [ssidName, setSsidName] = useState("");

  return (
    <div className="wifi-conf-step-one-container">
      <div>
        <p className="wifi-conf-step-one-title">STEP 1a.</p>
        <p className="wifi-conf-step-one-subtitle">Connecting Marty to</p>
        <p className="wifi-conf-step-one-a-ssid-title">WiFi Network Name (SSID)</p>
        <input
          type="text"
          placeholder="SSID"
          className="wifi-conf-step-one-a-ssid-input-field"
          value={ssidName}
          onChange={(e) => setSsidName(e.target.value)}
        />
      </div>
      <div className="wifi-conf-step-one-bottom-buttons-container">
        <SimpleButton title="Continue" onClick={() => currentScreenSetter("wifi-configuration-step-2", {ssid: ssidName})} />
      </div>
    </div>
  );
}
