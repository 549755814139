import "./styles.css";
import { ReactComponent as HelpSVG } from "../../assets/nav-buttons/help.svg";
import { ReactComponent as ConfigureSVG } from "../../assets/nav-buttons/configure.svg";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import MartyConnectionContext from "../../store/marty-connection-context";
import connectToMarty from "../../utils/network-api/connect-to-marty-function";

export default function () {
  const navigate = useNavigate();
  const connectionCtx = useContext(MartyConnectionContext);
  const setConnectionLoading = connectionCtx.setIsConnectionLoading;

  const onConfigurationClick = async () => {
    // return navigate("/configuration");
    if (connectionCtx.isConnected) {
      navigate("/configuration");
    } else {
      setConnectionLoading(true);
      try {
        await connectToMarty();
        setConnectionLoading(false);
        navigate("/configuration");
      } catch (e) {
        setConnectionLoading(false);
      }
    }
  };

  return (
    <div className="help-section-container">
      <div
        className="help-section-help-svg-container"
        onClick={() => navigate("/help")}
      >
        <HelpSVG />
      </div>
      <div
        className="help-section-configure-svg-container"
        onClick={onConfigurationClick}
      >
        <ConfigureSVG />
      </div>
    </div>
  );
}
