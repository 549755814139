import React from 'react';
import styles from './styles.module.css';

const AddMoreRobotsInfo: React.FC = () => {
  return (
    <div className={styles.infoContainer}>
      {/* <h2 className={styles.title}>Effortlessly add multiple robots to the same warranty account by following these <b>simple steps</b> 🤖✨</h2> */}
      <p className={styles.paragraph}>Please scan the code on the robot (if there is no code, please use the Bluetooth method)</p>
    </div>
  );
};

export default AddMoreRobotsInfo;
