/**
 * The ReportedWarningsState class is used to store the reported warnings from Marty.
 * One of the main purposes of this class is to prevent duplicate warnings from being
 * displayed to the user.
 */


import { RICLog } from "@robotical/ricjs";

// we won't store these warnings in the cached warnings state, and so they won't be displayed or stored to the warranty service db, only to the general db
const ERRORS_TO_IGNORE = [
  // eyes faulty connection 
  "Eyes Servo Fault Detected: Faulty drive Connection",
  "8 Servo Fault Detected: Faulty drive Connection",

  "Eyes Servo Fault Detected: Faulty drive connection",
  "8 Servo Fault Detected: Faulty drive connection",

  // right arm faulty connection
  "RightArm Servo Fault Detected: Faulty drive Connection",
  "7 Servo Fault Detected: Faulty drive Connection",

  "RightArm Servo Fault Detected: Faulty drive connection",
  "7 Servo Fault Detected: Faulty drive connection",

  // left arm faulty connection
  "LeftArm Servo Fault Detected: Faulty drive Connection",
  "6 Servo Fault Detected: Faulty drive Connection",

  "LeftArm Servo Fault Detected: Faulty drive connection",
  "6 Servo Fault Detected: Faulty drive connection",

  // all servo horn position errors
  "Servo Horn Position Error"
];

type CachedWarning = {
    timestamp: number;
};

export class ReportedWarningsState {
  private static instance: ReportedWarningsState;
  private reportedWarnings: Map<string, object>;
  private cachedWarnings: Map<string, CachedWarning[]>;

  private constructor() {
    this.reportedWarnings = new Map<string, object>(); // warnings that have been reported to the user
    this.cachedWarnings = new Map<string, CachedWarning[]>(); // warnings from the robot in the last 5 minutes
  }

  static getInstanceOrInstantiate() {
    if (!ReportedWarningsState.instance) {
      ReportedWarningsState.instance = new ReportedWarningsState();
    }
    return ReportedWarningsState.instance;
  }

  static shouldIgnoreWarning(warning: string): boolean {
    let shouldIgnore = false;
    ERRORS_TO_IGNORE.forEach((error) => {
      if (warning.toLowerCase().includes(error.toLowerCase())) {
        shouldIgnore = true;
      }
    });
    return shouldIgnore;
  }

  /**
   * This method is used to add a warning to the reported warnings state.
   * @param warning The warning to be added to the reported warnings state.
   * @returns true if the warning was added to the reported warnings state, false otherwise.
   */
  addWarning(warning: string): boolean {
    if (this.reportedWarnings.has(warning)) {
      // If the warning has already been added, do not add it again.
      return false;
    } else {
      this.reportedWarnings.set(warning, {});
    }
    return true;
  }

  /**
   * This method is used to remove a warning from the reported warnings state.
   * @param warning The warning to be removed from the reported warnings state.
   * @returns true if the warning was removed from the reported warnings state, false otherwise.
   * */
  removeWarning(warning: string): boolean {
    if (this.reportedWarnings.has(warning)) {
      this.reportedWarnings.delete(warning);
      return true;
    }
    return false;
  }

  /**
   * This method is used to clear all warnings from the reported warnings state.
   * */
  clearWarnings(): void {
    this.reportedWarnings.clear();
  }

  /**
   * This method is used to get the reported warnings state.
   * @returns The reported warnings state.
   * */
  getReportedWarnings(): Map<string, object> {
    return this.reportedWarnings;
  }

  /**
   * This method is used to set the reported warnings state.
   * @param reportedWarnings The reported warnings state to be set.
   * */
  setReportedWarnings(reportedWarnings: Map<string, object>): void {
    this.reportedWarnings = reportedWarnings;
  }

  /**
   * This method is used to check if a warning exists in the reported warnings state.
   * @param warning The warning to be checked.
   * @returns true if the warning exists in the reported warnings state, false otherwise.
   * */
  warningExists(warning: string): boolean {
    return this.reportedWarnings.has(warning);
  }

  /**
   * This method is used to add a warning to the cached warnings state.
   * @param warning The warning to be added to the cached warnings state.
   * @returns true if the warning was added to the cached warnings state, false otherwise.
   * */
  addCachedWarning(warningsKey: string): boolean {
    if (this.cachedWarnings.has(warningsKey)) {
      const cachedWarningsArr = this.cachedWarnings.get(warningsKey);
      if (cachedWarningsArr) {
        RICLog.debug("addCachedWarning -- adding cached warning, cachedWarningsArr.length: " + cachedWarningsArr.length);
        cachedWarningsArr.push({ timestamp: Date.now() });
      }
    } else {
      RICLog.debug("addCachedWarning -- adding cached warning first time");
      this.cachedWarnings.set(warningsKey, [{ timestamp: Date.now() }]);
    }
    return true;
  }

  /**
   * This method is used to check if there are x number of warnings let last y minutes.
   * @param warningsKey The key to the cached warnings state.
   * @param numWarnings The number of warnings to check for.
   * @param minutes The number of minutes to check for.
   * @returns true if there are x number of warnings let last y minutes, false otherwise.
   * */
  hasNumWarningsInLastMinutes(
    warningsKey: string,
    numWarnings: number,
    minutes: number
  ): boolean {
    if (this.cachedWarnings.has(warningsKey)) {
      const cachedWarningsArr = this.cachedWarnings.get(warningsKey);
      if (cachedWarningsArr) {
        const now = Date.now();
        const lastMinutes = now - minutes * 60 * 1000;
        const filteredWarnings = cachedWarningsArr.filter(
          (warning) => warning.timestamp > lastMinutes
        );
        RICLog.debug("hasNumWarningsInLastMinutes -- filteredWarnings.length: " + filteredWarnings.length);
        RICLog.debug(`hasNumWarningsInLastMinutes -- has ${numWarnings} warnings in last ${minutes} mins ` + (filteredWarnings.length >= numWarnings ? "true" : "false") );
        return filteredWarnings.length >= numWarnings;
      }
    }
    RICLog.debug("hasNumWarningsInLastMinutes -- does not have cached warnings");
    return false;
  }
}
