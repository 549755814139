import styles from "./styles.module.css";
import martyConnector from "../../MartyConnector/MartyConnector";
import { getDeviceId } from "../../utils/analytics/cookies/device-id";
import { useEffect, useState } from "react";

const ENV: "production" | "development" = "production";

const appNewsHubLinks = {
  production: "https://app-news-hub.web.app/",
  development: "http://localhost:3001/",
};

const NewsHub = () => {
  const [paramsStr, setParamsStr] = useState("");
  const [isOnline, setIsOnline] = useState(false);

  const networkCheck = () => {
    fetch(appNewsHubLinks[ENV], {
      mode: "no-cors",
      cache: 'no-cache',
    })
      .then(() => {
        setIsOnline(true);
      })
      .catch(() => {
        setIsOnline(false);
      });
  }

  useEffect(() => {
    networkCheck();
  }, []);

  useEffect(() => {
    const asyncFunc = async () => {
      const params = await paramsBuilder();
      setParamsStr(params);
    }
    asyncFunc();
  });

  const link = appNewsHubLinks[ENV];
  const contentJSX = <iframe className={styles.iframe} src={`${link}${paramsStr}&t=${new Date().getTime()}`} title="News and Updates"></iframe>;

  return (
    <div className={styles.container}>
      {isOnline && contentJSX}
    </div>
  );
};

export default NewsHub;

async function paramsBuilder() {
  let paramsStr = "?";
  if (martyConnector.martySerialNo) {
    paramsStr += `serialNumber=${martyConnector.martySerialNo}`;
  }
  if (martyConnector.martyVersion) {
    paramsStr += `&martyVersion=${martyConnector.martyVersion}`;
  }
  paramsStr += `&appVersion=webapp`;
  const deviceId = await getDeviceId();
  if (deviceId) {
    paramsStr += `&deviceId=${deviceId}`;
  }
  return paramsStr;
}