import React from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import styles from "./styles.module.css";
import martyConnector from "../../../MartyConnector/MartyConnector";
import ms from "../../../state-observables/modal/ModalState";

const UpdateRequiredModal: React.FC = () => {
  const handleDisconnect = async () => {
    await martyConnector.disconnect();
    ms.closeModal();
  };

  return (
    <div className={styles.container}>
      <div className={styles.modalHeader}>
        <FaExclamationTriangle className={styles.warningIcon} />
        Update Required
      </div>
      <div className={styles.modalBody}>
        This Marty needs to be updated before you can use it with the app.<br/>
        Please update your Marty using the{" "}
        <a
          href="https://updates.robotical.io/web-updater"
          target="_blank"
          rel="noopener noreferrer"
        >
          Marty Updater
        </a>
        .
      </div>
      <div className={styles.modalFooter}></div>
      <button className={styles.disconnectButton} onClick={handleDisconnect}>
        Disconnect From Marty
      </button>
    </div>
  );
};

export default UpdateRequiredModal;
