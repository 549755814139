
import './styles.css';

interface TextInfoProps {
  label?: string;
  info?: string;
}

const LabeledInfo = ({ label = '', info = '' }: TextInfoProps) => (
  <div className='labeled-info-container'>
    <p className='labeled-info-label'>{label}</p>
    <p className='labeled-info-info'>{info}</p>
  </div>
);

export default LabeledInfo;
