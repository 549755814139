import { AppDatabase, DatabaseEnum, DatabaseManager, RobotDatabase } from "@robotical/analytics-gatherer";
import SensorDashboardDatabase from "@robotical/analytics-gatherer/dist/database/SensorDashboardDatabase";
import { appConfig, sensorsDashboardSessiosnFirebaseConfig as dbConfig, martyBlocksSessiosnFirebaseConfig, robotConfig } from "../dbConfigs/configs";
import MartyBlocksDatabase from "@robotical/analytics-gatherer/dist/database/MartyBlocksDatabase";


export default class SensorDashboardSessions {

    public static async startSession() {
        const dbManager = DatabaseManager.getInstance();
        const db = await dbManager.initializeOrGetDatabase(DatabaseEnum.SENSORS_DASHBOARD, dbConfig, DatabaseEnum.SENSORS_DASHBOARD) as SensorDashboardDatabase;
        const appDb = await dbManager.initializeOrGetDatabase(DatabaseEnum.APP, appConfig, DatabaseEnum.APP) as AppDatabase;
        const robotDb = await dbManager.initializeOrGetDatabase(DatabaseEnum.ROBOT, robotConfig, DatabaseEnum.ROBOT) as RobotDatabase;
        const martyBlocksDb = await dbManager.initializeOrGetDatabase(DatabaseEnum.MARTY_BLOCKS, martyBlocksSessiosnFirebaseConfig, DatabaseEnum.MARTY_BLOCKS) as MartyBlocksDatabase;
        const appSessionId = appDb.dataToStore.sessionId;
        const martyBlocksSessionId = martyBlocksDb.dataToStore.sessionId;
        const robotSessionId = robotDb.dataToStore.sessionId;
        await db.startSession(appSessionId, martyBlocksSessionId, robotSessionId);
    }

    public static async endSession() {
        const dbManager = DatabaseManager.getInstance();
        const db = await dbManager.initializeOrGetDatabase(DatabaseEnum.SENSORS_DASHBOARD, dbConfig, DatabaseEnum.SENSORS_DASHBOARD) as SensorDashboardDatabase;
        db.endSession();
    }

    public static async setSessionToActive() {
        const dbManager = DatabaseManager.getInstance();
        const db = await dbManager.initializeOrGetDatabase(DatabaseEnum.SENSORS_DASHBOARD, dbConfig, DatabaseEnum.SENSORS_DASHBOARD) as SensorDashboardDatabase;
        await db.setIsActive(true);
    }
}