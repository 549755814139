import { useContext, useState } from "react";
import martyConnector from "../../MartyConnector/MartyConnector";
import MartyConnectionContext from "../../store/marty-connection-context";
import SimpleButton from "../buttons/SimpleButton";
import "./styles.css";

export default function UpdateMartyName() {
  const connectionCtx = useContext(MartyConnectionContext);
  const [martyName, setMartyName] = useState(connectionCtx.martyName);

  const onChangeName = () => {
    martyConnector.setRICName(martyName);
  };

  return (
    <div className="update-marty-name-container">
      <p className="update-marty-name-title">Name your Marty</p>
      <input
        type="text"
        className="update-marty-name-input-field"
        value={martyName}
        onChange={(e) => setMartyName(e.target.value)}
      />
      <div className="update-marty-button-container">
        <SimpleButton onClick={onChangeName} title="UPDATE NAME" />
      </div>
    </div>
  );
}
