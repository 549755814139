/**
    Handling service program cookies
*/

const onboardingCookieName = '@serviceProgramOnboarding';
const onboardingCookieExpiration = 20; // days

type OnboardingCookie = {
    serialNumbers: Array<string>;
    expires: string;
};

export function setOnboardingCookie(serialNumber: string) {
    LocalStorageManager.setItem(onboardingCookieName, { serialNumbers: [serialNumber] }, onboardingCookieExpiration);
}

export function getOnboardingCookie() {
    return LocalStorageManager.getItem(onboardingCookieName) as OnboardingCookie | undefined;
}

export function isOnboardingCookieExpired() {
    const onboardingCookie = getOnboardingCookie();
    if (onboardingCookie) {
        return LocalStorageManager.isDataExpired(onboardingCookie.expires);
    }
    return true;
}

export function appendSerialNumberToOnboardingCookie(serialNumber: string) {
    const onboardingCookie = getOnboardingCookie();
    if (onboardingCookie) {
        if (onboardingCookie.serialNumbers.includes(serialNumber)) return;
        onboardingCookie.serialNumbers.push(serialNumber);
        LocalStorageManager.setItem(onboardingCookieName, onboardingCookie, onboardingCookieExpiration);
    } else {
        LocalStorageManager.setItem(onboardingCookieName, { serialNumbers: [serialNumber] }, onboardingCookieExpiration);
    }
}

export function deleteOnboardingCookie() {
    LocalStorageManager.removeItem(onboardingCookieName);
}

export class LocalStorageManager {
    static setItem(name: string, value: object, expirationDays: number) {
        const date = new Date();
        date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
        const expires = date.toUTCString();
        const data = { ...value, expires };
        localStorage.setItem(name, JSON.stringify(data));
    }

    static getItem(name: string) {
        const dataString = localStorage.getItem(name);
        if (dataString) {
            const data = JSON.parse(dataString);
            return data;
        }
        return '';
    }

    static removeItem(name: string) {
        localStorage.removeItem(name);
    }

    static isDataExpired(dataExpiration: string) {
        const expirationDate = new Date(dataExpiration);
        const now = new Date();
        if (expirationDate < now) {
            return true;
        }
        return false;
    }
}


