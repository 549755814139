import { useState } from "react";
import SimpleButton from "../../components/buttons/SimpleButton";
import { ConfigurationComponentType } from "../../navigation/ConfigurationNavigator";
import "./styles.css";

interface WifiConfStepFailedToConnectProps extends ConfigurationComponentType {
  ssid: string;
}

export default function WifiConfStepFailedToConnect({
  currentScreenSetter,
  ssid,
}: WifiConfStepFailedToConnectProps) {

  return (
    <div className="wifi-conf-step-one-container">
      <div>
        <p className="wifi-conf-step-one-title">STEP 3</p>
        <p className="wifi-conf-step-one-subtitle">
          Failed to connect to{" "}
          <span className="wifi-conf-step-two-ssid-name">{ssid} </span>
          with the password you entered.
        </p>
        <p className="wifi-conf-step-one-subtitle">
          Re-enter the password or go back and try a different network.
        </p>
      </div>
        <div className="wifi-conf-step-one-bottom-buttons-container">
          <SimpleButton title="RE-ENTER WIFI PASSWORD" onClick={() => currentScreenSetter("wifi-configuration-step-2", {ssid})} />
          <SimpleButton title="SELECT DIFFERENT WIFI" onClick={() => currentScreenSetter("wifi-configuration-step-1")} />
        </div>
    </div>
  );
}
