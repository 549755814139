import "./styles.css";

type SimpleButtonProps = {
  onClick: () => void;
  title: string;
  titleSize?: string;
  disabled?: boolean;
  colour?: string;
  otherStyles? :React.CSSProperties;
  borderColour?: string;
  textColour?: string;
};

export default function SimpleButton({
  onClick,
  title,
  titleSize,
  disabled,
  colour,
  otherStyles,
  borderColour,
  textColour
}: SimpleButtonProps) {
  return (
    <div
      className={
        "simple-button-component " +
        (disabled ? "simple-button-component-disabled" : "")
      }
      style={{
        ...otherStyles,
        fontSize: titleSize,
        backgroundColor: colour,
        border: borderColour && `1px solid ${borderColour}`,
        color: textColour
      }}
      onClick={disabled ? () => {} : onClick}
    >
      {title}
    </div>
  );
}
