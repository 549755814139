import { useState } from 'react';
import styles from './styles.module.css';
import QRContent from './QRContent';
import BluetoothContent from './BluetoothContent';
import { toast } from 'react-toastify';
import { secondaryModalState } from '../../../state-observables/modal/ModalState';
import { FaTrashAlt } from 'react-icons/fa';
import martyConnector from '../../../MartyConnector/MartyConnector';
import { RICChannelWebBLE } from '@robotical/ricjs';

function ServiceProgramAddMoreRobots() {
  const [content, setContent] = useState<"bluetooth" | "qr">("qr");
  const [items, setItems] = useState<(string | BluetoothDevice)[]>([]);

  const scanForNewDevice = async () => {
    // get the name of the already connected device first
    const namesToIgnore = [...items.map((item) => {
      if (typeof item === "string") {
        return { name: item }
      } else {
        return { name: item.name }
      }
    }), { name: martyConnector.RICFriendlyName }];

    try {
      const device = await navigator.bluetooth.requestDevice({
        // acceptAllDevices: true,
        filters: [
          { services: [RICChannelWebBLE.RICServiceUUID] },
        ],
        // @ts-ignore
        exclusionFilters: namesToIgnore,
        optionalServices: [],
      });
      // add the device to the list of devices only if it's not already there
      if (!items.find((deviceInList) => {
        if (typeof deviceInList === "string") {
          return deviceInList === device.id
        } else {
          return deviceInList.id === device.id
        }
      })) {
        setItems((prevItems) => [...prevItems, device]);
        toast.success(`${device.name} added to the list of robots.`, {
          autoClose: 1000,
        });
      } else {
        toast.error(`${device.name} is already in the list of robots.`, {
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.warn(error);
    }

  };

  const removeItem = (itemToBeRemoved: string | BluetoothDevice) => {
    setItems((oldItems) => {
      if (typeof itemToBeRemoved === "string") {
        return oldItems.filter((serialNumber) => serialNumber !== itemToBeRemoved);
      } else {
        return (oldItems as BluetoothDevice[]).filter((device) => device.id !== itemToBeRemoved.id);
      }
    });
    const nameOrSerial = typeof itemToBeRemoved === "string" ? "Serial Number" : "Robot";
    toast.success(`${nameOrSerial} removed from the list.`, {
      autoClose: 1000,
    });
  };

  const onCancel = () => {
    secondaryModalState.closeModal([]);
  };

  const onSubmit = () => {
    secondaryModalState.closeModal(items);
  };

  const onBluetoothContent = () => {
    setContent("bluetooth");
  }

  const onQRContent = () => {
    setContent("qr");
  }

  return (
    <div className={styles.container}>
      {content === "qr" ?
        <QRContent setItems={setItems} />
        :
        <BluetoothContent />
      }
      <ul className={styles.deviceList}>
        <h3>Serial Numbers / Names:</h3>
        {items.map((item) => {
          const key = typeof item === "string" ? item : item.id;
          return (
            <li key={key} className={styles.deviceItem}>
              {typeof item === "string" ? item : item.name}
              <span className={styles.removeButton} onClick={() => removeItem(item)}>
                <FaTrashAlt />
              </span>
            </li>
          )
        })}
      </ul>
      <div className={styles.buttonsContainer}>
        <button type="button" className={styles.submitButton} onClick={onSubmit}>Submit</button>
        <button type="button" className={styles.cancelButton} onClick={onCancel}>Cancel</button>
        {content === "bluetooth" && <button className={styles.addAnotherRobotButton} onClick={scanForNewDevice}>Add Another Robot via Bluetooth</button>}
        {content === "qr" && <button className={styles.addRobotsBtButton} onClick={onBluetoothContent}>Add Robots via Bluetooth</button>}
        {content === "bluetooth" && <button type="button" className={styles.addRobotsQRButton} onClick={onQRContent}>Add Robots via QR Code</button>}
      </div>
    </div>
  );
};

export default ServiceProgramAddMoreRobots;
