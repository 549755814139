import SD from "@robotical/sensors-dashboard/dist/App";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NavLayout from "../../layout/NavLayout";
import martyConnector from "../../MartyConnector/MartyConnector";
import SensorsDashboardConnector from "../../MartyConnector/SensorsDashboardConnector";
import { AnalyticsVisitedScreens, AppDatabase, DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist";
import { appConfig } from "../../dbConfigs/configs";

function SensorsDashboard() {
  const navigate = useNavigate();

  if (window.mv2Dashboard) {
    window.mv2Dashboard.setIsModal(false);
  }

  useEffect(() => {
    // Analytics
    const dbManager = DatabaseManager.getInstance();
    dbManager.initializeOrGetDatabase(DatabaseEnum.APP, appConfig, DatabaseEnum.APP)
      .then((db) => {
        (db as AppDatabase).storeVisitedScreen("sensors-dash");
      }).catch((err) => console.log(err));
      
    martyConnector.sessionDbs?.SensorDash.startSession();

    return () => {
      martyConnector.sessionDbs?.SensorDash.endSession();
    }
  }, []);

  useEffect(() => {
    const sensDashConnector = new SensorsDashboardConnector();
    sensDashConnector.setApp(window.mv2Dashboard);
    martyConnector.addUpdaterRemover(
      sensDashConnector.clearUpdater.bind(sensDashConnector)
    );

    return () => {
      sensDashConnector.clearUpdater();
    };
  }, []);

  return (
    <NavLayout onClickBack={() => navigate("/")}>
      <SD />
    </NavLayout>
  );
}

export default SensorsDashboard;
