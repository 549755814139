import { AppDatabase, DatabaseEnum, DatabaseManager, RobotDatabase } from "@robotical/analytics-gatherer";
import MartyBlocksDatabase from "@robotical/analytics-gatherer/dist/database/MartyBlocksDatabase";
import { appConfig, martyBlocksSessiosnFirebaseConfig as dbConfig, robotConfig } from "../dbConfigs/configs";


export default class MartyBlocksSessions {

    public static async startSession() {
        const dbManager = DatabaseManager.getInstance();
        const db = await dbManager.initializeOrGetDatabase(DatabaseEnum.MARTY_BLOCKS, dbConfig, DatabaseEnum.MARTY_BLOCKS) as MartyBlocksDatabase;
        const appDb = await dbManager.initializeOrGetDatabase(DatabaseEnum.APP, appConfig, DatabaseEnum.APP) as AppDatabase;
        const robotDb = await dbManager.initializeOrGetDatabase(DatabaseEnum.ROBOT, robotConfig, DatabaseEnum.ROBOT) as RobotDatabase;
        const appSessionId = appDb.dataToStore.sessionId;
        const robotSessionId = robotDb.dataToStore.sessionId;
        try {
            await db.startSession(appSessionId, robotSessionId);
        } catch (err) {
            console.error(err);
        }
    }

    public static async endSession() {
        const dbManager = DatabaseManager.getInstance();
        const db = await dbManager.initializeOrGetDatabase(DatabaseEnum.MARTY_BLOCKS, dbConfig, DatabaseEnum.MARTY_BLOCKS) as MartyBlocksDatabase;
        db.endSession();
    }

    public static async storeUsedBlock(blockName: string) {
        const dbManager = DatabaseManager.getInstance();
        const db = await dbManager.initializeOrGetDatabase(DatabaseEnum.MARTY_BLOCKS, dbConfig, DatabaseEnum.MARTY_BLOCKS) as MartyBlocksDatabase;
        await db.addUsedBlock(blockName);
    }
}