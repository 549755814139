import {
  CenteredView,
  StyledView,
  ModalText,
  ModalTitle,
  StyledImage,
} from "./styles";
import imageAbutton from "../../../assets/marty-a-button.png";
import martyConnector from "../../../MartyConnector/MartyConnector";
import SimpleButton from "../../buttons/SimpleButton";
import modalState from "../../../state-observables/modal/ModalState";


export default function InUnpluggedModalContent() {
  // disconnecting from marty as soon as
  // the modal comes up because some users might
  // try pressing A when the modal is still up.
  martyConnector.disconnect();

  return (
    <StyledView>
      <ModalTitle>Couldn't connect to your Marty!</ModalTitle>
      <ModalText>
        Your Marty is currently in unplugged mode. If you want to dissconect
        Marty from unplugged mode, please press the "MODE"/"A" button on the back of
        Marty and try again!
      </ModalText>
      <StyledImage src={imageAbutton}/>
      <CenteredView>
        <SimpleButton title="Got it!" onClick={() => modalState.closeModal()} />
      </CenteredView>
    </StyledView>
  );
}
