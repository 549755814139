import { createContext, useState } from "react";

export type DefferedPromptContextTypes = {
    deferredPrompt: Event | null;
    setDeferredPrompt: (prompt: Event | null) => void;
};

const DefferedPromptContext = createContext<DefferedPromptContextTypes>({
    deferredPrompt: null,
    setDeferredPrompt: () => { },
});

type DefferedPromptContextProviderTypes = {
    children: React.ReactNode;
};

export function DefferedPromptContextProvider({
    children,
}: DefferedPromptContextProviderTypes) {
    const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);

    const setDeferredPromptHandler = (prompt: Event | null) => {
        setDeferredPrompt(prompt);
    };

    const context = {
        deferredPrompt: deferredPrompt,
        setDeferredPrompt: setDeferredPromptHandler,
    };

    return (
        <DefferedPromptContext.Provider value={context}>
            {children}
        </DefferedPromptContext.Provider>
    );
}

export default DefferedPromptContext;
