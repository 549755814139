import { FaWrench } from "react-icons/fa";
import styles from "./styles.module.css";
import { secondaryModalState } from "../../../state-observables/modal/ModalState";

function ServiceProgramComingSoonModal() {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        <FaWrench className={styles.icon} /> Introducing Our New Warranty
        Service Program! <FaWrench className={styles.icon} />
      </h1>
      <div className={styles.content}>
        <p>
          Unveiling Robotical's new <strong>Health Check</strong> and{" "}
          <strong>Auto Fault Detection</strong> features, enhancing your Marty
          experience!
        </p>
        <p>
          With our <strong>Warranty Program</strong>, we're simplifying
          robotics, identifying and solving potential Marty issues.
        </p>
        <p>
          Use our <strong>Health Check</strong> feature to quickly report
          Marty's status. Our <strong>Auto Fault Detection</strong> alerts you
          to problems, making support requests seamless.
        </p>
        <p>
          Stay updated with Marty's status on our{" "}
          <a
            href="https://service-program-dashboard.robotical.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>dashboard</strong>
          </a>
          , keeping track of your tickets.
        </p>
        <p>
          This feature is a learning tool; your experience helps us enhance
          Marty for all users, promoting continual development.
        </p>
        <p>
          Don't forget, even Marty needs regular check-ups to ensure a happy and
          healthy robotic adventure!
        </p>
      </div>
      <button
        className={styles.button}
        onClick={() => secondaryModalState.closeModal()}
      >
        Close
      </button>
    </div>
  );
}

export default ServiceProgramComingSoonModal;
