import { RICHWElem, RICHWElemList, RICMsgHandler } from "@robotical/ricjs";
import InBtButAPressedModalContent from "../components/modals/InBtButAPressed";
import modalState from "../state-observables/modal/ModalState";
import { MartyConnector } from "./MartyConnector";
import {
  DatabaseEnum,
  DatabaseManager,
  RobotDatabase,
} from "@robotical/analytics-gatherer/dist";
import { robotConfig } from "../dbConfigs/configs";
import { createElement } from "react";
import RICServoFaultDetector from "@robotical/ricjs/dist/RICServoFaultDetector";
import {
  createTicket,
  isSerialNumberRegistered,
} from "../ServiceProgram/onboarding";
import { toast } from "react-toastify";
import { ReportedWarningsState } from "../MartyNotifications/ReportedWarningsState";

const USER_WARNING_MESSAGE = {
  registeredUser:
    "Don't worry, we've logged the issue and our support team will contact you soon. Thanks for your patience!",
  unregisteredUser:
    "To get things sorted, head over to our 'Help' section and register your Marty for our Warranty Service Program.",
};

class HWStatusLastReported {
  // a class that holds the last reported hw status for 30 seconds
  // this is to prevent overloading marty with hwstatus requests
  private static instance: HWStatusLastReported;
  private lastReported: RICHWElemList | null;
  private lastReportedTime: number;
  private static readonly MAX_TIME = 30000; // 30 seconds

  private constructor() {
    this.lastReported = null;
    this.lastReportedTime = 0;
  }

  public static getInstanceOrInstantiate() {
    if (!HWStatusLastReported.instance) {
      HWStatusLastReported.instance = new HWStatusLastReported();
    }
    return HWStatusLastReported.instance;
  }

  public setLastReported(hwStatus: RICHWElemList | string) {
    // only set the last reported if it is not a string
    if (typeof hwStatus === "string") {
      return;
    }
    this.lastReported = hwStatus;
    this.lastReportedTime = Date.now();
  }

  public getLastReported() {
    if (Date.now() - this.lastReportedTime > HWStatusLastReported.MAX_TIME) {
      return null;
    }
    return this.lastReported;
  }
}

export class RICNotificationsManager {
  private martyConnector: MartyConnector;
  constructor(martyConnector: MartyConnector) {
    this.martyConnector = martyConnector;
  }

  setNotificationsHandler(ricMsgHandler: RICMsgHandler) {
    ricMsgHandler.reportMsgCallbacksSet(
      "notifyCB",
      this.reportNofication.bind(this)
    );
  }

  msgBodyDecider(report: any, hwElems: RICHWElem[]) {
    switch (report.msgBody) {
      case "overCurrentDet":
        if (!report.hasOwnProperty("IDNo")) break;
        let motor = report.IDNo;
        const motorElem = hwElems.find(({ IDNo }) => IDNo === report.IDNo);

        if (motorElem !== undefined) {
          motor = motorElem.name;
        }

        // TODO: add warnings manager to display warnings to user
        // adding warning to the manager where the warning message to
        // be displayed is decided
        // const ocwm = OverCurrentWarningManager.getInstanceOrInstantiate();
        // const warning = new OverCurrentWarning(motor);
        // ocwm.addWarning(warning);
        break;

      case "freefallDet":
        // this.emit(INCREMENT_FREE_FALL_WARNINGS);
        break;

      // Button A pressed Case
      case "btnAPressed":
        modalState.setModal(
          createElement(InBtButAPressedModalContent),
          "Warning!"
        );
        break;
    }
  }

  // REPORT notifications are messages generated by RIC in response to certain events, including raw i2c events and motor safeties
  reportNofication(report: any): void {
    const ricSystem = this.martyConnector._ricConnector.getRICSystem();
    const hwElems = ricSystem.getCachedAllHWElems();

    console.log(`reportNotification Report callback ${JSON.stringify(report)}`);

    if (report.hasOwnProperty("msgType")) {
      if (report.msgType === "raw") {
        this.handleServoFaultRawMsg(report);
        return;
      }

      if (report.msgType === "warn" && report.hasOwnProperty("msgBody")) {
        if (report.msgBody === "servoFaultDet") {
          // if there is a servo fault, we want to get the servo fault data using the servo fault detector
          console.log("servo fault detected");
          // this.handleServoFaultRawMsg(report); // DEBUGGING ONLY
          this.martyConnector._ricConnector.ricServoFaultDetector.atomicReadOperation(); // this will trigger reportMsgCallback, and so the above code will be executed
        } else if (report.msgBody === "elemCommsFailDet") {
          this.handleElemCommsFailDet(report);
        } else if (report.msgBody === "busFailDet") {
          this.handleBusFailDet(report);
        }
        ///////
        // Analytics
        let motorElem: RICHWElem | undefined = undefined;
        if (report.hasOwnProperty("IDNo")) {
          motorElem = hwElems.find(({ IDNo }) => IDNo === report.IDNo);
        }
        const dbManager = DatabaseManager.getInstance();
        dbManager
          .initializeOrGetDatabase(
            DatabaseEnum.ROBOT,
            robotConfig,
            DatabaseEnum.ROBOT
          )
          .then(async (db) => {
            const isSerialNoRegistered = await isSerialNumberRegistered(this.martyConnector.martySerialNo);
            (db as RobotDatabase).addWarningMessage(report, motorElem, isSerialNoRegistered);
          })
          .catch((err) => console.log(err));
        ///

        this.msgBodyDecider(report, hwElems);
      }
    }
  }

  async getHwStatus(stringify = true, maxRetries = 10, delayMs = 1000): Promise<string | RICHWElemList["hw"]> {
    for (let i = 0; i < maxRetries; i++) {
      const cachedHwStatus = HWStatusLastReported.getInstanceOrInstantiate().getLastReported();
      if (cachedHwStatus) {
        console.log("got hw status from cache")
        return stringify ? JSON.stringify(cachedHwStatus.hw) : cachedHwStatus.hw;
      }
      try {
        const hwStatusResponse = await this.martyConnector.sendRestMessage(
          'hwstatus',
        ) as unknown as RICHWElemList;
        if (hwStatusResponse?.rslt === 'ok' && hwStatusResponse?.hw) {
          HWStatusLastReported.getInstanceOrInstantiate().setLastReported(hwStatusResponse);
          console.log("got hw status from rest")
          return stringify ? JSON.stringify(hwStatusResponse.hw) : hwStatusResponse.hw;
        } else {
          console.log(`Couldn't get hwstatus: retrying...`);
          if (i < maxRetries - 1) {
            await new Promise(resolve => setTimeout(resolve, delayMs));
          }
        }
      } catch (err) {
        console.log(`Error getting hwstatus: ${err}, retrying...`);
        if (i < maxRetries - 1) {
          await new Promise(resolve => setTimeout(resolve, delayMs));
        }
      }
    }
    console.log("Couldn't get hwstatus: returning empty array");
    return stringify ? "[]" : [];
  }

  getHWNameGivenIdNo(hwStatusArr: RICHWElemList["hw"], IDNo: number) {
    const convertedIdNo = Number(IDNo);
    if (isNaN(convertedIdNo)) {
      return null;
    }
    for (const device of hwStatusArr) {
      const convertedHwIdNo = Number(device.IDNo);
      if (convertedHwIdNo === convertedIdNo) {
        return device.name;
      }
    }
    return convertedIdNo;
  }

  async getHwRevAndVersion(): Promise<string> {
    const cachedSystemInfo = this.martyConnector.systemInfo;
    if (cachedSystemInfo) {
      return `Versions info: ${JSON.stringify(cachedSystemInfo)}`;
    } else {
      try {
        const vResponse = await this.martyConnector.sendRestMessage('v');
        if (vResponse?.rslt === 'ok') {
          return `Versions info: ${JSON.stringify(vResponse)}`;
        } else {
          return "Couldn't get versions info";
        }
      } catch (err) {
        console.log("Error getting hwstatus:", err);
        return "Couldn't get versions info";
      }
    }
  }

  async martyNameStr() {
    let nameString = 'Robot Name: ';
    if (this.martyConnector.getCachedRICName()) return nameString + this.martyConnector.getCachedRICName();
    const fetchedName = await this.martyConnector.getRICName();
    if (fetchedName) {
      return nameString + fetchedName;
    } else {
      return nameString + 'Unknown';
    }
  }

  async handleServoFaultRawMsg(report: any) {
    // return;
    const detectedServoFaults =
      RICServoFaultDetector.interpretReportMsg(report);
    // const detectedServoFaults = {
    //   intermittentConnection: false,
    //   noConnection: false,
    //   faultyConnection: true,
    //   servoHornPositionError: false,
    // }; // DEBUGGING ONLY
    const humanReadableServoFaults = {
      intermittentConnection: "Intermittent Sensor Connection",
      noConnection: "No Sensor Connection",
      faultyConnection: "Faulty Drive Connection",
      servoHornPositionError: "Servo Horn Position Error",
    };
    if (detectedServoFaults) {
      const hwStatusArr = await this.getHwStatus(false) as RICHWElemList["hw"];
      const hwStatus = JSON.stringify(hwStatusArr);
      const elemName = this.getHWNameGivenIdNo(hwStatusArr, report.IDNo);
      const martyNameStr = await this.martyNameStr();
      const appVersion = "webapp";
      const versionsInfo = await this.getHwRevAndVersion();
      for (const servoFaultKey in detectedServoFaults) {
        if (
          detectedServoFaults[servoFaultKey as keyof typeof detectedServoFaults]
        ) {
          const simpleDescription = `${elemName} Servo Fault Detected: ${humanReadableServoFaults[servoFaultKey as keyof typeof humanReadableServoFaults]}`;
          const enhancedDescription = `${simpleDescription}\n\n${martyNameStr}\n\n${appVersion}\n\n${versionsInfo}\n\nHwStatus: ${hwStatus}`;
          try {
            ReportedWarningsState.getInstanceOrInstantiate().addCachedWarning(simpleDescription);
            const isSeNoRegistered = await isSerialNumberRegistered(this.martyConnector.martySerialNo);
            if (isSeNoRegistered) {
              if (
                // report the warning if it should not be ignored
                !ReportedWarningsState.shouldIgnoreWarning(simpleDescription) &&
                // and the warning has been seen 3 times in the last 5 minutes
                ReportedWarningsState.getInstanceOrInstantiate().hasNumWarningsInLastMinutes(simpleDescription, 3, 5)
              ) {
                createTicket(this.martyConnector.martySerialNo, `Servo Fault: ${elemName || report.IDNo}`, enhancedDescription);
              }
            }
            if (
              // only show the toast if the warning hasn't already been reported
              !ReportedWarningsState.getInstanceOrInstantiate().warningExists(simpleDescription) &&
              // and the warning should not be ignored
              !ReportedWarningsState.shouldIgnoreWarning(simpleDescription) &&
              // and the warning has been seen 3 times in the last 5 minutes
              ReportedWarningsState.getInstanceOrInstantiate().hasNumWarningsInLastMinutes(simpleDescription, 3, 5)
            ) {
              const userInfo = isSeNoRegistered
                ? USER_WARNING_MESSAGE.registeredUser
                : USER_WARNING_MESSAGE.unregisteredUser;
              toast.error(`Oops! We've detected that the servo ${elemName || report.IDNo} might be damaged. ${userInfo}`, { autoClose: false });
              // add the warning to the reported warnings state
              ReportedWarningsState.getInstanceOrInstantiate().addWarning(simpleDescription);
              // Analytics
              let motorElem: RICHWElem | undefined = undefined;
              if (report.hasOwnProperty("IDNo")) {
                const ricSystem = this.martyConnector._ricConnector.getRICSystem();
                const hwElems = ricSystem.getCachedAllHWElems();
                motorElem = hwElems.find(({ IDNo }) => IDNo === report.IDNo);
              }
              const dbManager = DatabaseManager.getInstance();
              dbManager
                .initializeOrGetDatabase(
                  DatabaseEnum.ROBOT,
                  robotConfig,
                  DatabaseEnum.ROBOT
                )
                .then(async (db) => {
                  (db as RobotDatabase).addWarningMessage(
                    { ...report, msgBody: humanReadableServoFaults[servoFaultKey as keyof typeof humanReadableServoFaults] },
                    motorElem,
                    isSeNoRegistered
                  );
                })
                .catch((err) => console.log(err));
              ///
            }
          } catch (err) {
            console.log(err);
          }
        }
      }
      console.log(`detected servo faults`, detectedServoFaults);
    }
  }

  handleElemCommsFailDet(report: any) {
    // return;
    // this is triggered if we get multiple occurrences of loss of comms with an addon
    // An IDNo will be reported, this can be cross referenced against the
    // hwstatus output to determine which element is giving the error, and hence which leg has a problem
    const warningKey = `elemCommsFailDet ${report.elemName || report.IDNo}`;
    ReportedWarningsState.getInstanceOrInstantiate().addCachedWarning(warningKey);

    isSerialNumberRegistered(this.martyConnector.martySerialNo)
      .then(async (isSeNoRegistered) => {
        const hwStatusArr = await this.getHwStatus(false) as RICHWElemList["hw"];
        const hwStatus = JSON.stringify(hwStatusArr);
        const elemName = this.getHWNameGivenIdNo(hwStatusArr, report.IDNo);
        const martyNameStr = await this.martyNameStr();
        const appVersion = "webapp";
        const versionsInfo = await this.getHwRevAndVersion();
        if (isSeNoRegistered) {
          const enhancedDescription = `Element IDNo: ${elemName || report.IDNo}\n\n${martyNameStr}\n\n${appVersion}\n\n${versionsInfo}\n\nHwStatus: ${hwStatus}`;
          if (
            // only create a ticket if the warning has been seen 3 times in the last 5 minutes
            ReportedWarningsState.getInstanceOrInstantiate().hasNumWarningsInLastMinutes(warningKey, 3, 5) &&
            // and if the warning should not be ignored
            !ReportedWarningsState.shouldIgnoreWarning(warningKey)
          ) {
            createTicket(this.martyConnector.martySerialNo, `Element Communication Failure at ${elemName || report.IDNo}`, enhancedDescription);
          }
        }
        if (
          // only show the toast if the warning hasn't already been reported
          !ReportedWarningsState.getInstanceOrInstantiate().warningExists(warningKey) &&
          // and has been seen 3 times in the last 5 minutes
          ReportedWarningsState.getInstanceOrInstantiate().hasNumWarningsInLastMinutes(warningKey, 3, 5) &&
          // and if the warning should not be ignored
          !ReportedWarningsState.shouldIgnoreWarning(warningKey)
        ) {
          const userInfo = isSeNoRegistered ? USER_WARNING_MESSAGE.registeredUser : USER_WARNING_MESSAGE.unregisteredUser;
          toast.error(`Oops! We've detected that the cable for the ${elemName || "element " + report.IDNo} might be damaged. ${userInfo}`, { autoClose: false });
          ReportedWarningsState.getInstanceOrInstantiate().addWarning(warningKey);
        }
      }).catch((err) => console.log(err));
  }

  handleBusFailDet(report: any) {
    // return;
    // this is a bit of a catch all, which will trigger if we get multiple i2c bus failures.
    // We won't be able to figure out which element is causing the failure from this, but it tells us something is up
    const warningKey = `busFailDet ${report.elemName || report.IDNo}`;
    ReportedWarningsState.getInstanceOrInstantiate().addCachedWarning(
      warningKey
    );

    isSerialNumberRegistered(this.martyConnector.martySerialNo)
      .then(async (isSeNoRegistered) => {
        const hwStatusArr = await this.getHwStatus(false) as RICHWElemList["hw"];
        const hwStatus = JSON.stringify(hwStatusArr);
        const elemName = this.getHWNameGivenIdNo(hwStatusArr, report.IDNo);
        const martyNameStr = await this.martyNameStr();
        const appVersion = "webapp";
        const versionsInfo = await this.getHwRevAndVersion();
        if (isSeNoRegistered) {
          const enhancedDescription = `Bus Fail Detected at ${elemName || report.IDNo}\n\n${martyNameStr}\n\n${appVersion}\n\n${versionsInfo}\n\nHwStatus: ${hwStatus}`;
          if (
            // only create a ticket if the warning has been seen 3 times in the last 5 minutes
            ReportedWarningsState.getInstanceOrInstantiate().hasNumWarningsInLastMinutes(warningKey, 3, 5) &&
            // and if the warning should not be ignored
            !ReportedWarningsState.shouldIgnoreWarning(warningKey)
          ) {
            createTicket(this.martyConnector.martySerialNo, `Bus Fail ${elemName || report.IDNo}`, enhancedDescription);
          }
        }
        if (
          // only show the toast if the warning hasn't already been reported 
          !ReportedWarningsState.getInstanceOrInstantiate().warningExists(warningKey) &&
          // and has been seen 3 times in the last 5 minutes
          ReportedWarningsState.getInstanceOrInstantiate().hasNumWarningsInLastMinutes(warningKey, 3, 5) &&
          // and if the warning should not be ignored
          !ReportedWarningsState.shouldIgnoreWarning(warningKey)
        ) {
          const userInfo = isSeNoRegistered ? USER_WARNING_MESSAGE.registeredUser : USER_WARNING_MESSAGE.unregisteredUser;
          toast.error(`Oops! We've detected that the BUS communication system at ${elemName || "element " + report.IDNo} might be damaged. ${userInfo}`, { autoClose: false });
          ReportedWarningsState.getInstanceOrInstantiate().addWarning(warningKey);
        }
      }).catch((err) => console.log(err));
  }
}
