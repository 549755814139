import { createElement, useEffect, useRef, useState } from 'react';
import SD from '@robotical/sensors-dashboard/dist/App';
import SensorsDashboardConnector from '../../../MartyConnector/SensorsDashboardConnector';
import { ReactComponent as BetaSignSVG } from "../../../assets/beta-sign.svg";
import modalState from "../../../state-observables/modal/ModalState";
import BugReportModal from '../BugReportModal';
import { AppDatabase, DatabaseEnum, DatabaseManager } from '@robotical/analytics-gatherer/dist';
import { appConfig } from '../../../dbConfigs/configs';

function SensorsDashboardModal() {
    const [showBetaSign, setShowBetaSign] = useState(true);
    const betaSignRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Analytics
        const dbManager = DatabaseManager.getInstance();
        dbManager.initializeOrGetDatabase(DatabaseEnum.APP, appConfig, DatabaseEnum.APP)
        .then((db) => {
            (db as AppDatabase).storeVisitedScreen("sensors-dash");
        }).catch((err) => console.log(err));
    }, []);

    if (window.mv2Dashboard) {
        window.mv2Dashboard.setIsModal(true);
    }

    useEffect(() => {
        const sensDashConnector = new SensorsDashboardConnector(true);
        sensDashConnector.setApp(window.mv2Dashboard);

        return () => {
            sensDashConnector.clearUpdater();
        };
    }, []);

    const updateBetaSignVisibility = () => {
        if (!betaSignRef.current) {
            return;
        }
        const parent = betaSignRef.current.parentElement;
        if (!parent) {
            return;
        }
        const parentHeight = parent.clientHeight;

        if (parentHeight > 100) {
            setShowBetaSign(true);
        } else {
            setShowBetaSign(false);
        }
    };

    useEffect(() => {
        updateBetaSignVisibility();

        if (!betaSignRef.current) {
            return;
        }
        const resizeObserver = new ResizeObserver(() => {
            updateBetaSignVisibility();
        });

        if (!betaSignRef.current.parentElement) {
            return;
        }

        resizeObserver.observe(betaSignRef.current.parentElement);

        return () => {
            resizeObserver.disconnect();
        };
    }, [betaSignRef]);


    return (
        <>
            <div
                style={{ visibility: showBetaSign ? "visible" : "hidden" }}
                className="beta-sign-icon-container"
                onClick={() =>
                    modalState.setModal(createElement(BugReportModal), "Anonymous Bug Report")
                }
                ref={betaSignRef}
            >
                <BetaSignSVG />
            </div>

            <SD />
        </>
    );
}

export default SensorsDashboardModal;