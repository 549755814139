import StoreEventEmitter from "./StoreEventEmitter";
import {
  RICOKFail,
  RICConnector,
  RICConnEvent,
  RICUpdateEvent,
  RICWifiConnStatus,
  RICCommsStats,
  RICWifiScanResults,
  RICServoParamUpdate,
  RICSystemInfo,
  RICHWElemList_Str,
  RICHWElemList
} from "@robotical/ricjs";
import { MartyObserver } from "./MartyObserver";
import modalState from "../state-observables/modal/ModalState";
import VerificationModal from "../components/modals/VerificationModal";
import { RICNotificationsManager } from "./RICNotificationsManager";
import { PystatusMsgType } from "@robotical/ricjs/src/RICTypes";
import InUnpluggedModalContent from "../components/modals/InUnplugged";
import { RICRoboticalAddOns } from "@robotical/ricjs-robotical-addons";
import NotificationsEmitter from "../MartyNotifications/NotificationsEmitter";
import MartySoundStreamingStats from "./MartySoundStreamingStats";
import { AppDatabase, DatabaseEnum, DatabaseManager, RobotDatabase } from "@robotical/analytics-gatherer/dist";
import { appConfig, robotConfig } from "../dbConfigs/configs";
import { osName } from "react-device-detect";
import Bowser from "bowser";
import { getDeviceId } from "../utils/analytics/cookies/device-id";
import { createElement } from "react";
import { RIC_WHOAMI_TYPE_CODE_ADDON_COLOUR } from "../utils/RICAddonsMap";
import ColourSensorManualCalibrator from "@robotical/ricjs-robotical-addons/dist/ColourSensorManualCalibrator";
import { UserRole as UserRoleType } from "../store/user-role-context";
import { addMartyNameToSerialNumber, getEmailGivenSerialNumber, getUserGivenEmail, isSerialNumberRegistered } from "../ServiceProgram/onboarding";
import SessionsDBManager from "../databases/SessionsDBManager";
import MartyBlocksDatabase from "@robotical/analytics-gatherer/dist/database/MartyBlocksDatabase";
import SensorDashboardDatabase from "@robotical/analytics-gatherer/dist/database/SensorDashboardDatabase";
import PythonTranslatorDatabase from "@robotical/analytics-gatherer/dist/database/PythonTranslatorDatabase";
import MLDatabase from "@robotical/analytics-gatherer/dist/database/MLDatabase";
import CodeAssesDatabase from "@robotical/analytics-gatherer/dist/database/CodeAssesDatabase";

export const SERIAL_NUMBER_UPDATED = "SERIAL_NUMBER_UPDATED";


// extending from StoreEventEmitter so we can
// access react context/state from here
// (react context is otherwise accessed only
// from within react components)
export class MartyConnector extends StoreEventEmitter {

  // RIC
  public _ricConnector = new RICConnector();

  // Observers
  private _observers: { [key: string]: Array<MartyObserver> } = {};

  // RICNotificationsManager
  private _ricNotificationsManager: RICNotificationsManager = new RICNotificationsManager(
    this
  );

  // sound streaming stats
  public soundStreamingStats = new MartySoundStreamingStats();

  // Colours to use for LED patterns
  private ledLcdColours = [
    { led: "#202000", lcd: "#FFFF00" },
    { led: "#880000", lcd: "#FF0000" },
    { led: "#000040", lcd: "#0080FF" },
  ];

  // Updater removers: when marty disconnects
  // these functions will clear the time intervals
  // created for updating the sensors
  private updaterRemovers: (() => void)[] = [];

  // Marty name
  public RICFriendlyName: string = "";

  // is connecting: from when the user presses the connect button to verifying the colours
  public isConnecting: boolean = false;

  // wifi scan duration
  private _wifiScanDuration = 10000; //ms

  // Marty version
  public martyVersion: string = "0.0.0";

  // Marty Serial Number
  public martySerialNo: string = "";

  // Joint names
  private _jointNames = {
    LeftHip: "LeftHip",
    LeftTwist: "LeftTwist",
    LeftKnee: "LeftKnee",
    RightHip: "RightHip",
    RightTwist: "RightTwist",
    RightKnee: "RightKnee",
    LeftArm: "LeftArm",
    RightArm: "RightArm",
    Eyes: "Eyes",
  };

  // marty signal
  public martyRSSI: number;

  // system infor
  public systemInfo: RICSystemInfo | null = null;
  public isVerified = false;
  public isSerialNoRegisteredInWarranty: boolean | null = null;

  // all dbs for session data
  public sessionDbs = new SessionsDBManager();

  // user role
  public userRole: UserRoleType = null;

  // Constructor
  constructor() {
    super();
    // initialise servo param update so we start downloading servo parameter updates
    const spu = RICServoParamUpdate.getSingletonInstance(this._ricConnector.getRICMsgHandler());
    spu && spu.init();

    // registering addons 
    const addOnManager = this._ricConnector.getAddOnManager();
    RICRoboticalAddOns.registerAddOns(addOnManager);

    this.analytics_AppStartSession();

    // ----------
    // Subscribe to RICConnector events
    this._ricConnector.setEventListener(
      (
        eventType: string,
        eventEnum: RICConnEvent | RICUpdateEvent,
        eventName: string,
        eventData: string | object | null | undefined
      ) => {
        console.log(
          `eventType: ${eventType} eventEnum: ${eventEnum} eventName: ${eventName} eventData: ${eventData}`
        );
        NotificationsEmitter.notify(eventType, eventEnum, eventName, eventData);
        this.publish(eventType, eventEnum, eventName, eventData);
      }
    );

    // Subscribe to disconnect event
    this.onDisconnectEventSubscription();

    this.martyRSSI = -200;
  }

  // Check if connected
  isConnected(): boolean {
    if (this._ricConnector) {
      return this._ricConnector.isConnected();
    }
    return false;
  }

  async analytics_AppStartSession() {
    const dbManager = DatabaseManager.getInstance();
    dbManager.initialize(DatabaseEnum.APP, appConfig, DatabaseEnum.APP);
    const db = await dbManager.initializeOrGetDatabase(DatabaseEnum.APP, appConfig, DatabaseEnum.APP) as AppDatabase;
    const browser = Bowser.getParser(window.navigator.userAgent);
    const browserInfo = browser.getBrowser();
    const appVersionNum = "web-app";
    const deviceVersion = browserInfo.name + " -- " + browserInfo.version;
    const deviceModel = osName;
    const deviceOS = "web-app";
    const deviceId = await getDeviceId();
    await db.startSession(appVersionNum, deviceVersion, deviceModel, deviceOS, deviceId);
  }

  async analytics_RobotStartSession(systemInfo: RICSystemInfo) {
    // create new robot session
    const dbManager = DatabaseManager.getInstance();
    const appDb = await dbManager.initializeOrGetDatabase(DatabaseEnum.APP, appConfig, DatabaseEnum.APP) as AppDatabase;
    const robotDb = await dbManager.initializeOrGetDatabase(DatabaseEnum.ROBOT, robotConfig, DatabaseEnum.ROBOT) as RobotDatabase;
    const mbDb = dbManager.getDatabase(DatabaseEnum.MARTY_BLOCKS);
    const sensDashDb = dbManager.getDatabase(DatabaseEnum.SENSORS_DASHBOARD);
    const pythonTranslatorDb = dbManager.getDatabase(DatabaseEnum.PYTHON_TRANSLATOR);
    const mlDb = dbManager.getDatabase(DatabaseEnum.MACHINE_LEARNING);
    const codeAssesDb = dbManager.getDatabase(DatabaseEnum.CODE_ASSES);
    const serialNo = systemInfo.SerialNo!;
    let ricRevStr: number | string = systemInfo.RicHwRevNo;
    // if it's number convert to string
    if (typeof ricRevStr === "number") {
      ricRevStr = ricRevStr.toString();
    }
    this.setSerialNo(serialNo);
    const systemVersion = systemInfo.SystemVersion;
    await robotDb.startSession(serialNo, systemVersion, appDb.dataToStore.sessionId, ricRevStr);
    await appDb.storeRobotSession(robotDb.dataToStore.sessionId);
    // when we start a new robot session, we also need to add that session id to the other sessions (it'll get stored if the other sessions exist)
    if (mbDb) {
      const mbDbInstance = mbDb as MartyBlocksDatabase;
      await mbDbInstance.storeRobotSessionId(robotDb.dataToStore.sessionId);
    }
    if (sensDashDb) {
      const sensDashDbInstance = sensDashDb as SensorDashboardDatabase;
      await sensDashDbInstance.storeRobotSessionId(robotDb.dataToStore.sessionId);
    }
    if (pythonTranslatorDb) {
      const pythonTranslatorDbInstance = pythonTranslatorDb as PythonTranslatorDatabase;
      await pythonTranslatorDbInstance.storeRobotSessionId(robotDb.dataToStore.sessionId);
    }
    if (mlDb) {
      const mlDbInstance = mlDb as MLDatabase;
      await mlDbInstance.storeRobotSessionId(robotDb.dataToStore.sessionId);
    }
    if (codeAssesDb) {
      const codeAssesDbInstance = codeAssesDb as CodeAssesDatabase;
      await codeAssesDbInstance.storeRobotSessionId(robotDb.dataToStore.sessionId);
    }
  }

  setSerialNo(serialNo: string) {
    this.martySerialNo = serialNo;
    // notify observers
    this.publish("conn", SERIAL_NUMBER_UPDATED, "", serialNo);
  }

  /**
   * Connect to a RIC
   *
   * @param {string} method - can be "WebBLE" or "WebSocket"
   * @param {string | object} locator - either a string (WebSocket URL) or an object (WebBLE)
   * @returns Promise<boolean>
   *
   */
  async connect(method: string, locator: string | object): Promise<boolean> {
    // Check already connected
    if (this._ricConnector && this._ricConnector.isConnected()) {
      await this._ricConnector.disconnect();
    }

    // Connect to RIC
    let success = await this._ricConnector.connect(method, locator);
    if (!success) {
      console.log("Failed to connect to RIC");
      return false;
    }

    // TODO 2022 - this code would normally run after LED pattern confirmed
    const sysInfoOk = await this._ricConnector.retrieveMartySystemInfo();
    if (!sysInfoOk) {
      // this.emit(RIC_REJECTED)
      return false;
    } else {
      // this.emit(VERIFIED_CORRECT_ricConnector, { systemInfo: this._ricConnectorSystem.getRICSystemInfo() });
    }

    //  -----check if the ric is in unplugged mode------
    const pystatus = await this.sendRestMessage("pystatus");
    // if it's in unplugged mode update the relevant state
    // that shows a modal
    if (pystatus && (pystatus as unknown as PystatusMsgType).running === "screenfree.py") {
      // set modal state to 'in plugged mode'
      modalState.setModal(createElement(InUnpluggedModalContent), "Warning!");
      await this._ricConnector.disconnect();
      return false;
    }

    // update components state
    this.connectionCtx?.setIsConnected(true);
    const martyName = await this.getRICName();
    this.connectionCtx?.setMartyName(martyName);

    // start verification process
    await modalState.setModal(createElement(VerificationModal), "Looking for Marty");
    return true;
  }

  async verifyMarty() {
    return this._ricConnector.checkCorrectRICStart(this.ledLcdColours);
  }

  setUserRole(role: "teacher" | "student" | null) {
    this.userRole = role;
    this.userRoleCtx?.setUserRole(role);
  }

  async stopVerifyingMarty(confirmCorrectRIC: boolean) {
    if (confirmCorrectRIC) {
      // successful connection to Marty
      const ricSystem = this._ricConnector.getRICSystem();

      const systemInfo = await ricSystem.getRICSystemInfo(true);
      this.systemInfo = systemInfo;
      this.martyVersion = systemInfo.SystemVersion;
      this.analytics_RobotStartSession(systemInfo);
      this.isVerified = true;


      // if the serial number is registered in the warranty service, store marty's name
      if (!this.RICFriendlyName) {
        this.RICFriendlyName = await this.getRICName();
      }
      const serialNo = systemInfo.SerialNo!;

      const dbManager = DatabaseManager.getInstance();
      const emailAssociatedWithSerialNo = await getEmailGivenSerialNumber(serialNo);
      const isSerialNoRegisteredInWarranty = !!emailAssociatedWithSerialNo;
      if (isSerialNoRegisteredInWarranty) {
        const warrantyUser = await getUserGivenEmail(emailAssociatedWithSerialNo);
        let hasConsentedToDataCollection = true;
        if (warrantyUser) {
          if (warrantyUser.analyticsConsent || warrantyUser.analyticsConsent === undefined) {
            hasConsentedToDataCollection = true;
          } else {
            hasConsentedToDataCollection = false;
          }
          dbManager.hasSerialNoConsentedToDataCollection = hasConsentedToDataCollection;
        }
      }

      dbManager.isSerialNoRegisteredInWarranty = isSerialNoRegisteredInWarranty;
      this.isSerialNoRegisteredInWarranty = isSerialNoRegisteredInWarranty;
      if (isSerialNoRegisteredInWarranty) {
        addMartyNameToSerialNumber(serialNo, this.RICFriendlyName);
      }

      // store ble conn performance 
      // as promise because we don't want to wait for it to finish
      Promise.resolve().then(async () => {
        const bleConnPerf = await this._ricConnector.checkConnPerformance();
        const dbManager = DatabaseManager.getInstance();
        const appDb = await dbManager.initializeOrGetDatabase(DatabaseEnum.APP, appConfig, DatabaseEnum.APP) as AppDatabase;
        bleConnPerf && appDb.storeBlePerformance(bleConnPerf);

        this.soundStreamingStats.configureSoundStreamingForRobot(systemInfo, bleConnPerf);
      });

      // add a callback to display warning messages from RIC to the user
      this._ricNotificationsManager.setNotificationsHandler(
        this._ricConnector.getRICMsgHandler()
      );
      // servo parameters update
      const spu = RICServoParamUpdate.getSingletonInstance();
      spu && spu.setRobotConnected(true);

      this._ricConnector.setLegacySoktoMode(this.soundStreamingStats.shouldUseLegacySoktoMode(systemInfo));
      // checking for servo faults (this will trigger reportMsgCallback)
      console.log("checking for servo faults");
      this._ricConnector.ricServoFaultDetector.atomicReadOperation();
    }
    return this._ricConnector.checkCorrectRICStop(confirmCorrectRIC);
  }

  async getConnectedAddOns() {
    const ricSystem = this._ricConnector.getRICSystem();
    const elemList = await ricSystem.getHWElemList("RSAddOn");
    return elemList;
  }

  async getAddOnConfigs() {
    const ricSystem = this._ricConnector.getRICSystem();
    const addOnList = await ricSystem.getAddOnConfigs();
    return addOnList;
  }

  async identifyAddOn(name: string) {
    await this._ricConnector.identifyAddOn(name);
  }

  async setAddOnConfig(serialNo: string, newName: string) {
    await this._ricConnector.setAddOnConfig(serialNo, newName);
  }

  async deleteAddOn(serialNo: string) {
    await this._ricConnector.deleteAddOn(serialNo);
  }

  getCachedRICName() {
    return this.RICFriendlyName;
  }

  async getRICName() {
    const ricSystem = this._ricConnector.getRICSystem();
    const nameObj = await ricSystem.getRICName();
    this.RICFriendlyName = nameObj.friendlyName;
    return nameObj.friendlyName;
  }

  async setRICName(newName: string) {
    try {
      const ricSystem = this._ricConnector.getRICSystem();
      await ricSystem.setRICName(newName);
      this.connectionCtx?.setMartyName(newName);
    } catch (e) {
      console.log("Couldn't set Marty name", e);
    }
  }

  getBatteryStrength() {
    const ricState = this._ricConnector.getRICStateInfo();
    return ricState.power.powerStatus.battRemainCapacityPercent;
  }

  addUpdaterRemover(updaterRemover: () => void) {
    this.updaterRemovers.push(updaterRemover);
  }

  clearUpdatersAfterDisconnect() {
    this.updaterRemovers.forEach((updaterRemover) => updaterRemover());
    this.updaterRemovers = [];
  }

  async disconnect(): Promise<boolean> {
    this.sendRestMessage('blerestart');
    await this._ricConnector?.disconnect();
    this.connectionCtx?.setIsConnected(false);
    this.clearUpdatersAfterDisconnect();
    this.martySerialNo = "";
    this.martyVersion = "0.0.0";
    this.isSerialNoRegisteredInWarranty = null;
    const dbManager = DatabaseManager.getInstance();
    dbManager.isSerialNoRegisteredInWarranty = null;
    dbManager.hasSerialNoConsentedToDataCollection = null;
    this.isVerified = false;
    return true;
  }

  async sendRestMessage(msg: string, params?: object): Promise<RICOKFail> {
    if (this._ricConnector) {
      return this._ricConnector.sendRICRESTMsg(msg, params || {});
    }
    return new RICOKFail();
  }

  async getConnectedColourSensors(): Promise<string[]> {
    const names: string[] = [];
    try {
      const hwElemList_Str = await this._ricConnector.getRICMsgHandler()
        .sendRICRESTURL<RICHWElemList_Str>(`hwstatus/strstat/?filterByType=${RIC_WHOAMI_TYPE_CODE_ADDON_COLOUR}`);
      const hwElems = hwElemList_Str.hw;
      let hwElemList;
      if (hwElems.length) {
        if (typeof hwElems[0] !== "object") {
          // we are on a fw version that doesn't supports strstat
          hwElemList = await this._ricConnector.getRICMsgHandler().sendRICRESTURL<
            RICHWElemList
          >(`hwstatus?filterByType=${RIC_WHOAMI_TYPE_CODE_ADDON_COLOUR}`);
        } else {
          // we are on the fw version that supports strstat
          hwElemList = RICHWElemList_Str.expand(hwElemList_Str);
        }
      }

      if (hwElemList?.rslt === "ok" && hwElemList.hw?.length > 0) {
        for (const hwElem of hwElemList.hw) {
          names.push(hwElem.name);
        }
      }
      return names;
    } catch (e) {
      new Error("Error getting colour sensor names: " + JSON.stringify(e));
      return [];
    }
  }


  async checkForUpdateRequired(): Promise<boolean> {
    const isColourSensorCalibrationRequired = await this.isColourSensorCalibrationRequired();
    const isBatch6FaultyLED = await this.isBatch6FaultyLED();
    return isColourSensorCalibrationRequired || isBatch6FaultyLED;
  }

  async isBatch6FaultyLED(): Promise<boolean> {
    console.log("\n==== Checking if batch 6 LED is faulty ====");
    // if fw version === 1.3.12 and RicHwRevNo === 6, then we need to update
    const ricSystem = this._ricConnector.getRICSystem();
    const systemInfo = await ricSystem.getRICSystemInfo(true);
    const fwVersion = systemInfo.SystemVersion;
    const ricHwRevNo = systemInfo.RicHwRevNo;
    const isBatch6FaultyLED = fwVersion === "1.3.12" && ricHwRevNo === 6;
    return isBatch6FaultyLED;
  }

  async isColourSensorCalibrationRequired(): Promise<boolean> {
    console.log("\n==== Checking if colour sensor calibration is required ====");
    // check if the connected RIC has at least one coloursensor on version 1.3.0, with DTID of 0x0097
    // if so, we need to calibrate the colour sensor after updating
    const ricHWListStr = await this._ricConnector.getRICMsgHandler()
      .sendRICRESTURL<RICHWElemList_Str>(`hwstatus/strstat/?filterByType=${RIC_WHOAMI_TYPE_CODE_ADDON_COLOUR}`);
    const hwElems = ricHWListStr.hw;
    if (hwElems && hwElems.length) {
      if (typeof hwElems[0] !== "object") {
        // we are on a fw version that doesn't support strstat, so we can assume that they don't have a batch 5 coloursensor
        return false;
      } else {
        const hwElemList = RICHWElemList_Str.expand(ricHWListStr);
        console.log("hwElemList: ", hwElemList);
        const colourSensElems = hwElemList.hw.filter(
          (elem) => elem.whoAmI === RIC_WHOAMI_TYPE_CODE_ADDON_COLOUR
        );
        console.log("colourSensElems: ", colourSensElems);
        // check if any of the colour sensors are on version 1.3.0
        const isAnyColourSensOn130 = colourSensElems && (!!colourSensElems.length) && colourSensElems.some((elem) => elem.versionStr === "1.3.0");
        console.log("isAnyColourSensOn130: ", isAnyColourSensOn130);
        // check if any of the colour sensors have a DTID of 0x0097
        // const isAnyColourSensOn0097 = colourSensElems && !!colourSensElems.length && colourSensElems.some((elem) => elem.whoAmITypeCode === "97");
        // console.log("isAnyColourSensOn0097: ", isAnyColourSensOn0097);
        return isAnyColourSensOn130;
      }
    }
    return false;
  }

  async streamAudio(audioData: Uint8Array, duration: number, clearExisting?: boolean): Promise<boolean> {
    console.log(`streamAudio length ${audioData.length}`);
    if (this._ricConnector) {
      this._ricConnector.streamAudio(audioData, !!clearExisting, duration);
      return true;
    }
    return false;
  }

  isStreamStarting() {
    if (this._ricConnector) {
      return this._ricConnector.isStreamStarting();
    }
    return false;
  }

  // Marty observer
  subscribe(observer: MartyObserver, topics: Array<string>): void {
    for (const topic of topics) {
      if (!this._observers[topic]) {
        this._observers[topic] = [];
      }
      if (this._observers[topic].indexOf(observer) === -1) {
        this._observers[topic].push(observer);
      }
    }
  }

  unsubscribe(observer: MartyObserver): void {
    for (const topic in this._observers) {
      if (this._observers.hasOwnProperty(topic)) {
        const index = this._observers[topic].indexOf(observer);
        if (index !== -1) {
          this._observers[topic].splice(index, 1);
        }
      }
    }
  }

  onDisconnectEventSubscription(): void {
    // This function subscribes to the disconnect event that comes from RICJS 
    // when the RIC is disconnected (either by the user or by losing connection)
    // Essentially, this makes sure that we update the state of the app when
    // the RIC is disconnected
    this.subscribe({
      notify: (eventType: string, eventEnum: RICConnEvent | RICUpdateEvent, eventName: string, eventData: string | object | null | undefined) => {
        if (eventType === "conn") {
          if (eventEnum === RICConnEvent.CONN_DISCONNECTED_RIC) {
            this.connectionCtx?.setIsConnected(false);
            this.clearUpdatersAfterDisconnect();
          }
        }
      },
    },
      ["conn"]
    );
  }

  publish(
    eventType: string,
    eventEnum: RICConnEvent | RICUpdateEvent | string,
    eventName: string,
    eventData: string | object | null | undefined
  ): void {
    if (this._observers.hasOwnProperty(eventType)) {
      for (const observer of this._observers[eventType]) {
        observer.notify(eventType, eventEnum, eventName, eventData);
      }
    }
  }

  // mark: wifi configuration
  getCachedWifiStatus(): RICWifiConnStatus {
    const ricSystem = this._ricConnector.getRICSystem();
    return ricSystem.getCachedWifiStatus();
  }

  // mark: wifi connect
  async wifiConnect(ssid: string, password: string): Promise<boolean> {
    const ricSystem = this._ricConnector.getRICSystem();
    return ricSystem.wifiConnect(ssid, password);
  }

  // mark: wifi disconnect
  async wifiDisconnect(): Promise<boolean> {
    const ricSystem = this._ricConnector.getRICSystem();
    return ricSystem.wifiDisconnect();
  }

  // mark: wifi getWiFiConnStatus
  async getWiFiConnStatus(): Promise<boolean | null> {
    const ricSystem = this._ricConnector.getRICSystem();
    return ricSystem.getWiFiConnStatus();
  }

  // mark: pause wifi
  async pauseWifiConnection(pause: boolean) {
    const ricSystem = this._ricConnector.getRICSystem();
    return ricSystem.pauseWifiConnection(pause);
  }

  // mark: get rssi of connected marty
  getRSSI() {
    const ricState = this._ricConnector.getRICStateInfo();
    const rssiValue = ricState.robotStatus.robotStatus.bleRSSI;
    this.martyRSSI = rssiValue;
    return rssiValue;
  }

  // mark: wifi scan get results
  async wifiScanResults(): Promise<RICWifiScanResults | false> {
    const ricSystem = this._ricConnector.getRICSystem();
    await ricSystem.wifiScanStart();
    let resultsTimeout: NodeJS.Timeout;
    const results: RICOKFail | RICWifiScanResults = await new Promise(
      (resolve, reject) =>
      (resultsTimeout = setTimeout(() => {
        ricSystem.wifiScanResults()
          .then((wifiscanMsgResults: boolean | RICOKFail | RICWifiScanResults) => {
            if (typeof wifiscanMsgResults !== "boolean") {
              resolve(wifiscanMsgResults);
            } else {
              reject("Something went wrong");
            }
          })
          .catch((err: unknown) => reject(err))
          .finally(() => clearTimeout(resultsTimeout));
      }, this._wifiScanDuration))
    );
    if ((results as RICWifiScanResults).hasOwnProperty("wifi")) {
      return results as RICWifiScanResults;
    }
    return false;
  }

  async calibrateColourSensors() {
    // @ts-ignore: REMEMBER TO UPDATE ricjs-robotical-addons to use ricjs magnetometer version -- ignore for now
    const didCalibrate = await ColourSensorManualCalibrator.calibrate(this._ricConnector);
    return didCalibrate;
  }

  // mark: calibration
  async calibrate(cmd: string, joints: string) {
    // Make a list of joints to calibrate on
    const jointList: Array<string> = new Array<string>();
    if (joints === "legs") {
      jointList.push(this._jointNames.LeftHip);
      jointList.push(this._jointNames.LeftTwist);
      jointList.push(this._jointNames.LeftKnee);
      jointList.push(this._jointNames.RightHip);
      jointList.push(this._jointNames.RightTwist);
      jointList.push(this._jointNames.RightKnee);
    } else if (joints === "arms") {
      jointList.push(this._jointNames.LeftArm);
      jointList.push(this._jointNames.RightArm);
    }
    if (joints === "eyes") {
      jointList.push(this._jointNames.Eyes);
    }

    const ricSystem = this._ricConnector.getRICSystem();
    return ricSystem.calibrate(cmd, jointList, this._jointNames);
  }

  // Mark: Comms stats -----------------------------------------------------------------------------------------

  getCommsStats(): RICCommsStats {
    return this._ricConnector.getCommsStats();
  }


  // get feedback from platforms
  getFeedback(feedback: string) {
    console.log(`feedback from platform: ${feedback}`);
  }

}


declare global {
  interface Window { martyConnector: MartyConnector; }
}

const martyConnector = new MartyConnector();
window.martyConnector = martyConnector;
export default martyConnector;
