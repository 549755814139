import { RICConnEvent, RICUpdateEvent } from "@robotical/ricjs";
import { toast } from "react-toastify";
import martyConnector from "../MartyConnector/MartyConnector";
import { MAIN_BLUE } from "../styles/colors";
export enum NotificationEvent {
  FW_NEEDS_UPDATE = "notification/fw-needs-update",
  CUSTOM_WARN_MSG = "notification/warn-message",
  CUSTOM_INFO_MSG = "notification/info-message",
  CUSTOM_ERROR_MSG = "notification/error-message",
}

export default class NotificationsEmitter {
  static notify(
    eventType: string,
    eventEnum: RICConnEvent | RICUpdateEvent | string,
    eventName: string,
    eventData: string | object | null | undefined
  ) {
    switch (eventType) {
      case "conn":
        if (eventEnum === RICConnEvent.CONN_STREAMING_ISSUE) {
          martyConnector.soundStreamingStats.increaseWarning();
          /* Muting sound streaming warning. 4/4/2024. Slack thread: https://roboticalltd.slack.com/archives/C166WK1EK/p1712226497365899?thread_ts=1712225223.828469&cid=C166WK1EK */
          //   toast.warn(<div>{"Streaming can't keep up! Sound quality will be lowered if this keeps happening."}{" "}
          //   <a style={
          //     {
          //       color: MAIN_BLUE,
          //       textDecoration: "underline"
          //     }
          //   }
          //     href="https://userguides.robotical.io/martyv2/support_faqs/warnings-in-app" target="_blank" rel="noreferrer">Learn more</a>
          // </div>, {
          //     position: "top-right",
          //     autoClose: 5000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //   });
        }
        break;
      case "notification":
        const notificationEvent =
          (eventEnum as string).split("/")[0] +
          "/" +
          (eventEnum as string).split("/")[1];
        if (NotificationEvent.FW_NEEDS_UPDATE === notificationEvent) {
          toast.warn(
            "Please update your Marty to the latest version to use this!",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        } else if (NotificationEvent.CUSTOM_WARN_MSG === notificationEvent) {
          const warnMsg = (eventEnum as string).split("/")[2];
          toast.warn(
            <div>{warnMsg}{" "}
              <a style={
                {
                  color: MAIN_BLUE,
                  textDecoration: "underline"
                }
              }
                href="https://userguides.robotical.io/martyv2/support_faqs/warnings-in-app" target="_blank" rel="noreferrer">Learn more</a>
            </div>, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (NotificationEvent.CUSTOM_INFO_MSG === notificationEvent) {
          const infoMsg = (eventEnum as string).split("/")[2];
          toast.info(infoMsg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (NotificationEvent.CUSTOM_ERROR_MSG === notificationEvent) {
          const errorMsg = (eventEnum as string).split("/")[2];
          toast.error(<div>{errorMsg}{" "}
            <a style={
              {
                color: MAIN_BLUE,
                textDecoration: "underline"
              }
            }
              href="https://userguides.robotical.io/martyv2/support_faqs/warnings-in-app" target="_blank" rel="noreferrer">Learn more</a>
          </div>, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        break;
    }
  }
}
