import { useContext } from "react";
import {
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import ConfigurationNavigator from "../navigation/ConfigurationNavigator";
import HelpNavigator from "../navigation/HelpNavigator";
import HomeScreen from "../screens/Home";
import MartyBlocks from "../screens/MartyBlocks/MartyBlocks";
import MartyBlocksJR from "../screens/MartyBlocksJR/MartyBlocksJR";
import PrivacyPolicy from "../screens/PrivacyPolicy";
import RCSequencer from "../screens/RCSequencer/RCSequencer";
import SensorsDashboard from "../screens/SensorsDashboard/SensorsDashboard";
import UnpluggedScreen from "../screens/Unplugged";
import MartyConnectionContext from "../store/marty-connection-context";
import WarrantyTerms from "../screens/WarrantyTerms";
import CodeAssessTerms from "../screens/CodeAssessTerms";

export default function Router() {

  const connectionCtx = useContext(MartyConnectionContext);
  return (
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route
          path="/configuration"
          element={
            connectionCtx.isConnected ? (
            <ConfigurationNavigator key={new Date().getTime()}/>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/rcsequencer"
          element={
            connectionCtx.isConnected ? (
              <RCSequencer />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route path="/marty-blocks" element={<MartyBlocks />} /> 
        <Route path="/marty-blocks-jr" element={<MartyBlocksJR />} />
        <Route path="/help" element={<HelpNavigator />} />
        <Route path="/unplugged" element={<UnpluggedScreen />} />
        <Route path="/warranty-terms" element={<WarrantyTerms />} />
        <Route path="/code-assess-terms" element={<CodeAssessTerms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/sensors-dashboard" element={<SensorsDashboard />} />

        <Route path="*" element={<Navigate to="/" replace />} />

      </Routes>
  );
}
