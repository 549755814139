import { FirebaseConfig } from "@robotical/analytics-gatherer/dist";

const serviceProgramFirebaseConfig: FirebaseConfig = {
    apiKey: "AIzaSyCbWTuFNqK62vm3d9Q1sR56ChB2yo36DrI",
    authDomain: "service-program-users.firebaseapp.com",
    databaseURL: "https://service-program-users-default-rtdb.firebaseio.com",
    projectId: "service-program-users",
    storageBucket: "service-program-users.appspot.com",
    messagingSenderId: "731474380079",
    appId: "1:731474380079:web:0ce9d28c6b4d148af74dda",
    email: "dev@robotical.io",
    password: "martyRocks",
};

export default serviceProgramFirebaseConfig;