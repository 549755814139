import { CalibrationComponentType } from "../../../../navigation/CalibrationNavigator";
import {
  getCalibrationImage,
  getCalibrationStrings,
  Steps,
} from "../../../../utils/helpers/calibration-screen";
import SimpleButton from "../../../buttons/SimpleButton";
import "../../../../screens/Calibration/styles.css";
import { useState } from "react";
import martyConnector from "../../../../MartyConnector/MartyConnector";
import { GREEN, RED } from "../../../../styles/colors";

interface LegCalibrationScreenProps extends CalibrationComponentType {}

export default function LegCalibration2({
  currentScreenSetter,
}: LegCalibrationScreenProps) {
  const [isBusy, setBusy] = useState<boolean>(false);

  const testLegMovementHandler = async () => {
    setBusy(true);
    await martyConnector.sendRestMessage("traj/" + "step", {
      stepLength: 25,
      moveTime: 1500,
    });
    setBusy(false);
  };

  return (
    <div className="calibration-screen-container">
      <div className="calibration-screen-marty-image-container">
        <img src={getCalibrationImage(Steps.STEP_LEGS_02)} alt="marty-image" />
      </div>
      <div className="calibration-screen-middle-text-container">
        <p className="calibration-screen-middle-text-title">
          {getCalibrationStrings(Steps.STEP_LEGS_02).title}
        </p>
        <p className="calibration-screen-middle-text-subtitle">
          {getCalibrationStrings(Steps.STEP_LEGS_02).hint}
        </p>
        {!isBusy ? (
          <></>
        ) : (
          <p className="calibration-screen-middle-text-subtitle">
            Communicating with your Marty...
          </p>
        )}
      </div>
      <div className="calibration-screen-bottom-buttons-container">
        <SimpleButton
          title="TEST LEG MOVEMENT"
          onClick={testLegMovementHandler}
        />
        <div
          style={{ display: "grid", gridAutoFlow: "column", columnGap: "1rem" }}
        >
          <SimpleButton
            title="👍 GOOD"
            onClick={() => currentScreenSetter("calibration")}
            colour={GREEN}
          />
          <SimpleButton
            title="👎 NOT GOOD"
            onClick={() => currentScreenSetter("leg-calibration-1")}
            colour={RED}
          />
        </div>
      </div>
    </div>
  );
}
