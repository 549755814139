import { RICSystemInfo } from "@robotical/ricjs";
import DisconnectConfirmationModal from "../components/modals/DisconnectConfirmation";
import NotificationsEmitter from "../MartyNotifications/NotificationsEmitter";
import ms from "../state-observables/modal/ModalState";
import connectToMarty from "../utils/network-api/connect-to-marty-function";
import AppConnector from "./AppConnector";
import martyConnector, { MartyConnector } from "./MartyConnector";
import { createElement } from "react";

export type MartyBlocksJRIF = {
  accel: string;
  addons: string;
  battRemainCapacityPercent: number;
  isConnected: boolean;
  rssi: number;
  power: string;
  servos: string;
  send_REST: (msg: string) => void;
  streamAudio: (audioData: Uint8Array, duration: number) => void;
  setBattRemainCapacityPercent: (battRemainCapacityPercent: number) => void;
  setIsConnected: (isConnected: boolean) => void;
  setRSSI: (rssi: number) => void;
  removeEventListeners: () => void;
  setName: (name: string) => void;
  updateConnectionInfo: () => void;
  systemInfo: RICSystemInfo;
  sendFeedbackToServer: (feedback: string) => void;
};
/* 
    Nikos: I had to make public some properties of the MartyConnector class
    because I don't know how to extend from a singleton class.
    Extending from that class would allow me access to its private/protected properites.
*/

class MartyBlocksJRConnector extends AppConnector {
  protected martyConnector: MartyConnector = martyConnector;
  // Marty Blocks JR window
  public _martyBlocksJRIF: MartyBlocksJRIF | null = null;

  // Sensor readings update timer
  protected _updateTimer: ReturnType<typeof setInterval> | null = null;

  constructor() {
    super();
  }

  async clearUpdater() {
    // busy wait to make sure we clearing
    // the _updateHooks interval after
    // we've updated the last state of marty
    // (marty should have been disconnected by that time)
    await new Promise((r) => setTimeout(r, 1500));
    if (this._updateTimer) {
      clearInterval(this._updateTimer);
      this._updateTimer = null;
    }
    this.unhookFromRicConnector();
  }

  protected unhookFromRicConnector() {
    // resetting properties so next time we 
    // fire an event it'll pass through the first
    // check which checks if new property is same 
    // as old property
    if (this._martyBlocksJRIF) {
      this._martyBlocksJRIF.battRemainCapacityPercent = 0;
      this._martyBlocksJRIF.rssi = -200;
      this._martyBlocksJRIF.setIsConnected(false);
    }
  }

  protected hookupToRicConnector() {
    if (this.martyConnector._ricConnector) {
      if (this._martyBlocksJRIF) {
        this._martyBlocksJRIF.setIsConnected(
          this.martyConnector._ricConnector.isConnected()
        );
        this._martyBlocksJRIF.send_REST = this.decodeAndSendMsgToApp.bind(
          this
        );
        this._martyBlocksJRIF.sendFeedbackToServer = this.martyConnector.getFeedback.bind(
          this.martyConnector
        );
      }
    }
  }


  async decodeAndSendMsgToApp(msg: string) {
    if (msg === "disconnect") {
      return ms.setModal(createElement(DisconnectConfirmationModal), "Are you sure you want to disconnect from your Marty?");
    }
    if (msg === "connect") {
      await connectToMarty();
      return this._martyBlocksJRIF && this.setApp(this._martyBlocksJRIF);
    }
    if (msg.startsWith("notification")) {
      return NotificationsEmitter.notify("notification", msg, "", null);
    }
    if (msg.startsWith("hide-back-arrow")) {
      try {
        return (document.querySelector("#root>.nav-layout-container>.nav-layout-go-back-btn") as HTMLElement).style.display = "none";
      } catch (e) {
        return console.log(e);
      }
    }
    if (msg.startsWith("show-back-arrow")) {
      try {
        return (document.querySelector("#root>.nav-layout-container>.nav-layout-go-back-btn") as HTMLElement).style.display = "flex";
      } catch (e) {
        return console.log(e);
      }
    }

    return this.martyConnector.sendRestMessage(msg);
  }

  setApp(martyBlocksJRIF: MartyBlocksJRIF) {
    this._martyBlocksJRIF = martyBlocksJRIF;
    this.hookupToRicConnector();

    if (this.martyConnector._ricConnector.isConnected()) {
      this._updateTimer = setInterval(() => {
        this._updateSensors();
      }, 1000);
    }
  }

  _updateSensors() {
    const ricState = this.martyConnector._ricConnector.getRICStateInfo();
    if (this._martyBlocksJRIF) {
      this._martyBlocksJRIF.systemInfo = this.martyConnector.systemInfo || new RICSystemInfo();
      this._martyBlocksJRIF.addons = JSON.stringify(ricState.addOnInfo);

      this._martyBlocksJRIF.setIsConnected(
        this.martyConnector._ricConnector.isConnected()
      );
      this._martyBlocksJRIF.setBattRemainCapacityPercent(
        ricState.power.powerStatus.battRemainCapacityPercent
      );
      this._martyBlocksJRIF.setName(this.martyConnector.RICFriendlyName);
      this._martyBlocksJRIF.power = JSON.stringify(ricState.power);

      this._martyBlocksJRIF.setRSSI(this.martyConnector.getRSSI());
      this._martyBlocksJRIF.updateConnectionInfo();
    }
  }
}

export default MartyBlocksJRConnector;
