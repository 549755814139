import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import NavLayout from "../../layout/NavLayout";
import MartyBlocksJRConnector, {
  MartyBlocksJRIF,
} from "../../MartyConnector/MartyBlocksJRConnector";
import martyConnector from "../../MartyConnector/MartyConnector";
import { AppDatabase, DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist";
import { appConfig } from "../../dbConfigs/configs";

const MainWindow = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
`;

const ContentWindow = styled.div`
  flex: auto;
  height: 100%;
`;

function MartyBlocksJR() {
  const [isMartyBlocksJRLoaded, setIsMartyBlocksJRLoaded] = useState(0);
  const navigate = useNavigate();

  const handleMartyBlocksJRLoad = (e: SyntheticEvent<HTMLDivElement>) => {
    // this differs slightly from rcsequencer and mb (isMartyBlocksJRLoaded is a number that increases)
    // mv2 is redifined every time we visit a different
    // page in mbjr (e.g., home.html/editor.html etc).
    // Therefore, we need to re-setApp the app, every time we
    // redefine mv2
    setIsMartyBlocksJRLoaded((oldState) => oldState + 1);
  };

  useEffect(() => {
    // Analytics
    const dbManager = DatabaseManager.getInstance();
    dbManager.initializeOrGetDatabase(DatabaseEnum.APP, appConfig, DatabaseEnum.APP)
      .then((db) => {
        (db as AppDatabase).storeVisitedScreen("scratchJr");
      }).catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    let mbjrConnector: MartyBlocksJRConnector;
    if (isMartyBlocksJRLoaded) {
      mbjrConnector = new MartyBlocksJRConnector();
      for (let i = 0; i < 10; i++) {
        if (window[i] && (window[i] as any).mv2) {
          mbjrConnector.setApp((window[i] as any).mv2 as MartyBlocksJRIF);
          break;
        }
      }
      martyConnector.addUpdaterRemover(
        mbjrConnector.clearUpdater.bind(mbjrConnector)
      );
    }
    return () => {
      mbjrConnector && mbjrConnector.clearUpdater();
    };
  }, [isMartyBlocksJRLoaded]);

  return (
    <NavLayout onClickBack={() => navigate("/")}>
      <MainWindow>
        <ContentWindow>
          <object
            type="text/html"
            data="./MartyBlocksJR/index.html"
            onLoad={handleMartyBlocksJRLoad}
            style={{ width: "100%", height: "100%" }}
          ></object>
          {/* <object type="text/html" data="http://localhost:8601/index.html" onLoad={handleMartyBlocksJRLoad} style={{width:'100%', height:'100%'}}>
        </object> */}
        </ContentWindow>
      </MainWindow>
    </NavLayout>
  );
}

export default MartyBlocksJR;
