// RIC ADDON CODES
export const RIC_WHOAMI_TYPE_CODE_ADDON_DISTANCE = "VCNL4200";
export const RIC_WHOAMI_TYPE_CODE_ADDON_LIGHT = "lightsensor";
export const RIC_WHOAMI_TYPE_CODE_ADDON_COLOUR = "coloursensor";
export const RIC_WHOAMI_TYPE_CODE_ADDON_IRFOOT = "IRFoot";
export const RIC_WHOAMI_TYPE_CODE_ADDON_LEDFOOT = "LEDfoot";
export const RIC_WHOAMI_TYPE_CODE_ADDON_LEDARM = "LEDarm";
export const RIC_WHOAMI_TYPE_CODE_ADDON_LEDEYE = "LEDeye";
export const RIC_WHOAMI_TYPE_CODE_ADDON_NOISE = "noisesensor";
export const RIC_WHOAMI_TYPE_CODE_ADDON_GRIPSERVO = "roboservo3";

const whoAmIToTypeStrMAP: { [key: string]: string } = {
  [RIC_WHOAMI_TYPE_CODE_ADDON_GRIPSERVO]: "GripperArm",
  [RIC_WHOAMI_TYPE_CODE_ADDON_LEDFOOT]: "DiscoFoot",
  [RIC_WHOAMI_TYPE_CODE_ADDON_LEDARM]: "DiscoArm",
  [RIC_WHOAMI_TYPE_CODE_ADDON_LEDEYE]: "DiscoEyes",
  [RIC_WHOAMI_TYPE_CODE_ADDON_IRFOOT]: "IRFoot",
  [RIC_WHOAMI_TYPE_CODE_ADDON_COLOUR]: "ColourSensor",
  [RIC_WHOAMI_TYPE_CODE_ADDON_DISTANCE]: "DistanceSensor",
  [RIC_WHOAMI_TYPE_CODE_ADDON_NOISE]: "NoiseSensor",
  [RIC_WHOAMI_TYPE_CODE_ADDON_LIGHT]: "LightSensor",
};

export function getHWElemTypeStr(whoAmI: string | undefined) {
  if (whoAmI === undefined) {
    return `Undefined whoamiTypeCode`;
  }
  return whoAmIToTypeStrMAP[whoAmI]
    ? whoAmIToTypeStrMAP[whoAmI]
    : `Unknown (${whoAmI})`;
}
