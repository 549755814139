import RC from "@robotical/rc-sequencer/dist/App";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NavLayout from "../../layout/NavLayout";
import martyConnector from "../../MartyConnector/MartyConnector";
import RCSequencerConnector from "../../MartyConnector/RCSequencerConnector";
import { AppDatabase, DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist";
import { appConfig } from "../../dbConfigs/configs";

function RCSequencer() {
  const navigate = useNavigate();

  useEffect(() => {
    // Analytics
    const dbManager = DatabaseManager.getInstance();
    dbManager.initializeOrGetDatabase(DatabaseEnum.APP, appConfig, DatabaseEnum.APP)
      .then((db) => {
        (db as AppDatabase).storeVisitedScreen("controller");
      }).catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const rcSeqConnector = new RCSequencerConnector();
    rcSeqConnector.setApp(window.mv2Sequencer);
    martyConnector.addUpdaterRemover(
      rcSeqConnector.clearUpdater.bind(rcSeqConnector)
    );

    return () => {
      rcSeqConnector.clearUpdater();
    };
  }, []);

  return (
    <NavLayout onClickBack={() => navigate("/")}>
      <RC />
    </NavLayout>
  );
}

export default RCSequencer;
