import { CalibrationComponentType } from "../../../navigation/CalibrationNavigator";
import {
  getCalibrationImage,
  getCalibrationStrings,
  Steps,
} from "../../../utils/helpers/calibration-screen";
import SimpleButton from "../../buttons/SimpleButton";
import "../../../screens/Calibration/styles.css";

interface DoneCalibrationScreenProps extends CalibrationComponentType {}

export default function DoneCalibration({
  currentScreenSetter,
}: DoneCalibrationScreenProps) {
  return (
    <div className="calibration-screen-container">
      <div className="calibration-screen-marty-image-container">
        <img src={getCalibrationImage(Steps.STEP_CAL_DONE)} alt="marty-image" />
      </div>
      <div className="calibration-screen-middle-text-container">
        <p className="calibration-screen-middle-text-title">
          {getCalibrationStrings(Steps.STEP_CAL_DONE).title}
        </p>
        <p className="calibration-screen-middle-text-subtitle">
          {getCalibrationStrings(Steps.STEP_CAL_DONE).hint}
        </p>
      </div>
      <div className="calibration-screen-bottom-buttons-container">
        <SimpleButton
          title="BACK"
          onClick={() => currentScreenSetter("calibration")}
        />
      </div>
    </div>
  );
}
