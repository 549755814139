import { createContext, useState } from "react";
import martyConnector from "../MartyConnector/MartyConnector";

export type UserRole = "teacher" | "student" | null;

export type UserRoleContextTypes = {
  userRole: UserRole;
  setUserRole: (role: UserRole) => void;
};

const UserRoleContext = createContext<UserRoleContextTypes>({
  userRole: null,
  setUserRole: () => { },
});

type UserRoleContextProviderTypes = {
  children: React.ReactNode;
};
export function UserRoleContextProvider({
  children,
}: UserRoleContextProviderTypes) {
  const [userRole, setUserRole] = useState<UserRole>(null);

  const setUserRoleHandler = (role: UserRole) => {
    setUserRole(role);
  };

  const context = {
    userRole: userRole,
    setUserRole: setUserRoleHandler,
  };
  // we need to wire the context to the marty connector in
  // order to be able to use the context/store outside of react components
  martyConnector.setUserRoleCtx(context);

  return (
    <UserRoleContext.Provider value={context}>
      {children}
    </UserRoleContext.Provider>
  );
}

export default UserRoleContext;
