
import { WifiScanWifiItem } from "@robotical/ricjs";
import SimpleButton from "../../components/buttons/SimpleButton";
import { ConfigurationComponentType } from "../../navigation/ConfigurationNavigator";
import "./styles.css";
import { useEffect, useState } from "react";
import WifiList from "../../components/WifiList";
import { AppDatabase, DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist";
import { appConfig } from "../../dbConfigs/configs";

interface WifiConfStep1ScreenProps extends ConfigurationComponentType {};

export default function WifiConfStep1Screen({currentScreenSetter}:WifiConfStep1ScreenProps) {
  const [selectedWifi, setSelectedWifi] = useState<WifiScanWifiItem | undefined>(undefined);

    useEffect(() => {
      // Analytics
      const dbManager = DatabaseManager.getInstance();
      dbManager.initializeOrGetDatabase(DatabaseEnum.APP, appConfig, DatabaseEnum.APP)
        .then((db) => {
            (db as AppDatabase).storeVisitedScreen("wifi-configuration");
        }).catch((err) => console.log(err));
    }, []);

  return (
    <div className="wifi-conf-step-one-container">
      <div>
        <p className="wifi-conf-step-one-title">STEP 1.</p>
        <p className="wifi-conf-step-one-subtitle">
          Which WiFi network should Marty connect to?
        </p>
        <p className="wifi-conf-step-one-subtitle">
          Marty can only connect to 2.4GHz WiFi networks
        </p>
        <WifiList
          onItemPress={(item) => setSelectedWifi(item)}
          selectedWifiBssid={selectedWifi && selectedWifi.bssid}
        />
      </div>
      <div className="wifi-conf-step-one-bottom-buttons-container">
        <SimpleButton title="Continue" disabled={!selectedWifi} onClick={() => currentScreenSetter("wifi-configuration-step-2", {ssid: selectedWifi?.ssid})} />
        <SimpleButton title="Use a hidden or unlisted wifi network" onClick={() => currentScreenSetter("wifi-configuration-step-1a")} />
      </div>
    </div>
  );
}
