import { useState } from "react";
import {
  ButtonsView,
  ModalText,
  ModalTitle,
  StyledView,
  StyledImage,
} from "./styles";

import imageAbutton from "../../../assets/marty-a-button.png";
import martyConnector from "../../../MartyConnector/MartyConnector";
import modalState from "../../../state-observables/modal/ModalState";
import SimpleButton from "../../buttons/SimpleButton";

type ModalTextType = "Button A was pressed!" | "Disconnected!";
function InBtButAPressedModalContent() {
  const [modalText, setModalText] = useState<ModalTextType>("Button A was pressed!");

  const disconnectHandler = () => {
    // disconnecting from marty
    martyConnector.disconnect();
    setTimeout(() => {
      setModalText("Disconnected!");
    }, 500);
    setTimeout(() => {
      modalState.closeModal();
    }, 1000);
  };

  return (
    <StyledView>
      <ModalTitle>{modalText}</ModalTitle>
      {modalText !== "Disconnected!" && (
        <ModalText>
          Someone has pressed the "MODE"/"A" button on the robot, if you would like to
          change mode you must first disconnect from the app and then press the
          A again!
        </ModalText>
      )}
      <StyledImage src={imageAbutton} />
      {modalText !== "Disconnected!" && (
        <ButtonsView>
          <SimpleButton
            title="Cancel"
            onClick={() => modalState.closeModal()}
          />
          <SimpleButton title="Disconnect" onClick={disconnectHandler} />
        </ButtonsView>
      )}
    </StyledView>
  );
}

export default InBtButAPressedModalContent;