import Layout from "../../layout/Layout";
import "./styles.css";
import { useEffect } from "react";
import { AppDatabase, DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist";
import { appConfig } from "../../dbConfigs/configs";
import NewsHub from "../NewsHub";
import ms from "../../state-observables/modal/ModalState";

function HomeScreen() {

  useEffect(() => {
    // Analytics
    const dbManager = DatabaseManager.getInstance();
    dbManager.initializeOrGetDatabase(DatabaseEnum.APP, appConfig, DatabaseEnum.APP)
      .then((db) => {
        (db as AppDatabase).storeVisitedScreen("home");
      }).catch((err) => console.log(err));

    // setTimeout(() => {
    //   const ServiceProgramModalWithProps = createElement(
    //     ServiceProgramOnboardingModal,
    //     {
    //       martySerialNumber: ''
    //     }
    //   );
    //   ms.setModal(ServiceProgramModalWithProps, "");
    // }, 1000)
  }, []);

  return (
    <Layout>
      <div className="home-screen-container">
        <NewsHub />
        <div className="home-screen-background-svg-container">
          {/* <LandingScreenImg /> */}
        </div>
      </div>
    </Layout >
  );
}

export default HomeScreen;
