import { useState } from "react";
import SimpleButton from "../../components/buttons/SimpleButton";
import LoadingSpinner from "../../components/LoadingSpinner";
import martyConnector from "../../MartyConnector/MartyConnector";
import { ConfigurationComponentType } from "../../navigation/ConfigurationNavigator";
import "./styles.css";

interface WifiConfStep2ScreenProps extends ConfigurationComponentType {
  ssid: string;
}

export default function WifiConfStep2Screen({
  currentScreenSetter,
  ssid,
}: WifiConfStep2ScreenProps) {
  const [password, setPassword] = useState("");
  const [isConnecting, setIsConnecting] = useState(false);
  const [isPausing, setIsPausing] = useState(false);

  const onWifiConnect = async () => {
    setIsConnecting(true);
    // we have to resume now, or the pause won't work later
    await martyConnector.pauseWifiConnection(false);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const connectResult = await martyConnector.wifiConnect(ssid, password);

    if (connectResult) {
      setIsPausing(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const connStat = await martyConnector.getWiFiConnStatus();
      console.log("connStat: " + connStat);
      // Request the WiFi connection to be paused
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const wifiPauseState = await martyConnector.pauseWifiConnection(true);
      console.log("wifiPauseState: " + wifiPauseState);
      setIsPausing(false);
      currentScreenSetter("wifi-configuration-step-connected", { ssid });
    }
    setIsConnecting(false);
    if (!connectResult) {
      currentScreenSetter("wifi-configuration-step-failed-to-connect", {
        ssid,
      });
    }
    return connectResult;
  };

  return (
    <div className="wifi-conf-step-one-container">
      <div>
        <p className="wifi-conf-step-one-title">STEP 2</p>
        <p
          className="wifi-conf-step-one-subtitle"
          style={{ display: "inline-block", marginRight: "2rem" }}
        >
          Connecting Marty to{" "}
          <span className="wifi-conf-step-two-ssid-name">{ssid}</span>
        </p>
        {isConnecting ? (
          <>
            <div className="wifi-list-spinner-container">
              <LoadingSpinner />
            </div>
            {isPausing && <p>Connected! Pausing connection for now...</p>}
          </>
        ) : (
          <>
            <p className="wifi-conf-step-one-a-ssid-title">WiFi password</p>
            <input
              type="text"
              placeholder="Password"
              className="wifi-conf-step-one-a-ssid-input-field"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </>
        )}
      </div>
      {!isConnecting && (
        <div className="wifi-conf-step-one-bottom-buttons-container">
          <SimpleButton title="Continue" onClick={onWifiConnect} />
        </div>
      )}
    </div>
  );
}
