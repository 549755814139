import martyConnector from "../../../MartyConnector/MartyConnector";
import modalState from "../../../state-observables/modal/ModalState";
import { RED } from "../../../styles/colors";
import SimpleButton from "../../buttons/SimpleButton";
import { Container } from "./styles";

export default function DisconnectConfirmationModal() {
  const disconnectHandler = () => {
    // disconnecting from marty
    martyConnector.disconnect();
    modalState.closeModal();
  };

  return (
    <Container>
      <SimpleButton onClick={() => modalState.closeModal()} title="Cancel" />
      <SimpleButton onClick={disconnectHandler} title="Disconnect" colour={RED}/>
    </Container>
  );
}
