import styles from './styles.module.css';
import AddMoreRobotsInfo from './AddMoreRobotsInfo';

type Props = {
};

function BluetoothContent() {

  return (
    <div className={styles.container}>
      <AddMoreRobotsInfo />
    </div>
  );
};

export default BluetoothContent;
