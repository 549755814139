import { RICConnector } from "@robotical/ricjs";
import { getOnboardingCookie, isOnboardingCookieExpired } from "./cookies";
import { ServiceProgramDatabase, DatabaseEnum, DatabaseManager, RegisteredUser } from "@robotical/analytics-gatherer/dist";
import serviceProgramFirebaseConfig from "./dbConfig";

export async function shouldShowOnboardingModal(serialNo: string | undefined): Promise<boolean> {

    let shouldShowModal = false;
    try {
        if (serialNo) {
            const cookie = getOnboardingCookie();

            const isCookieExpired = isOnboardingCookieExpired();
            const serialNoInCookie = cookie ? cookie.serialNumbers.includes(serialNo) : false;

            const dbManager = DatabaseManager.getInstance();
            const dbInstance = await dbManager.initializeOrGetDatabase(
                DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
            ) as ServiceProgramDatabase;
            let isSerialNoRegistered = false;

            isSerialNoRegistered = await dbInstance.isSerialNumberRegistered(serialNo);
            shouldShowModal = (!cookie || isCookieExpired || !serialNoInCookie) && !isSerialNoRegistered;
        }
    } catch (error) {
        console.error(error);
        // Don't show the modal if there was an error (probably no internet connection)
        return false;
    }
    return shouldShowModal;
}

export async function getSerialNumberStubbornly(
    serialNumberGetter: () => string | undefined,
    attempts: number
): Promise<string | undefined> {
    return new Promise(async (resolve, reject) => {
        let currentAttempt = 1;

        const tryGetSerialNumber = () => {
            let serialNo = serialNumberGetter();
            if (currentAttempt > attempts) {
                reject(new Error('Failed to get serial number after maximum attempts'));
                return;
            }

            if (serialNo) {
                resolve(serialNo);
            } else {
                currentAttempt++;
                setTimeout(tryGetSerialNumber, 1000);
            }
        };

        tryGetSerialNumber();
    });
}


export async function getSerialNumberFromDevices(devices: BluetoothDevice[]) {
    const serialNumbers: string[] = [];
    for (const device of devices) {
        const serialNumber = await getSerialNumberFromDevice(device);
        if (serialNumber) {
            serialNumbers.push(serialNumber);
        }
    }
    return serialNumbers;
}
export async function getSerialNumberFromDevice(device: BluetoothDevice) {
    try {
        const ricConnector = new RICConnector();
        const isConnected = await ricConnector.connect("WebBLE", device);
        if (isConnected) {
            const ricSystem = ricConnector.getRICSystem();
            const systemInfo = await ricSystem.getRICSystemInfo(true);
            const serialNumber = systemInfo.SerialNo;
            await ricConnector.disconnect();
            return serialNumber;
        }
        return undefined;
    } catch (error) {
        console.error(`Failed to get serial number from device: ${error}`);
        return undefined;
    }
}

export async function registerUser(email: string, establishment: string, serialNumbers: string[]): Promise<boolean> {
    try {
        const dbManager = DatabaseManager.getInstance();
        const dbInstance = await dbManager.initializeOrGetDatabase(
            DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
        ) as ServiceProgramDatabase;
        // also register serial numbers
        return dbInstance.registerUser(email, establishment, serialNumbers);
    } catch (error) {
        console.error(`Failed to register user: ${error}`);
        return false;
    }
}

export async function isUserRegistered(email: string): Promise<boolean> {
    try {
        const dbManager = DatabaseManager.getInstance();
        const dbInstance = await dbManager.initializeOrGetDatabase(
            DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
        ) as ServiceProgramDatabase;
        return !! await dbInstance.getUser(email);
    } catch (error) {
        console.error(`Failed to check if user is registered: ${error}`);
        return false;
    }
}

export async function getUserGivenEmail(email: string): Promise<RegisteredUser | null> {
    try {
        const dbManager = DatabaseManager.getInstance();
        const dbInstance = await dbManager.initializeOrGetDatabase(
            DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
        ) as ServiceProgramDatabase;
        const user = await dbInstance.getUser(email);
        return user;
    } catch (error) {
        console.error(`Failed to get user given email: ${error}`);
        return null;
    }
}

export const isSerialNumberRegistered = async (serialNo: string): Promise<boolean> => {
    try {
        const dbManager = DatabaseManager.getInstance();
        const dbInstance = await dbManager.initializeOrGetDatabase(
            DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
        ) as ServiceProgramDatabase;
        return dbInstance.isSerialNumberRegistered(serialNo);
    } catch (error) {
        console.error(`Failed to check if serial number is registered: ${error}`);
        return false;
    }
};

export const createTicket = async (serialNo: string, title: string, description: string): Promise<boolean> => {
    try {
        // The below happens outside of this function
        // const isSeNoRegistered = await isSerialNumberRegistered(serialNo);
        // if (!isSeNoRegistered) {
        //     return false;
        // }
        const dbManager = DatabaseManager.getInstance();
        const dbInstance = await dbManager.initializeOrGetDatabase(
            DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
        ) as ServiceProgramDatabase;
        // first we get the serial number, so we can get the email that serial number is registered to
        const serialNumber = await dbInstance.getSerialNumber(serialNo);
        if (serialNumber) {
            return !!await dbInstance.registerReport(serialNumber.email, serialNo, title, description);
        }
        return false;
    } catch (error) {
        console.error(`Failed to check if serial number is registered: ${error}`);
        return false;
    }
};

export const getEmailGivenSerialNumber = async (serialNo: string): Promise<string | undefined> => {
    try {
        const dbManager = DatabaseManager.getInstance();
        const dbInstance = await dbManager.initializeOrGetDatabase(
            DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
        ) as ServiceProgramDatabase;
        const serialNumber = await dbInstance.getSerialNumber(serialNo);
        return serialNumber?.email;
    } catch (error) {
        console.error(`Failed to get email given serial number: ${error}`);
        return undefined;
    }
}

export const addMartyNameToSerialNumber = async (serialNo: string, name: string): Promise<boolean> => {
    try {
        const dbManager = DatabaseManager.getInstance();
        const dbInstance = await dbManager.initializeOrGetDatabase(
            DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
        ) as ServiceProgramDatabase;
        return dbInstance.addMartyNameToSerialNumber(serialNo, name);
    } catch (error) {
        console.error(`Failed to add Marty name to serial number: ${error}`);
        return false;
    }
}