import { useState } from "react";
import modalState from "../../../state-observables/modal/ModalState";
import { DB_URL } from "../../../utils/analytics/bug-report-db-urls";
import randomHashGenerator from "../../../utils/helpers/randomHashGenerator";
import SimpleButton from "../../buttons/SimpleButton";
import "./styles.css";

export default function BugReportModal() {
  const [report, setReport] = useState("");
  const [email, setEmail] = useState("");
  const [reportSent, setReportSent] = useState(false);

  const onReport = () => {
    if (!report) return;
    if (!email) {
      const confirm = window.confirm(
        "We noticed you didn't leave your email. Without it we won't be able to reach out and help you. If you want to leave your email, press cancel and fill in the email field, otherwise press OK to send the report without your email."
      );
      if (!confirm) return;
    }
    const reportWithContact = { text: `${email ? email : "Anonymous"}: ${report}` };
    fetch(DB_URL + randomHashGenerator() + ".json", {
      method: "PATCH",
      headers: {
        Application: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reportWithContact),
    })
      .then((res) => {
        // also sending to slack webhook
        fetch(
          "https://hooks.slack.com/services/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",
          {
            method: "POST",
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
            },
            body: JSON.stringify(reportWithContact),
          }
        )
          .then()
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
    setTimeout(() => {
      setReportSent(true);
    }, 500);
    setTimeout(() => {
      modalState.closeModal();
    }, 1500);
  };

  return (
    <div className="bug-report-modal-container">
      {reportSent ? (
        "Thank you for your feedback!"
      ) : (
        <>
          <div className="bug-report-modal-info">
            Please help up improve Marty! Please let us know if you spot any irregularities/bugs. <strong className="bug-report-modal-info-strong">You can
              optionally leave your contact details so we can reach out and help you</strong>.
            Thank you!
          </div>
          <div className="bug-report-modal-form">
            <textarea
              className="bug-report-modal-text-input"
              placeholder="Your report..."
              onChange={(e) => setReport(e.target.value)}
              value={report}
            />
            <label className="bug-report-modal-email-label">
              Provide your email if you want us to get back to you with a solution!
            </label>
            <input
              type="email"
              className="bug-report-modal-email-input"
              placeholder="Email (optional)"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>
          <div className="bug-report-modal-buttons">
            <SimpleButton
              onClick={() => modalState.closeModal()}
              title="Cancel"
            />
            <SimpleButton onClick={onReport} title="Report" />
          </div>
        </>
      )}
    </div>
  );
}
