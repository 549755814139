import ConnectButton from "../../components/ConnectButton";
import HelpSection from "../../components/HelpSection";
import NavButtons from "../../components/NavButtons";
import "./styles.css";

export default function NavMenu() {
  return (
    <div className="nav-menu-container">
      <div className="nav-menu-connect-button-container">
        <ConnectButton />
      </div>
      <div className="nav-menu-nav-buttons-container">
        <NavButtons />
      </div>
      <div className="nav-menu-help-section-container">
        <HelpSection />
      </div>
    </div>
  );
}
