import { createElement, useContext } from "react";
import ButtonConn from "./ButtonConn";
import ButtonDisconn from "./ButtonDisconn";

import MartyConnectionContext from "../../store/marty-connection-context";
import connectToMarty from "../../utils/network-api/connect-to-marty-function";
import "./styles.css";
import modalState from "../../state-observables/modal/ModalState";
import DisconnectConfirmationModal from "../modals/DisconnectConfirmation";

const ConnectButton = () => {
  const connectionCtx = useContext(MartyConnectionContext);
  const connectionLoading = connectionCtx.isConnectionLoading;
  const setConnectionLoading = connectionCtx.setIsConnectionLoading;

  const onClickConnect = async () => {
    setConnectionLoading(true);
    try {
      await connectToMarty();
      setConnectionLoading(false);
    } catch (e) {
      console.log(e);
      setConnectionLoading(false);
    }
  };

  const onClickDisconnect = () => {
    modalState.setModal(createElement(DisconnectConfirmationModal), "Are you sure you want to disconnect from your Marty?");
  };

  return (
    <div className="connect-button-container">
      {connectionCtx.isConnected ? (
        <ButtonDisconn onClick={onClickDisconnect} />
      ) : (
        <ButtonConn onClick={onClickConnect} isLoading={connectionLoading} />
      )}
    </div>
  );
};

export default ConnectButton;
