import { instanceTitleBuilder } from "../../sound-streaming-db-urls";

export default class SoundStreamingWarningInstance {
  private platform = "webapp";
  private streamingSetup: string;

  constructor(streamingSetup: string) {
    this.streamingSetup = streamingSetup;
    this.fire();
  }

  fire() {
    const data = {
      streamingSetup: this.streamingSetup,
      platform: this.platform,
    };
    const dataStringified = JSON.stringify(data);
    try {
      return fetch("SOUND_STREAMING_DB_URL" + instanceTitleBuilder() + ".json", {
        method: "PATCH",
        headers: {
          Application: "application/json",
          "Content-Type": "application/json",
        },
        body: dataStringified,
      });
    } catch (err) {
      console.log(err, "FirebaseManager.ts", "line: ", "148");
    }
  }

}
