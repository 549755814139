import { useState } from "react";
import SimpleButton from "../../components/buttons/SimpleButton";
import { ConfigurationComponentType } from "../../navigation/ConfigurationNavigator";
import "./styles.css";

interface WifiConfStepConnectedProps extends ConfigurationComponentType {
  ssid: string;
}

export default function WifiConfStepConnected({
  currentScreenSetter,
  ssid,
}: WifiConfStepConnectedProps) {

  return (
    <div className="wifi-conf-step-one-container">
      <div>
        <p className="wifi-conf-step-one-title">STEP 3</p>
        <p className="wifi-conf-step-one-subtitle">
          Connected Marty to{" "}
          <span className="wifi-conf-step-two-ssid-name">{ssid}</span>
        </p>
        <p className="wifi-conf-step-one-subtitle">
          Your Marty has succesfully connected to: {ssid}.
        </p>
        <p className="wifi-conf-step-one-subtitle">
          However, this connection is paused whilst your Marty's bluetooth is connected to the phone app.
        </p>
        <p className="wifi-conf-step-one-subtitle">
          Please disconnect your Marty from the phone app before trying to connect via wifi.
        </p>
      </div>
        <div className="wifi-conf-step-one-bottom-buttons-container">
          <SimpleButton title="COMPLETE SETUP" onClick={() => currentScreenSetter("configuration")} />
        </div>
    </div>
  );
}
