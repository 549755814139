import { DatabaseEnum, DatabaseManager, LoginToken, ServiceProgramDatabase } from "@robotical/analytics-gatherer";
import serviceProgramFirebaseConfig from "../../ServiceProgram/dbConfig";

const endpoint = 'https://d88ja061rl.execute-api.us-east-1.amazonaws.com/prod/sendMail';


export default class WarrantyMailer {

    static storeLoginToken = async (email: string): Promise<LoginToken | false> => {
        const dbManager = DatabaseManager.getInstance();
        const dbInstance = await dbManager.initializeOrGetDatabase(
            DatabaseEnum.SERVICE_PROGRAM, serviceProgramFirebaseConfig, DatabaseEnum.SERVICE_PROGRAM
        ) as ServiceProgramDatabase;
        return dbInstance.addLoginTokenToUser(email);
    };

    static sendSerialNumbersLinkEmail = async (email: string, serialNos: string[]): Promise<boolean> => {
        let loginToken: LoginToken | false = false;
        try {
            loginToken = await this.storeLoginToken(email);
            if (!loginToken) {
                console.log('Error storing login token');
                return false;
            }
        } catch (error) {
            console.error('Error storing login token', error);
            return false;
        }

        let urlQuery = loginToken.queryString + '&sns=' + serialNos.join(',');
        try {
            const fetchRes = await fetch(endpoint, {
                method: 'POST',
                body: JSON.stringify({
                    email,
                    subject: 'Robotical - Warranty Registration',
                    queryString: urlQuery,
                }),
                headers: {
                    "Content-Type": "application/json",
                }
            });
            if (fetchRes.status === 200) {
                return true;
            } else {
                console.error('Response status not 200', fetchRes);
                return false;
            }
        } catch (error) {
            console.error('Error sending email', error);
            return false;
        }
    }

    static sendRegistrationConfirmationEmail = async (email: string, school: string, serialNos: string[]): Promise<boolean> => {
        // expire after 1 day
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 1);
        let urlQuery = '?cta=registration' +
            '&school=' +
            ServiceProgramDatabase.encodeEmail(school) +
            '&email=' +
            ServiceProgramDatabase.encodeEmail(email) +
            '&expiry=' +
            expiryDate.toISOString() +
            '&sns=' +
            serialNos.join(',');
        try {
            const fetchRes = await fetch(endpoint, {
                method: 'POST',
                body: JSON.stringify({
                    email,
                    subject: 'Robotical - Warranty Registration',
                    queryString: urlQuery,
                }),
                headers: {
                    "Content-Type": "application/json",
                }
            });
            if (fetchRes.status === 200) {
                return true;
            } else {
                console.error('Response status not 200', fetchRes);
                return false;
            }
        } catch (error) {
            console.error('Error sending email', error);
            return false;
        }
    }
}

