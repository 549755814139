import { useEffect } from "react";
import SimpleButton from "../../components/buttons/SimpleButton";
import { CalibrationComponentType } from "../../navigation/CalibrationNavigator";
import {
  getCalibrationImage,
  getCalibrationStrings,
  Steps,
} from "../../utils/helpers/calibration-screen";
import "./styles.css";
import { AppDatabase, DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist";
import { appConfig } from "../../dbConfigs/configs";

interface CalibrationScreenProps extends CalibrationComponentType { }

export default function CalibrationScreen({
  currentScreenSetter,
}: CalibrationScreenProps) {
  useEffect(() => {
    // Analytics
    const dbManager = DatabaseManager.getInstance();
    dbManager.initializeOrGetDatabase(DatabaseEnum.APP, appConfig, DatabaseEnum.APP)
      .then((db) => {
        (db as AppDatabase).storeVisitedScreen("calibration");
      }).catch((err) => console.log(err));
  }, []);

  return (
    <div className="calibration-screen-container">
      <div className="calibration-screen-marty-image-container">
        <img src={getCalibrationImage(Steps.STEP01)} alt="marty-image" />
      </div>
      <div className="calibration-screen-middle-text-container">
        <p className="calibration-screen-middle-text-title">
          {getCalibrationStrings(Steps.STEP01).title}
        </p>
        <p className="calibration-screen-middle-text-subtitle">
          {getCalibrationStrings(Steps.STEP01).hint}
        </p>
      </div>
      <div className="calibration-screen-bottom-buttons-container">
        <SimpleButton
          title="EYE CALIBRATION"
          onClick={() => currentScreenSetter("eye-calibration")}
        />
        <SimpleButton
          title="ARM CALIBRATION"
          onClick={() => currentScreenSetter("arm-calibration")}
        />
        <SimpleButton
          title="LEG CALIBRATION"
          onClick={() => currentScreenSetter("leg-calibration-1")}
        />
        <SimpleButton
          title="COLOR SENSOR CALIBRATION"
          onClick={() => currentScreenSetter("colour-sensor-calibration")}
        />
      </div>
    </div>
  );
}
