import { useEffect, useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { toast } from "react-toastify";
import FirmwareVersionChekcer from "../../utils/FirmwareVersionChecker";
import martyConnector from "../../MartyConnector/MartyConnector";

const FWVersionOutdateWarning = () => {
  const [isMartyOutdated, setIsMartyOutdated] = useState(false);

  useEffect(() => {
    if (martyConnector.isVerified) {
      FirmwareVersionChekcer.getAndCheckVersion(martyConnector.martyVersion)
        .then((isOutdated) => {
          setIsMartyOutdated(!!isOutdated);
        })
        .catch((er) => {
          console.log(er);
        });
    }
  }, [martyConnector.isVerified]);

  const bringDownAlert = () => {
    toast.warn(
      "Marty version is out of date. Please update your Marty to the latest version for optimal experience."
    );
  };

  // useEffect(() => {
  //  comment this in if you want to see the warning on every load, otherwise it'll stay as an exclamation mark in the top right corner of the button
  //     if (isMartyOutdated && dropDownAlertRef) {
  //         bringDownAlert()
  //     }
  // }, [dropDownAlertRef]);

  // if (!isMartyOutdated) {
  if (true) { // we don't want to show the question mark sign anymore
    return null;
  }

  return (
    <button
      onClick={bringDownAlert}
      style={{
        position: "absolute",
        top: 0,
        right: 10,
        width: 20,
        height: 20,
        cursor: "pointer",
        background: "none",
        border: "none",
        outline: "none",
      }}
    >
      <FiAlertCircle color="orange" size={30} />
    </button>
  );
};

export default FWVersionOutdateWarning;
