import styles from "./styles.module.css";
import ms, { secondaryModalState } from "../../../../state-observables/modal/ModalState";
import { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import WarrantyMailer from "../../../../utils/warranty-service-mailer/WarrantyMailer";
import { toast } from "react-toastify";

type Props = {
  email: string;
  serialNumbers: string[];
};

function EmailAlreadyRegisteredModal({ email, serialNumbers }: Props) {
  const [isLoadingMessage, setIsLoadingMessage] = useState("");

  const onRegisterClick = async () => {
    setIsLoadingMessage("Sending registration email...");
    try {
      const didSend = await WarrantyMailer.sendSerialNumbersLinkEmail(email, serialNumbers);
      if (!didSend) {
        toast.error("Something went wrong while sending the email. Please try again later.");
      } else {
        toast.success(`Registration email sent to ${email}! To complete the registration, please click the link in the email.`, { autoClose: false });
        secondaryModalState.closeModal();
        ms.closeModal();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingMessage("");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p>
          The email address you provided <strong>({email})</strong> is already registered with our Warranty Service.
        </p>
        <p>
          You can continue to register the additional Marty(s) using this email address.
        </p>
        <p>
          If you want to proceed with the registration, please click the "Register" button below.
          A confirmation email will be sent to {email}.
        </p>
      </div>
      {isLoadingMessage && (
        <div className={styles.scanningForMoreRobots}>
          <h5>
            {isLoadingMessage} <FaSpinner />
          </h5>
        </div>
      )}
      <div className={styles.buttonsContainer}>
        <button
          className={[styles.button, styles.registerButton, isLoadingMessage !== "" ? styles.disabledButton : ""].join(" ")}
          onClick={onRegisterClick}
        >
          Register
        </button>
        <button
          className={[styles.button, styles.cancelButton, isLoadingMessage !== "" ? styles.disabledButton : ""].join(" ")}
          onClick={() => secondaryModalState.closeModal()}
        >
          I don't want to register any more Marty robots
        </button>
      </div>
    </div>
  );
}

export default EmailAlreadyRegisteredModal;
