import { CalibrationComponentType } from "../../../navigation/CalibrationNavigator";
import {
  getCalibrationButton,
  getCalibrationImage,
  getCalibrationStrings,
  Steps,
} from "../../../utils/helpers/calibration-screen";
import SimpleButton from "../../buttons/SimpleButton";
import "../../../screens/Calibration/styles.css";
import { useEffect, useState } from "react";
import martyConnector from "../../../MartyConnector/MartyConnector";
import LoadingSpinner from "../../LoadingSpinner";

interface ColourSensorCalibrationScreenProps extends CalibrationComponentType {}

export default function ColourSensorCalibration({
  currentScreenSetter,
}: ColourSensorCalibrationScreenProps) {
  const [isBusy, setBusy] = useState<boolean>(false);
  const [colourSensorConnected, setColourSensorConnected] = useState<boolean>(false);
  const [messageToUser, setMessageToUser] = useState<string>("");

  
  useEffect(() => {
    setMessageToUser("Looking for colour sensor(s)...");
    setBusy(true);
    const asyncFunc = async () => {
      const colourSensorNames = await martyConnector.getConnectedColourSensors();
      if (colourSensorNames.length > 0) {
        setMessageToUser("Place Marty on a white surface and start calibration!");
        setColourSensorConnected(true);
      } else {
        setMessageToUser("Colour sensor(s) not found! Please connect one and try again.");
        setColourSensorConnected(false);
      }
      setBusy(false);
    }
    try {
      asyncFunc();
    } catch {
      setBusy(false);
    }
  }, []);


  const onSaveCalibration = async () => {
    try {
      setBusy(true);
      const didCalibrate = await martyConnector.calibrateColourSensors();
      console.log("didCalibrate", didCalibrate)
      if (!didCalibrate) {
        setMessageToUser("Failed to calibrate colour sensor(s)! Please try again.");
        setBusy(false);
        return;
      }
      setBusy(false);
      currentScreenSetter("done-calibration");
    } catch {
      console.warn(`Failed to execute calibration step`);
    }
  };

  return (
    <div className="calibration-screen-container">
      <div className="calibration-screen-marty-image-container">
        <img src={getCalibrationImage(Steps.STEP_COLOUR_SENSOR)} alt="marty-image" />
      </div>
      <div className="calibration-screen-middle-text-container">
        <p className="calibration-screen-middle-text-title">
          {getCalibrationStrings(Steps.STEP_COLOUR_SENSOR).title}
        </p>
        <p className="calibration-screen-middle-text-subtitle">
          {messageToUser}
        </p>
        {isBusy && (
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 50,
            width: 50,
            margin: "0 auto"
          }}>
            <LoadingSpinner />
          </div>
        )}
      </div>
      <div className="calibration-screen-bottom-buttons-container">
        <SimpleButton
          title={getCalibrationButton(Steps.STEP_COLOUR_SENSOR)}
          onClick={onSaveCalibration}
          disabled={isBusy || !colourSensorConnected}
        />
      </div>
    </div>
  );
}
