import InfoField from "../../components/InfoField";
import martyConnector from "../../MartyConnector/MartyConnector";
import { RICWifiConnState } from "@robotical/ricjs";
import { ConfigurationComponentType } from "../../navigation/ConfigurationNavigator";
import { ReactComponent as BluetoothSVG } from "../../assets/configuration/bluetooth.svg";
import { ReactComponent as WifiSVG } from "../../assets/configuration/wifi.svg";
import { ReactComponent as MartyDiagnosticsSVG } from "../../assets/configuration/marty-diagnostics.svg";
import { HINT_GREY, MAIN_BLUE } from "../../styles/colors";
import "./styles.css";
import UpdateMartyName from "../../components/UpdateMartyName";
import ImageButton from "../../components/buttons/SVGImageButton";
import { ReactComponent as CalibrationImageSVG } from "../../assets/configuration/calibration.svg";
import wifiConfigSub, {
  FW_VERSION_RSSI_WIFI,
} from "../../utils/wifi-configuration-subtitle-gen";
import { createElement, useEffect, useState } from "react";
import isVersionGreater from "../../utils/helpers/compare-version";
import ms from "../../state-observables/modal/ModalState";
import DisconnectConfirmationModal from "../../components/modals/DisconnectConfirmation";
import SimpleButton from "../../components/buttons/SimpleButton";
import openNewTab from "../../utils/open-new-tab";
import { AppDatabase, DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist";
import { appConfig } from "../../dbConfigs/configs";

interface ConfigurationScreenProps extends ConfigurationComponentType {}

function ConfigurationScreen({
  currentScreenSetter,
}: ConfigurationScreenProps) {
  const dummyScreenRefresh = useState(0)[1];

  const onPressWifi = async () => {
    if (!isVersionGreater(martyConnector.martyVersion, FW_VERSION_RSSI_WIFI))
      return;
    //check if wifi is currently connected or paused
    //if no wifi has been set up these will both be 0/false
    if (
      martyConnector.getCachedWifiStatus().connState ===
        RICWifiConnState.WIFI_CONN_CONNECTED ||
      martyConnector.getCachedWifiStatus().ssid
    ) {
      await martyConnector.wifiDisconnect();
      await new Promise((resolve) => setTimeout(resolve, 2000));
      currentScreenSetter("configuration", {
        dummy: "just refreshing the state",
      });
    } else {
      currentScreenSetter("wifi-configuration-step-1");
    }
  };

  useEffect(() => {
    // Analytics
    const dbManager = DatabaseManager.getInstance();
    dbManager.initializeOrGetDatabase(DatabaseEnum.APP, appConfig, DatabaseEnum.APP)
      .then((db) => {
        (db as AppDatabase).storeVisitedScreen("marty-configuration");
      }).catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    // making sure the cached wifi info are up to date
    martyConnector
      .getWiFiConnStatus()
      .then((_) => {
        dummyScreenRefresh((old) => old + 1);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div className="configuration-screen-container">
      <div className="configuration-screen-info-container">
        <InfoField
          title="Bluetooth"
          subtitle="Connected"
          buttonText="Disconnect"
          buttonTextColour={"red"}
          IconSVG={BluetoothSVG}
          IconFill={MAIN_BLUE}
          onClick={() =>
            ms.setModal(
              createElement(DisconnectConfirmationModal),
              "Are you sure you want to disconnect from your Marty?"
            )
          }
        />

        <InfoField
          title="Wifi"
          IconSVG={WifiSVG}
          IconFill={MAIN_BLUE}
          subtitle={wifiConfigSub()}
          buttonText={
            martyConnector.getCachedWifiStatus().connState ===
              RICWifiConnState.WIFI_CONN_CONNECTED ||
            martyConnector.getCachedWifiStatus().ssid
              ? "Disconnect"
              : "Connect"
          }
          buttonTextColour={
            martyConnector.getCachedWifiStatus().connState ===
            RICWifiConnState.WIFI_CONN_CONNECTED ||
            martyConnector.getCachedWifiStatus().ssid
              ? "red"
              : HINT_GREY
          }
          onClick={onPressWifi}
        />

        <InfoField
          title="Diagnostics"
          subtitle="Check Marty Diagnostics"
          buttonText="Open"
          buttonTextColour={HINT_GREY}
          IconSVG={MartyDiagnosticsSVG}
          IconFill={MAIN_BLUE}
          onClick={() => currentScreenSetter("diagnostics")}
        />
      </div>

      <div className="configuration-screen-update-name-container">
        <UpdateMartyName />
      </div>

      <div className="configuration-screen-calibration-container">
        <ImageButton
          SVGImage={CalibrationImageSVG}
          onClick={() => currentScreenSetter("calibration")}
          title="CALIBRATE"
          subtitle="Keep your Marty on track"
        />
      </div>

      <div className="configuration-screen-addons-container">
        <ImageButton
          SVGImage={CalibrationImageSVG}
          onClick={() => currentScreenSetter("addons")}
          title="ADD-ONS"
          subtitle="Manage Marty's expansions"
        />
      </div>

      <div className="configuration-screen-updates">
        <SimpleButton
          onClick={() => openNewTab("https://updates.robotical.io/web-updater")}
          title="UPDATE YOUR MARTY"
        />
        <h4 className="marty-version-text">Current Version: {martyConnector.martyVersion}</h4>
      </div>
    </div>
  );
}

export default ConfigurationScreen;
