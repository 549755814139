import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import NavLayout from "../../layout/NavLayout";
import MartyBlocksConnector, {
  MartyBlocksIF,
} from "../../MartyConnector/MartyBlocksConnector";
import martyConnector from "../../MartyConnector/MartyConnector";
import draggableModalState from "../../state-observables/modal/DraggableModalState";
import { AppDatabase, DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist";
import { appConfig } from "../../dbConfigs/configs";

const MainWindow = styled.div`
  text-align: center;
  display: flex; 
  flex-direction: row;
  height: 100vh;
  width: 100%;
`;

const ContentWindow = styled.div`
  flex: auto;
  height: 100%;
`;

function MartyBlocks() {
  const [searchParams, _] = useSearchParams();
  const [isMartyBlocksLoaded, setIsMartyBlocksLoaded] = useState(false);
  const navigate = useNavigate();

  const handleMartyBlocksLoad = (e: SyntheticEvent<HTMLDivElement>) => {
    setIsMartyBlocksLoaded(true);
    // focus on the object
    const object = e.target as HTMLObjectElement;
    console.log("focusing")
    object.contentDocument?.body.focus();
    object.contentWindow?.focus();
  };

  useEffect(() => {
    // Analytics
    const dbManager = DatabaseManager.getInstance();
    dbManager.initializeOrGetDatabase(DatabaseEnum.APP, appConfig, DatabaseEnum.APP)
      .then((db) => {
        (db as AppDatabase).storeVisitedScreen("scratch");
      }).catch((err) => console.log(err));
      martyConnector.sessionDbs.MartyBlocks.startSession();
    return () => {
      martyConnector.sessionDbs.MartyBlocks.endSession();
    }
  }, []);

  useEffect(() => {
    let mbConnector: MartyBlocksConnector;
    if (isMartyBlocksLoaded) {
      mbConnector = new MartyBlocksConnector();
      for (let i = 0; i < 10; i++) {
        if (window[i] && (window[i] as any).mv2Interface) {
          mbConnector.setApp((window[i] as any).mv2Interface as MartyBlocksIF);
          break;
        }
      }
      martyConnector.addUpdaterRemover(
        mbConnector.clearUpdater.bind(mbConnector)
      );
      mbConnector.loadWithProject(searchParams.get("p"));
    }
    return () => {
      mbConnector && mbConnector.clearUpdater();
      if (draggableModalState.modalContent) {
        return draggableModalState.closeModal();
      }
    };
  }, [isMartyBlocksLoaded]);

  return (
    <NavLayout onClickBack={() => navigate("/")}>
      <MainWindow>
        <ContentWindow>
          <object
            type="text/html"
            data={`./MartyBlocks/index.html?p=${searchParams.get("p")}&&tutorial=${searchParams.get("tutorial")}`}
            onLoad={handleMartyBlocksLoad}
            style={{ width: "100%", height: "100%" }}
            id="martyBlocksContent"
          ></object>
          {/* <object type="text/html" data="http://localhost:8601/index.html" onLoad={handleMartyBlocksLoad} style={{width:'100%', height:'100%'}}>
        </object> */}
        </ContentWindow>
      </MainWindow>
    </NavLayout>
  );
}

export default MartyBlocks;
