import { MartyConnectionContextTypes } from "../store/marty-connection-context";
import { UserRoleContextTypes } from "../store/user-role-context";

export default class StoreEventEmitter {
  public connectionCtx: MartyConnectionContextTypes | null = null;
  public userRoleCtx: UserRoleContextTypes | null = null;
  constructor() {}

  setConnectionCtx(connectionCtx: MartyConnectionContextTypes) {
    this.connectionCtx = connectionCtx;
  }

  setUserRoleCtx(userRoleCtx: UserRoleContextTypes) {
    if (this.userRoleCtx) {
      this.userRoleCtx = userRoleCtx;
    }
  }
}
