import "./styles.css";
import { useState } from "react";
import { ReactComponent as SettingsSVG } from "../../assets/configuration/settings.svg";
import SimpleButton from "../buttons/SimpleButton";
import LabeledInfo from "../LabeledInfo";
import LabeledInput from "../LabeledInput";
import { RED } from "../../styles/colors";

interface AddOnItemProps {
  name: string;
  isConnected: boolean;
  isConfigured: boolean;
  isOpen?: boolean;
  onPress?: () => void;
  onPressNo?: () => void;
  onPressSetName: (serialNo: string | undefined, newName: string) => void;
  onPressDelete: () => void;
  serialNo?: string;
  addOnType?: string;
  versionStr?: string;
  whoami?: string;
}

const AddOnItem = ({
  name,
  isConnected,
  isConfigured,
  isOpen,
  onPress,
  onPressNo,
  onPressSetName,
  onPressDelete,
  serialNo,
  versionStr,
  addOnType,
  whoami,
}: AddOnItemProps) => {
  const [addOnName, setAddOnName] = useState(name);
  const [pendingDelete, setPendingDelete] = useState(false);

  return (
    <div className="addon-item-container">
      <div className="addon-item-inner-container" onClick={onPress}>
        <div className="addon-item-name-container">
          <p
            className="addon-item-name"
            style={{
              color: isConnected ? "" : "#aaaaaa",
              textDecoration: pendingDelete ? "line-through" : "none",
            }}
          >
            {addOnName}
            {whoami ? " - " + whoami : null}{" "}
            {isConnected ? "" : " (Not Connected)"}
          </p>
        </div>

        <div>
          <SettingsSVG />
        </div>
      </div>

      {isOpen ? (
        <div className="addon-item-configuration-container">
          <div className="addon-item-type">
            <LabeledInfo label="Add-on Type" info={addOnType} />
          </div>
          <div className="addon-item-serial-number">
            <LabeledInfo label="Serial Number" info={serialNo} />
          </div>
          <div className="addon-item-version">
            <LabeledInfo label="Version" info={versionStr} />
          </div>
          <div className="addon-item-name-input">
            <LabeledInput
              label="Name this add-on"
              value={addOnName}
              placeholder="Add-on Name"
              onChange={(e) => setAddOnName(e.target.value)}
            />
          </div>
          <div className="addon-item-update-name-button">
            <SimpleButton
              title="Update Name"
              onClick={() => {
                onPressSetName(serialNo, addOnName);
                setPendingDelete(false);
              }}
            />
          </div>
          <div className="addon-item-delete-button">
            <SimpleButton
              title="Delete"
              onClick={() => {
                onPressDelete();
                setPendingDelete(true);
              }}
              colour={RED}
              disabled={isConfigured ? false : true}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AddOnItem;
