import { useEffect, useState } from "react";
import martyConnector from "../../MartyConnector/MartyConnector";
import "./styles.css";
import { AppDatabase, DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist";
import { appConfig } from "../../dbConfigs/configs";

const Diagnostics = () => {
  const [rxRate, setRxRate] = useState("");
  const [worstMs, setWorstMs] = useState(0);
  const [lastMs, setLastMs] = useState(0);
  const [bestMs, setBestMs] = useState(0);
  const [retries, setRetries] = useState(0);

  useEffect(() => {
    // Analytics
    const dbManager = DatabaseManager.getInstance();
    dbManager.initializeOrGetDatabase(DatabaseEnum.APP, appConfig, DatabaseEnum.APP)
      .then((db) => {
        (db as AppDatabase).storeVisitedScreen("diagnostics");
      }).catch((err) => console.log(err));
  }, []);

  const updateDebugInfo = () => {
    const fetchedRxRate = (
      Math.round(martyConnector.getCommsStats().getMsgRxRate() * 100) / 100
    ).toFixed(2);
    const fetchedWorstMs = martyConnector.getCommsStats().getRTWorstMs();
    const fetchedLastMs = martyConnector.getCommsStats().getRTLastMs();
    const fetchedBestMs = martyConnector.getCommsStats().getRTBestMs();
    const fetchedRetries = martyConnector.getCommsStats().getRetries();

    setRxRate(fetchedRxRate);
    setWorstMs(fetchedWorstMs);
    setLastMs(fetchedLastMs);
    setBestMs(fetchedBestMs);
    setRetries(fetchedRetries);
  };

  useEffect(() => {
    const updateInterval = setInterval(() => {
      updateDebugInfo();
    }, 300);

    return () => clearInterval(updateInterval);
  }, []);

  return (
    <div className="diagnostics-screen-container">
      <ul className="diagnostics-screen-list-container">
        <li>rxRate: {rxRate}</li>
        <li>Worst ms: {worstMs}</li>
        <li>Last ms: {lastMs}</li>
        <li>Best ms: {bestMs}</li>
        <li>Retries: {retries}</li>
      </ul>
    </div>
  );
};

export default Diagnostics;
