import { RICSystemInfo } from "@robotical/ricjs";
import DisconnectConfirmationModal from "../components/modals/DisconnectConfirmation";
import NotificationsEmitter from "../MartyNotifications/NotificationsEmitter";
import ms from "../state-observables/modal/ModalState";
import connectToMarty from "../utils/network-api/connect-to-marty-function";
import AppConnector from "./AppConnector";
import martyConnector, { MartyConnector } from "./MartyConnector";
import draggableModalState from "../state-observables/modal/DraggableModalState";
import SensorsDashboardModal from "../components/modals/ SensorsDashboardModal";
import { createElement } from "react";

export type MartyBlocksIF = {
  accel: string;
  magneto: string;
  addons: string;
  battRemainCapacityPercent: number;
  isConnected: boolean;
  rssi: number;
  power: string;
  servos: string;
  send_REST: (msg: string) => void;
  streamAudio: (audioData: Uint8Array, duration: number) => void;
  isStreamStarting: boolean;
  setBattRemainCapacityPercent: (battRemainCapacityPercent: number) => void;
  setIsConnected: (isConnected: boolean) => void;
  setRSSI: (rssi: number) => void;
  removeEventListeners: () => void;
  systemInfo: RICSystemInfo;
  mp3EncodingBitRate: number;
  mp3EncodingSampleRate: number;
  mp3EncodingAvgFlag: boolean;
  projectToLoadId: string | null;
  sendFeedbackToServer: (feedback: string) => void;
};
/* 
    Nikos: I had to make public some properties of the MartyConnector class
    because I don't know how to extend from a singleton class.
    Extending from that class would allow me access to its private/protected properites.
*/

enum MartyBlocksDBsSessionsMessagesE {
  END_ML_SESSION = "end-ml-session",
  SET_CODE_ASSES_SESSION_ACTIVE = "set-code-asses-session-active",
  END_CODE_ASSES_SESSION = "end-code-asses-session",
  START_CODE_ASSES_SESSION = "start-code-asses-session",
  START_AND_SET_ACTIVE_AND_END_ML_SESSION = "start-and-set-active-and-end-ml-session",
  SET_ML_SESSION_ACTIVE = "set-ml-session-active",
  START_ML_SESSION = "start-ml-session",
  START_PYTHON_TRANSLATOR_SESSION = "start-python-translator-session",
  SET_PYTHON_TRANSLATOR_SESSION_ACTIVE = "set-python-translator-session-active",
  END_PYTHON_TRANSLATOR_SESSION = "end-python-translator-session",
  START_AND_SET_PYTHON_TRANSLATOR_SESSION_ACTIVE = "start-and-set-python-translator-session-active",
  STORE_USED_BLOCK_MB = "save-used-block-mb",
}


class MartyBlocksConnector extends AppConnector {
  protected martyConnector: MartyConnector = martyConnector;
  // Marty Blocks window
  public _martyBlocksIF: MartyBlocksIF | null = null;

  // Sensor readings update timer
  protected _updateTimer: ReturnType<typeof setInterval> | null = null;

  constructor() {
    super();
  }

  async clearUpdater() {
    // busy wait to make sure we clearing
    // the _updateHooks interval after
    // we've updated the last state of marty
    // (marty should have been disconnected by that time)
    await new Promise((r) => setTimeout(r, 200));
    if (this._updateTimer) {
      clearInterval(this._updateTimer);
      this._updateTimer = null;
    }
    this.unhookFromRicConnector();
  }

  protected unhookFromRicConnector() {
    // resetting properties so next time we
    // fire an event it'll pass through the first
    // check which checks if new property is same
    // as old property
    if (this._martyBlocksIF) {
      this._martyBlocksIF.battRemainCapacityPercent = 0;
      this._martyBlocksIF.rssi = -200;
      this._martyBlocksIF.isConnected = false;
    }
  }

  protected hookupToRicConnector() {
    if (this.martyConnector._ricConnector) {
      // TODO 2022
      if (this._martyBlocksIF) {
        this._martyBlocksIF.setIsConnected(
          this.martyConnector._ricConnector.isConnected()
        );
        this._martyBlocksIF.send_REST = this.decodeAndSendMsgToApp.bind(this);
        this._martyBlocksIF.streamAudio = this.martyConnector.streamAudio.bind(
          this.martyConnector
        );
        this._martyBlocksIF.sendFeedbackToServer = this.martyConnector.getFeedback.bind(
          this.martyConnector
        );
      }
    }
  }

  async decodeAndSendMsgToApp(msg: string) {
    if (msg === "disconnect") {
      return ms.setModal(
        createElement(DisconnectConfirmationModal),
        "Are you sure you want to disconnect from your Marty?"
      );
    }
    if (msg === "connect") {
      await connectToMarty();
      return this._martyBlocksIF && this.setApp(this._martyBlocksIF);
    }
    if (msg.startsWith("notification")) {
      return NotificationsEmitter.notify("notification", msg, "", null);
    }

    if (msg === "toggle-sensors-dashboard") {
      if (draggableModalState.modalContent) {
        return draggableModalState.closeModal();
      }
      martyConnector.sessionDbs?.SensorDash.startSession();
      return draggableModalState.setModal(createElement(SensorsDashboardModal), "Sensor Insights Hub (βETA)", "sensors-dashboard", martyConnector.sessionDbs?.SensorDash.endSession);
    }

    if (msg === MartyBlocksDBsSessionsMessagesE.END_ML_SESSION) {
      await martyConnector.sessionDbs.MachineLearning.endSession();
    }
    if (msg === MartyBlocksDBsSessionsMessagesE.END_CODE_ASSES_SESSION) {
      await martyConnector.sessionDbs.CodeAsses.endSession();
    }
    if (msg === MartyBlocksDBsSessionsMessagesE.END_PYTHON_TRANSLATOR_SESSION) {
      await martyConnector.sessionDbs.PythonTranslator.endSession();
    }
    if (msg === MartyBlocksDBsSessionsMessagesE.SET_CODE_ASSES_SESSION_ACTIVE) {
      await martyConnector.sessionDbs.CodeAsses.setSessionToActive();
    }
    if (msg === MartyBlocksDBsSessionsMessagesE.SET_ML_SESSION_ACTIVE) {
      await martyConnector.sessionDbs.MachineLearning.setSessionToActive();
    }
    if (msg === MartyBlocksDBsSessionsMessagesE.START_AND_SET_PYTHON_TRANSLATOR_SESSION_ACTIVE) {
      await martyConnector.sessionDbs.PythonTranslator.startSession();
      await martyConnector.sessionDbs.PythonTranslator.setSessionToActive();
    }
    if (msg === MartyBlocksDBsSessionsMessagesE.START_AND_SET_ACTIVE_AND_END_ML_SESSION) {
      await martyConnector.sessionDbs.MachineLearning.startSession();
      await martyConnector.sessionDbs.MachineLearning.setSessionToActive();
      await martyConnector.sessionDbs.MachineLearning.endSession();
    }
    if (msg === MartyBlocksDBsSessionsMessagesE.START_CODE_ASSES_SESSION) {
      await martyConnector.sessionDbs.CodeAsses.startSession();
    }
    if (msg === MartyBlocksDBsSessionsMessagesE.START_ML_SESSION) {
      await martyConnector.sessionDbs.MachineLearning.startSession();
    }
    if (msg === MartyBlocksDBsSessionsMessagesE.START_PYTHON_TRANSLATOR_SESSION) {
      await martyConnector.sessionDbs.PythonTranslator.startSession();
    }
    if (msg === MartyBlocksDBsSessionsMessagesE.SET_PYTHON_TRANSLATOR_SESSION_ACTIVE) {
      await martyConnector.sessionDbs.PythonTranslator.setSessionToActive();
    }
    if (msg.includes(MartyBlocksDBsSessionsMessagesE.STORE_USED_BLOCK_MB)) {
      const blockName = msg.split(MartyBlocksDBsSessionsMessagesE.STORE_USED_BLOCK_MB)[1];
      console.log("storing used block", blockName)
      await martyConnector.sessionDbs.MartyBlocks.storeUsedBlock(blockName);
    }

    return this.martyConnector.sendRestMessage(msg);
  }

  setApp(martyBlocksIF: MartyBlocksIF) {
    // TODO 2022
    this._martyBlocksIF = martyBlocksIF;
    this.hookupToRicConnector();

    this._updateTimer = setInterval(() => {
      this._updateSensors();
    }, 100);
  }

  loadWithProject(projectId: string | null) {
    if (this._martyBlocksIF) {
      console.log("setting project to load");
      this._martyBlocksIF.projectToLoadId = projectId;
    }
  }

  _updateSensors() {
    // TODO 2022
    const ricState = this.martyConnector._ricConnector.getRICStateInfo();
    if (this._martyBlocksIF) {
      this._martyBlocksIF.mp3EncodingBitRate = this.martyConnector.soundStreamingStats.getCurrentBitRate;
      this._martyBlocksIF.mp3EncodingSampleRate = this.martyConnector.soundStreamingStats.getCurrentSampleRate;
      this._martyBlocksIF.mp3EncodingAvgFlag = this.martyConnector.soundStreamingStats.getCurrentAvgFlag;
      this._martyBlocksIF.systemInfo =
      this.martyConnector.systemInfo || new RICSystemInfo();
      this._martyBlocksIF.accel = JSON.stringify(ricState.imuData);
      this._martyBlocksIF.magneto = JSON.stringify(ricState.magnetoData);
      this._martyBlocksIF.addons = JSON.stringify(ricState.addOnInfo);
      this._martyBlocksIF.isStreamStarting = this.martyConnector.isStreamStarting();
      this._martyBlocksIF.setIsConnected(
        this.martyConnector._ricConnector.isConnected()
      );
      this._martyBlocksIF.setBattRemainCapacityPercent(
        ricState.power.powerStatus.battRemainCapacityPercent
      );

      this._martyBlocksIF.power = JSON.stringify(ricState.power);
      this._martyBlocksIF.servos = JSON.stringify(ricState.smartServos);

      // TODO 2022 - can rssi be got from WebBLE connection?
      this._martyBlocksIF.setRSSI(this.martyConnector.getRSSI());
    }
  }
}

export default MartyBlocksConnector;
