import { Marty2 as RCSequencer } from "@robotical/rc-sequencer/dist/rn-bridge/mv2-rn";
import DisconnectConfirmationModal from "../components/modals/DisconnectConfirmation";
import ms from "../state-observables/modal/ModalState";
import AppConnector from "./AppConnector";
import martyConnector, { MartyConnector } from "./MartyConnector";
import { createElement } from "react";

/* 
    Nikos: I had to make public some properties of the MartyConnector class
    because I don't know how to extend from a singleton class.
    Extending from that class would allow me access to its private/protected properites.
*/

type RCSequencerMessageDataType = {
  type: "trajectory" | "disconnect" | "generic-command";
  payload: { name: string; options: { [key: string]: string | number } };
};

class RCSequencerConnector extends AppConnector {
  protected martyConnector: MartyConnector = martyConnector;
  // RC Sequencer window
  protected _rcsequencer: RCSequencer | null = null;

  // Sensor readings update timer
  protected _updateTimer: ReturnType<typeof setInterval> | null = null;

  constructor() {
    super();
  }

  async clearUpdater() {
    // busy wait to make sure we clearing
    // the _updateHooks interval after
    // we've updated the last state of marty
    // (marty should have been disconnected by that time)
    await new Promise((r) => setTimeout(r, 200));
    if (this._updateTimer) {
      clearInterval(this._updateTimer);
      this._updateTimer = null;
    }
    this.unhookFromRicConnector();
  }

  async decodeAndSendMsgToApp(msg: string) {
    const dataParsed: RCSequencerMessageDataType = JSON.parse(msg);
    if (dataParsed.type === "trajectory") {
      const trajName = dataParsed.payload.name;
      const options = dataParsed.payload.options;
      return this.martyConnector.sendRestMessage("traj/" + trajName, options);
    }

    if (dataParsed.type === "generic-command") {
      const command = dataParsed.payload.name;
      return this.martyConnector.sendRestMessage(command, {});
    }

    if (dataParsed.type === "disconnect") {
      return ms.setModal(createElement(DisconnectConfirmationModal), "Are you sure you want to disconnect from your Marty?");
    }
  }

  protected hookupToRicConnector() {
    if (this.martyConnector._ricConnector) {
      if (this._rcsequencer) {
        this._rcsequencer.send_REST = this.decodeAndSendMsgToApp.bind(this);
        this._rcsequencer.setIsConnected(
          this.martyConnector._ricConnector.isConnected()
        );
      }
    }
  }

  unhookFromRicConnector() {
      // resetting properties so next time we 
      // fire an event it'll pass through the first
      // check which checks if new property is same 
      // as old property
    if (this._rcsequencer) {
        this._rcsequencer.removeEventListeners();
        this._rcsequencer.battRemainCapacityPercent = 0;
        this._rcsequencer.rssi = -200;
        this._rcsequencer.martyName = "";
        this._rcsequencer.isConnected = false;
      }
  }

  setApp(RCSeq: RCSequencer) {
    this._rcsequencer = RCSeq;
    this.hookupToRicConnector();
    // TODO 2022 - not sure where this should go
    this._updateTimer = setInterval(() => {
      this._updateSensors();
    }, 300);
  }

 _updateSensors() {
    // TODO 2022
    const ricState = this.martyConnector._ricConnector.getRICStateInfo();    
    if (this._rcsequencer) {
      this._rcsequencer.setBattRemainCapacityPercent(
        ricState.power.powerStatus.battRemainCapacityPercent
      );
      this._rcsequencer.setMartyName(this.martyConnector.RICFriendlyName);
      this._rcsequencer.setMartyVersion(this.martyConnector.martyVersion);
      this._rcsequencer.setAddons(JSON.stringify(ricState.addOnInfo));

      // TODO 2022 - can rssi be got from WebBLE connection?
      this._rcsequencer.setRSSI(this.martyConnector.getRSSI());
    }
  }
}

export default RCSequencerConnector;
