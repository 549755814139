import React from 'react';

import {
  Container,
  InnerContainer,
  Name,
  WifiItemStyle,
  Details,
} from './styles';

interface WifiItemProps {
  name: string;
  freq: number;
  bssid: string;
  onPress?: () => void;
  isSelected: boolean;
}

const freqToString = (freq: number): string => {
  if (freq < 3000) {
    return '2.4GHz';
  }
  return '5GHz';
};

const WifiItem = ({
  name,
  freq,
  bssid,
  isSelected,
  onPress,
}: WifiItemProps) => (
    <Container $isSelected={isSelected} onClick={onPress}>
      <InnerContainer>
        <Name>{name}</Name>
        <Details>
          {freqToString(freq)} BSSID {bssid}
        </Details>
      </InnerContainer>
    </Container>
);

export default WifiItem;
