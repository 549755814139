import { AddOnElemAndConfig } from "@robotical/ricjs";
import { useEffect, useState } from "react";
import AddOnItem from "../../components/AddonItem";
import LoadingSpinner from "../../components/LoadingSpinner";
import Logo from "../../components/Logo";
import martyConnector from "../../MartyConnector/MartyConnector";
import { getHWElemTypeStr } from "../../utils/RICAddonsMap";
import "./styles.css";
import { AppDatabase, DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer/dist";
import { appConfig } from "../../dbConfigs/configs";

export default function Addons() {
  const [isLoading, setIsLoading] = useState(true);
  const [addOnOpened, setAddOnOpened] = useState("");
  const [addonDeleted, setAddonDeleted] = useState(false);

  const [addOnsFound, setAddOnsFound] = useState(
    new Array<AddOnElemAndConfig>()
  );

  useEffect(() => {
    // Analytics
    const dbManager = DatabaseManager.getInstance();
    dbManager.initializeOrGetDatabase(DatabaseEnum.APP, appConfig, DatabaseEnum.APP)
      .then((db) => {
        (db as AppDatabase).storeVisitedScreen("add-ons");
      }).catch((err) => console.log(err));
  }, []);

  const getAddOnInfo = async () => {
    try {
      //First the app gets the addon/list info
      //Which is previously saved info and not what
      //is currently connected to the robot
      const hwElemList = await martyConnector.getConnectedAddOns();
      console.log(hwElemList);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      console.log("requesting addonlist");
      const addOnList = await martyConnector.getAddOnConfigs();
      console.log(addOnList);

      const addOnElemAndConfigList = new Array<AddOnElemAndConfig>();
      let elemIdx = 0;

      // List all connected add-ons
      for (const hwElemRec of hwElemList) {
        if (hwElemRec.type !== "RSAddOn" || hwElemRec.SN.length === 0) {
          continue;
        }
        let matchFound = false;
        for (const addOnRec of addOnList.addons) {
          if (addOnRec.SN === hwElemRec.SN) {
            // item is on addon list and detected as connected to the robot
            addOnElemAndConfigList.push(
              new AddOnElemAndConfig(addOnRec, hwElemRec, elemIdx++)
            );
            matchFound = true;
            break;
          }
        }
        if (!matchFound) {
          // item is present but has not been configured to addon list
          addOnElemAndConfigList.push(
            new AddOnElemAndConfig(null, hwElemRec, elemIdx++)
          );
        }
      }

      // Also list add-ons from saved data, but not plugged in
      for (const addOnRec of addOnList.addons) {
        let itemPresent = false;

        for (const addOnElemAndConfig of addOnElemAndConfigList) {
          if (addOnElemAndConfig.SN === addOnRec.SN) {
            itemPresent = true;
            break;
          }
        }
        if (!itemPresent) {
          // item is in addonlist, but not physically connected to the robot
          addOnElemAndConfigList.push(
            new AddOnElemAndConfig(addOnRec, null, elemIdx++)
          );
        }
      }

      // Set found addons into local state
      setAddOnsFound(addOnElemAndConfigList);

      console.log("FOUND ADDDONS ARE:");
      console.log(addOnElemAndConfigList);

      // debugger;
    } catch (error) {
      console.log("AddOns - failed to get add-on info " + error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAddOnInfo();
  }, []);

  const onPressAddOn = async (item: AddOnElemAndConfig) => {
    try {
      console.log(`User pressed add-on ${item.SN}`);
      await martyConnector.identifyAddOn(item.name);
      if (addOnOpened === item.id) {
        setAddOnOpened("");
      } else {
        setAddOnOpened(item.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onPressSetName = async (
    serialNo: string | undefined,
    newName: string
  ) => {
    console.log(`Pressed set name serialNo ${serialNo} name ${newName}`);
    if (serialNo !== undefined) {
      await martyConnector.setAddOnConfig(serialNo, newName);
      setAddOnOpened("");
      getAddOnInfo();
    }
  };

  const onPressDelete = async (item: AddOnElemAndConfig) => {
    console.log(
      `Pressed delete serialNo ${item.SN} item ${JSON.stringify(item)}`
    );
    if (item.SN !== undefined) {
      await martyConnector.deleteAddOn(item.SN);
      setAddonDeleted(true);
      setAddOnOpened("");
      getAddOnInfo();
    }
  };

  const addonsListJSX = addOnsFound.map((addon) => {
    return (
      <AddOnItem
        key={addon.id}
        name={addon.name}
        whoami={addon.hwElemRec?.whoAmI}
        isConnected={addon.isConnected}
        isConfigured={addon.isConfigured}
        onPress={() => onPressAddOn(addon)}
        isOpen={addOnOpened === addon.id}
        onPressSetName={(serialNo, newName) =>
          onPressSetName(serialNo, newName)
        }
        onPressDelete={() => onPressDelete(addon)}
        addOnType={getHWElemTypeStr(addon.hwElemRec?.whoAmI)}
        versionStr={addon.hwElemRec?.versionStr}
        serialNo={addon.SN}
      />
    );
  });

  return (
    <div className="addons-screen-container">
      <div className="addons-screen-logo-container">
        <Logo />
      </div>
      <h2 className="addons-screen-title">MANAGE ADD-ONS</h2>
      <p className="addons-screen-paragraph">
        Plug in any add-ons you want to configure
      </p>
      <p className="addons-screen-notice">
        {addonDeleted ? "Please restart Marty for deletion to take effect" : ""}
      </p>
      <div
        className="addons-screen-addons-list-container"
        style={{ width: isLoading ? "initial" : "100%" }}
      >
        {isLoading ? <LoadingSpinner /> : addonsListJSX}
      </div>

    </div>
  );
}
