import styled from "styled-components";

import {
  BLACK,
  BLACK_025,
  SAND_YELLOW_25,
  HINT_GREY,
  RED,
} from "../../styles/colors";

export interface WifiItemStyle {
  $isSelected: boolean;
}

export const WifiContainer = styled.div`
  flex: 1;
  padding: 8px 8px 0px 8px;
`;

export const Hint = styled.p`
  font-size: 15px;
  font-family: "Lato Regular";
  color: ${HINT_GREY};
  margin-bottom: 15px;
  text-align: center;
`;

export const ConnectedNetwork = styled.p`
  font-weight: bold;
  color: #000000;
`;

export const Warning = styled.p`
  color: ${RED};
  text-align: center;
  font-weight: bold;
  font-family: "Lato";
`;

export const Container = styled.div<WifiItemStyle>`
  width: 100%;
  padding: .5rem 0;
  background-color: ${({ $isSelected }: WifiItemStyle) =>
    $isSelected ? SAND_YELLOW_25 : "#fff"};
  cursor: pointer;
  border-bottom: solid 1px var(--colour-GREY);
`;

export const InnerContainer = styled.div`
  flex: 1;
  margin-left: 16px;
  justify-content: center;
  border-bottom-width: 1px;
  border-color: ${BLACK_025};
`;

export const Name = styled.p`
  font-size: 16px;
  letter-spacing: -0.4px;
  color: ${BLACK};
`;

export const Details = styled.p`
  font-size: 10px;
  color: ${BLACK};
`;
