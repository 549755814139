import { FirebaseConfig } from "@robotical/analytics-gatherer/dist";

const appConfig: FirebaseConfig = {
    apiKey: "AIzaSyA51XE7fXHzsfLdWUULj6P4wpEdE-HIbY4",
    authDomain: "marty-app-analytics.firebaseapp.com",
    databaseURL: "https://marty-app-analytics-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "marty-app-analytics",
    storageBucket: "marty-app-analytics.appspot.com",
    messagingSenderId: "360434117994",
    appId: "1:360434117994:web:45bb18ac2f6a6e42a44913",
    measurementId: "G-G9BRPK12XZ",
    email: "",
    password: ""
};
const learningSiteConfig: FirebaseConfig = {
    apiKey: "AIzaSyA_LWFZcTyYNaFyxmB4OPc6SmgU8Zzs9M0",
    authDomain: "learning-site-analytics.firebaseapp.com",
    databaseURL: "https://learning-site-analytics-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "learning-site-analytics",
    storageBucket: "learning-site-analytics.appspot.com",
    messagingSenderId: "375076108196",
    appId: "1:375076108196:web:ec82ddb1a987f418623331",
    email: "dev@robotical.io",
    password: "martyRocks"
};
const robotConfig: FirebaseConfig = {
    apiKey: "AIzaSyA5U7tSsrs22ZPxHJGmYAJaSHPe0XtraBY",
    authDomain: "marty-bt-robot-analytics.firebaseapp.com",
    databaseURL: "https://marty-bt-robot-analytics-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "marty-bt-robot-analytics",
    storageBucket: "marty-bt-robot-analytics.appspot.com",
    messagingSenderId: "241250679762",
    appId: "1:241250679762:web:354e3e8ec282695c7aa60b",
    measurementId: "G-4GQ5Y27P36",
    email: "",
    password: ""
};
const processedDataConfig: FirebaseConfig = {
    apiKey: "AIzaSyDz2rDCiSqT_BCgkVbmUJZi-QpTeq1w47g",
    authDomain: "analytics-processed.firebaseapp.com",
    databaseURL: "https://analytics-processed-default-rtdb.firebaseio.com",
    projectId: "analytics-processed",
    storageBucket: "analytics-processed.appspot.com",
    messagingSenderId: "775956929723",
    appId: "1:775956929723:web:52d12614f4849b5e495d9e",
    email: "dev@robotical.io",
    password: "martyRocks"
};

const codeAssesSessiosnFirebaseConfig: FirebaseConfig = {
    apiKey: "AIzaSyALCxDlDSA7vaHujn2HeQfFoGAQyzGYiIY",
    authDomain: "codeassessessions.firebaseapp.com",
    databaseURL: "https://codeassessessions-default-rtdb.firebaseio.com",
    projectId: "codeassessessions",
    storageBucket: "codeassessessions.appspot.com",
    messagingSenderId: "1094543651642",
    appId: "1:1094543651642:web:55c9b0dd35dcc8a7cf1603",
    measurementId: "G-71SDFJFTTS",
    email: "dev@robotical.io",
    password: "martyRocks",
}
const machineLearningSessiosnFirebaseConfig: FirebaseConfig = {
    apiKey: "AIzaSyCp3vswhF8KDBAjBTBN7hyKgfBsrV0bnxE",
    authDomain: "machinelearningsessions.firebaseapp.com",
    databaseURL: "https://machinelearningsessions-default-rtdb.firebaseio.com",
    projectId: "machinelearningsessions",
    storageBucket: "machinelearningsessions.appspot.com",
    messagingSenderId: "542079462368",
    appId: "1:542079462368:web:6f0fbfde5dadc076031985",
    email: "dev@robotical.io",
    password: "martyRocks",
}
const martyBlocksSessiosnFirebaseConfig: FirebaseConfig = {
    apiKey: "AIzaSyDHtLCPKR3GyH93sYcKwyX47byUfZjjyEQ",
    authDomain: "martyblockssessions.firebaseapp.com",
    databaseURL: "https://martyblockssessions-default-rtdb.firebaseio.com",
    projectId: "martyblockssessions",
    storageBucket: "martyblockssessions.appspot.com",
    messagingSenderId: "954869444910",
    appId: "1:954869444910:web:9c1b4fe74ab7429e294abf",
    measurementId: "G-J9JHTM8HSP",
    email: "dev@robotical.io",
    password: "martyRocks",
}
const pythonTranslatorSessiosnFirebaseConfig: FirebaseConfig = {
    apiKey: "AIzaSyDxFG0mxzOEs4KSegarCXRTU3oNmUzOdJg",
    authDomain: "pythontranslatorsessions.firebaseapp.com",
    databaseURL: "https://pythontranslatorsessions-default-rtdb.firebaseio.com",
    projectId: "pythontranslatorsessions",
    storageBucket: "pythontranslatorsessions.appspot.com",
    messagingSenderId: "298111275468",
    appId: "1:298111275468:web:014235ac3b0ce5101c4d69",
    measurementId: "G-X0HVY8HYFC",
    email: "dev@robotical.io",
    password: "martyRocks",
}
const sensorsDashboardSessiosnFirebaseConfig: FirebaseConfig = {
    apiKey: "AIzaSyAEOlJmgyrljV2NcqXAexVSAqZEMcY4YRo",
    authDomain: "sensordashboardsessions.firebaseapp.com",
    databaseURL: "https://sensordashboardsessions-default-rtdb.firebaseio.com",
    projectId: "sensordashboardsessions",
    storageBucket: "sensordashboardsessions.appspot.com",
    messagingSenderId: "765530410682",
    appId: "1:765530410682:web:4198cebb8b327024a13c83",
    measurementId: "G-T51GYGEBHL",
    email: "dev@robotical.io",
    password: "martyRocks",
}
const noConsentDbConfig: FirebaseConfig = {
    apiKey: "AIzaSyAyvd-98Ht6fXs5pAIRbtPnDGj3t87fwk0",
    authDomain: "no-consent-analytics.firebaseapp.com",
    databaseURL: "https://no-consent-analytics-default-rtdb.firebaseio.com",
    projectId: "no-consent-analytics",
    storageBucket: "no-consent-analytics.appspot.com",
    messagingSenderId: "730739942780",
    appId: "1:730739942780:web:3eee1121b0348d5682b0f9",
    email: "dev@robotical.io",
    password: "martyRocks",
}



export {
    appConfig,
    learningSiteConfig,
    robotConfig,
    processedDataConfig,
    codeAssesSessiosnFirebaseConfig,
    machineLearningSessiosnFirebaseConfig,
    martyBlocksSessiosnFirebaseConfig,
    pythonTranslatorSessiosnFirebaseConfig,
    sensorsDashboardSessiosnFirebaseConfig,
    noConsentDbConfig
};