import CodeAssesSessions from "./CodeAssesSessions";
import MLSessions from "./MachineLearningSessions";
import MartyBlocksSessions from "./MartyBlocksSessions";
import PythonTranslatorSessions from "./PythonTranslatorSessions";
import SensorDashboardSessions from "./SensorsDashboardSessions";

export default class SessionsDBManager {

    public CodeAsses: typeof CodeAssesSessions;
    public MartyBlocks: typeof MartyBlocksSessions;
    public PythonTranslator: typeof PythonTranslatorSessions;
    public SensorDash: typeof SensorDashboardSessions;
    public MachineLearning: typeof MLSessions;

    constructor() {
        this.CodeAsses = CodeAssesSessions;
        this.MartyBlocks = MartyBlocksSessions;
        this.PythonTranslator = PythonTranslatorSessions;
        this.SensorDash = SensorDashboardSessions;
        this.MachineLearning = MLSessions;
    }

}