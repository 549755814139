import React from "react";
import styles from "./styles.module.css";

const ServiceProgramInfo: React.FC = () => {
  return (
    <div className={styles.infoContainer}>
      <h2 className={styles.title}>Activate Marty's Warranty</h2>
      <p className={styles.paragraph}>
        Your Marty comes with a free 2 years warranty - please activate it by
        linking your robot to an e-mail address.
      </p>
      {/* <p className={styles.paragraph}>
        🔧 <b>Why activate the warranty?</b>
      </p>
      <p className={styles.paragraph}>
        🌐 If your Marty develops a fault, an error log can be sent to Robotical
        so we can fix the issue immediately or get you a replacement.
      </p>
      <p className={styles.paragraph}>
        🔗 If you report an error through the app, we'll reach out through this
        email address. Please use the best e-mail address to discuss technical
        support for your Martys. This address will only be used for
        support-related communication.
      </p> */}
    </div>
  );
};

export default ServiceProgramInfo;
