import randomHashGenerator from "../../helpers/randomHashGenerator";

const DEVICE_ID_KEY = "@device_id";

export function getStoredDeviceId() {
  return localStorage.getItem(DEVICE_ID_KEY);
}

export function setStoredDeviceId(deviceId: string) {
  return localStorage.setItem(DEVICE_ID_KEY, deviceId);
}
export async function getDeviceId(): Promise<string> {
  try {
    const fetchedDeviceId = await getStoredDeviceId();
    if (!fetchedDeviceId) {
      // ID doesn't exist - store it
      const randomDeviceId = randomHashGenerator(10);
      setStoredDeviceId(randomDeviceId);
      return randomDeviceId;
    } else {
      // ID does exist
      return fetchedDeviceId;
    }
  } catch (error) {
    console.log(error, "Error in AppSession.ts, line 79");
    // In case of an error, generate a new device ID and return it
    const randomDeviceId = randomHashGenerator(10);
    return randomDeviceId;
  }
}
