import { useState } from "react";
import ConfigurationScreen from "../screens/Configuration";
import WifiConfStep1Screen from "../screens/WifiConfiguration/WifiConfigurationStep1";
import Layout from "../layout/Layout";
import Diagnostics from "../screens/Diagnostics";
import NavLayout from "../layout/NavLayout";
import Addons from "../screens/Addons";
import CalibrationNavigator from "./CalibrationNavigator";
import WifiConfStep1aScreen from "../screens/WifiConfiguration/WifiConfigurationStep1a";
import WifiConfStep2Screen from "../screens/WifiConfiguration/WifiConfigurationStep2";
import WifiConfStepFailedToConnect from "../screens/WifiConfiguration/WifiConfigurationStepFailedToConnect";
import WifiConfStepConnected from "../screens/WifiConfiguration/WifiConfigurationStepConnected";

type ScreenType =
  | "configuration"
  | "wifi-configuration-step-1"
  | "wifi-configuration-step-1a"
  | "wifi-configuration-step-2"
  | "wifi-configuration-step-failed-to-connect"
  | "wifi-configuration-step-connected"
  | "diagnostics"
  | "addons"
  | "calibration";

export interface ConfigurationComponentType {
  currentScreenSetter: (screen: ScreenType, params?: any) => void;
}

export default function ConfigurationNavigator() {
  const [currentScreen, setCurrentScreen] = useState<{
    screen: ScreenType;
    params: any;
  }>({ screen: "configuration", params: {} });

  const currentScreenSetter = (screen: ScreenType, params?: any) => {
    setCurrentScreen({ screen, params });
  };

  let currentScreenJSX;
  switch (currentScreen.screen) {
    case "wifi-configuration-step-1":
      currentScreenJSX = (
        <NavLayout
          onClickBack={() =>
            setCurrentScreen({ screen: "configuration", params: {} })
          }
        >
          <WifiConfStep1Screen currentScreenSetter={currentScreenSetter} />
        </NavLayout>
      );
      break;

    case "wifi-configuration-step-1a":
      currentScreenJSX = (
        <NavLayout
          onClickBack={() =>
            setCurrentScreen({
              screen: "wifi-configuration-step-1",
              params: {},
            })
          }
        >
          <WifiConfStep1aScreen currentScreenSetter={currentScreenSetter} />
        </NavLayout>
      );
      break;

    case "wifi-configuration-step-2":
      currentScreenJSX = (
        <NavLayout
          onClickBack={() =>
            setCurrentScreen({
              screen: "wifi-configuration-step-1",
              params: {},
            })
          }
        >
          <WifiConfStep2Screen
            ssid={currentScreen.params.ssid}
            currentScreenSetter={currentScreenSetter}
          />
        </NavLayout>
      );
      break;

    case "wifi-configuration-step-failed-to-connect":
      currentScreenJSX = (
        <NavLayout
          onClickBack={() =>
            setCurrentScreen({
              screen: "wifi-configuration-step-1",
              params: {},
            })
          }
        >
          <WifiConfStepFailedToConnect
            ssid={currentScreen.params.ssid}
            currentScreenSetter={currentScreenSetter}
          />
        </NavLayout>
      );
      break;

    case "wifi-configuration-step-connected":
      currentScreenJSX = (
        <NavLayout
          onClickBack={() =>
            setCurrentScreen({
              screen: "configuration",
              params: {},
            })
          }
        >
          <WifiConfStepConnected
            ssid={currentScreen.params.ssid}
            currentScreenSetter={currentScreenSetter}
          />
        </NavLayout>
      );
      break;

    case "diagnostics":
      currentScreenJSX = (
        <NavLayout
          onClickBack={() =>
            setCurrentScreen({ screen: "configuration", params: {} })
          }
        >
          <Diagnostics />
        </NavLayout>
      );
      break;

    case "addons":
      currentScreenJSX = (
        <NavLayout
          onClickBack={() =>
            setCurrentScreen({ screen: "configuration", params: {} })
          }
        >
          <Addons />
        </NavLayout>
      );
      break;

    case "calibration":
      currentScreenJSX = (
        <NavLayout
          onClickBack={() =>
            setCurrentScreen({ screen: "configuration", params: {} })
          }
        >
          <CalibrationNavigator />
        </NavLayout>
      );
      break;

    default:
      currentScreenJSX = (
        <ConfigurationScreen currentScreenSetter={currentScreenSetter} key={new Date().toISOString()}/>
      );
      break;
  }

  return <Layout>{currentScreenJSX}</Layout>;
}
