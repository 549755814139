import { Marty2 as SensorsDashboard } from "@robotical/sensors-dashboard/dist/app-bridge/mv2-rn";
import DisconnectConfirmationModal from "../components/modals/DisconnectConfirmation";
import ms from "../state-observables/modal/ModalState";
import connectToMarty from "../utils/network-api/connect-to-marty-function";
import AppConnector from "./AppConnector";
import martyConnector, { MartyConnector } from "./MartyConnector";
import { createElement } from "react";

/* 
    Nikos: I had to make public some properties of the MartyConnector class
    because I don't know how to extend from a singleton class.
    Extending from that class would allow me access to its private/protected properites.
*/

type SensorsDashboardMessageDataType = {
  type: "trajectory" | "disconnect" | "generic-command" | "connect";
  payload: { name: string; options: { [key: string]: string | number } };
};

class SensorsDashboardConnector extends AppConnector {
  protected martyConnector: MartyConnector = martyConnector;
  // RC Sequencer window
  protected _sensorsDashboard: SensorsDashboard | null = null;

  // Sensor readings update timer
  protected _updateTimer: ReturnType<typeof setInterval> | null = null;

  private _isModal: boolean = false;

  constructor(isModal: boolean = false) {
    super();
    this._isModal = isModal;
  }

  async clearUpdater() {
    // busy wait to make sure we clearing
    // the _updateHooks interval after
    // we've updated the last state of marty
    // (marty should have been disconnected by that time)
    await new Promise((r) => setTimeout(r, 200));
    if (this._updateTimer) {
      clearInterval(this._updateTimer);
      this._updateTimer = null;
    }
    this.unhookFromRicConnector();
  }

  async decodeAndSendMsgToApp(msg: string) {
    const dataParsed: SensorsDashboardMessageDataType = JSON.parse(msg);
    if (dataParsed.type === "trajectory") {
      const trajName = dataParsed.payload.name;
      const options = dataParsed.payload.options;
      return this.martyConnector.sendRestMessage("traj/" + trajName, options);
    }

    if (dataParsed.type === "generic-command") {
      const command = dataParsed.payload.name;
      return this.martyConnector.sendRestMessage(command, {});
    }

    if (dataParsed.type === "disconnect") {
      return ms.setModal(createElement(DisconnectConfirmationModal), "Are you sure you want to disconnect from your Marty?");
    }
    if (dataParsed.type === "connect") {
      await connectToMarty();
      return this._sensorsDashboard && this.setApp(this._sensorsDashboard);
    }
  }

  protected hookupToRicConnector() {
    if (this.martyConnector._ricConnector) {
      if (this._sensorsDashboard) {
        this._sensorsDashboard.send_REST = this.decodeAndSendMsgToApp.bind(this);
        // this._sensorsDashboard.setIsConnected(
        //   this.martyConnector._ricConnector.isConnected()
        // );
      }
    }
  }

  unhookFromRicConnector() {
      // resetting properties so next time we 
      // fire an event it'll pass through the first
      // check which checks if new property is same 
      // as old property
    if (this._sensorsDashboard) {
      this._sensorsDashboard.battRemainCapacityPercent = 0;
      this._sensorsDashboard.rssi = -200;
      this._sensorsDashboard.martyName = "";
      this._sensorsDashboard.setIsConnected(false);
      this._sensorsDashboard.setIsConnecting(false);
      }
  }

  setApp(sensDash: SensorsDashboard) {
    if (this._sensorsDashboard) return; // already set
    this._sensorsDashboard = sensDash;
    this.hookupToRicConnector();
    this._updateTimer = setInterval(() => {
      this._updateSensors();
    }, 300);
  }

  _updateSensors() {
    const ricState = this.martyConnector._ricConnector.getRICStateInfo();    
    if (this._sensorsDashboard) {
      this._sensorsDashboard.setBattRemainCapacityPercent(
        ricState.power.powerStatus.battRemainCapacityPercent
      );
      this._sensorsDashboard.setIsModal(this._isModal);
      this._sensorsDashboard.setMartyName(this.martyConnector.RICFriendlyName);
      this._sensorsDashboard.setAddons(JSON.stringify(ricState.addOnInfo));
      this._sensorsDashboard.setAccel(JSON.stringify(ricState.imuData));
      this._sensorsDashboard.setMagneto(JSON.stringify(ricState.magnetoData));
      this._sensorsDashboard.setServos(JSON.stringify(ricState.smartServos));
      this._sensorsDashboard.setIsConnected(
        this.martyConnector._ricConnector.isConnected()
      );
      this._sensorsDashboard.setIsConnecting(
        this.martyConnector.isConnecting
      )
      this._sensorsDashboard.setRSSI(this.martyConnector.getRSSI());
    }
  }
}

export default SensorsDashboardConnector;
