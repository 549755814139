import { useContext, useEffect, useState } from "react";
import "./styles.css";
import { ReactComponent as DownloadSVG } from "../../assets/download-button.svg";
import { MAIN_BLUE } from "../../styles/colors";
import DefferedPromptContext from "../../store/deffered-prompt-installation";

export default function InstallButton() {

  const defferedPromptCtx = useContext(DefferedPromptContext);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", beforeInstallHandler);
    return () => window.removeEventListener("beforeinstallprompt", beforeInstallHandler);
  }, []);

  const beforeInstallHandler = (e: Event) => {
    defferedPromptCtx.setDeferredPrompt(e);
  };


  const installHandler = async () => {
    if (defferedPromptCtx.deferredPrompt) {
      // @ts-ignore;
      defferedPromptCtx.deferredPrompt.prompt();
      // @ts-ignore;
      const { outcome } = await defferedPromptCtx.deferredPrompt;
      if (outcome === "accepted") {
        defferedPromptCtx.setDeferredPrompt(null);
      }
    }
  };

  return (
    <>
      {window.location.pathname === "/" && defferedPromptCtx.deferredPrompt && (
        <div className="install-app-btn-container" onClick={installHandler}>
          <DownloadSVG fill={MAIN_BLUE}/>
        </div>
      )}
    </>
  );
}
