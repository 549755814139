import { useState, FormEvent, useEffect } from 'react';
import styles from './styles.module.css';
import ms from '../../../state-observables/modal/ModalState';
import martyConnector from '../../../MartyConnector/MartyConnector';
import { createTicket } from '../../../ServiceProgram/onboarding';
import { toast } from 'react-toastify';

type Props = {
    martySerialNumber: string;
}

const ServiceProgramRobotIssueReportModal = ({ martySerialNumber }: Props) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [martyHwStatus, setMartyHwStatus] = useState('');
    const [isHwStatusReady, setIsHwStatusReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // grab details from Marty
        martyConnector.sendRestMessage("hwstatus").then((hwStatusResponse) => {
            // @ts-ignore
            if (hwStatusResponse.rslt === "ok" && hwStatusResponse.hw) {
                // @ts-ignore
                setMartyHwStatus(JSON.stringify(hwStatusResponse.hw));
                setIsHwStatusReady(true);
            } else {
                console.log("Error getting hwstatus");
            }
        });
        // if hwstatus is not ready in 10 seconds, give up
        const timeout = setTimeout(() => {
            if (!isHwStatusReady) {
                console.log("Error getting hwstatus");
                setIsHwStatusReady(true);
            }
        }, 10000);
        return () => {
            clearTimeout(timeout);
            setMartyHwStatus('');
            setIsHwStatusReady(false);
        }
    }, []);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        const enhancedDescription = `${description}\n\HwStatus: ${martyHwStatus}`;
        createTicket(martySerialNumber, title, enhancedDescription).then((res) => {
            if (res) {
                ms.closeModal();
                toast.success(
                    <>{"Your ticket has been created successfully. You can check the status of your ticket on "}
                        <a href="https://service-program-dashboard.robotical.io/" target="_blank" rel="noopener noreferrer">
                            your dashboard
                        </a></>,
                    { autoClose: 5000 }
                );
            } else {
                toast.error("There was an error creating your ticket. Please try again later.");
            }
        }).catch((err) => {
            toast.error("There was an error creating your ticket. Please try again later.");
            console.log(err)
        }).finally(() => {
            setTitle('');
            setDescription('');
            setIsLoading(false);
        });
    };

    const handleCancel = () => {
        setTitle('');
        setDescription('');
        ms.closeModal();
    };

    return (
        <div className={styles.formContainer}>
            <form onSubmit={handleSubmit}>
                <h6 className={styles.inputTitle}>
                    Experiencing trouble with your Marty? Let's get it fixed as soon as possible!
                </h6>
                <p className={styles.inputDescription}>
                    Please provide us with a brief title and description of the issue you're facing. The more details you can provide, the better equipped we will be to help. Don't worry, our expert team is here to support you and your robot every step of the way.
                </p>
                <label className={styles.formLabel}>
                    Issue Title:
                    <input
                        type="text"
                        className={styles.formInput}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </label>
                <label className={styles.formLabel}>
                    Short Description:
                    <textarea
                        className={styles.formTextarea}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                </label>
                <div className={styles.buttonContainer}>
                    <button
                        type="submit"
                        className={[styles.formSubmitButton, !isHwStatusReady || isLoading ? styles.formSubmitButtonDisabled : ''].join(' ')}
                        disabled={!isHwStatusReady || isLoading}
                    >
                        Submit
                    </button>
                    <button type="button" className={styles.formCancelButton} onClick={handleCancel}>
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ServiceProgramRobotIssueReportModal;
