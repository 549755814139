import { FaChalkboardTeacher, FaUserGraduate } from "react-icons/fa";
import styles from "./styles.module.css";
import { DatabaseEnum, DatabaseManager } from "@robotical/analytics-gatherer";
import { noConsentDbConfig } from "../../dbConfigs/configs";
import NoConsentAnalyticsDatabase, { UserRoleE } from "@robotical/analytics-gatherer/dist/database/NoConsentDatabase";

type Props = {
    onUserRoleSelection: (role: "teacher" | "student") => void;
    isForWarrantyOnboarding?: boolean;
}

export default function UserRole({ onUserRoleSelection, isForWarrantyOnboarding }: Props) {

    const handleUserRoleSelection = (role: "teacher" | "student") => {
        // track user role click event
        const dbManager = DatabaseManager.getInstance();
        dbManager.initializeOrGetDatabase(DatabaseEnum.NO_CONSENT_ANALYTICS, noConsentDbConfig, DatabaseEnum.NO_CONSENT_ANALYTICS)
            .then(database => {
                const databaseTyped = database as NoConsentAnalyticsDatabase;
                databaseTyped.userRoleClicked(role as UserRoleE);
            })
            .catch(err => console.warn("err", err));
        ////
        onUserRoleSelection(role);
    };

    return (
        <div className={styles.userSelectorContainer}>
            <h3>Who are you?</h3>
            <div className={styles.userSelector}>
                <button
                    className={styles.userSelectorButton}
                    onClick={() => handleUserRoleSelection("teacher")}
                >
                    <FaChalkboardTeacher />
                    <h4>I am a teacher</h4>
                    <p>{isForWarrantyOnboarding ? "Register your Marty and manage your robots." : ""}</p>
                </button>
                <button
                    className={styles.userSelectorButton}
                    onClick={() => handleUserRoleSelection("student")}
                >
                    <FaUserGraduate />
                    <h4>I am a student</h4>
                    <p>{isForWarrantyOnboarding ? "Play, Program and Learn!" : ""}</p>
                </button>
            </div>
        </div>)
}
