import { FunctionComponent, SVGProps, useEffect, useRef } from "react";
import "./styles.css";

interface InfoFieldProps {
  title?: string;
  subtitle?: string | null;
  IconSVG?: FunctionComponent<SVGProps<SVGSVGElement>>;
  buttonTextColour?: string;
  IconFill?: string;
  onClick?: () => void;
  buttonText: string;
}

const InfoField = ({
  title,
  subtitle,
  IconSVG,
  buttonTextColour,
  IconFill,
  buttonText,
  onClick,
}: InfoFieldProps) => {
  const svgRef = useRef<SVGSVGElement>(null);
  useEffect(() => {
    if (svgRef.current && IconFill) {
      svgRef.current.setAttribute("fill", IconFill);
    }
  }, []);
  return (
    <div className="info-field-container">
      <div className="info-field-icon-container">
        {IconSVG && <IconSVG ref={svgRef} />}
      </div>
      <div className="info-field-rightside-container">
        <p className="info-field-title">{title}</p>
        <p className="info-field-subtitle">{subtitle}</p>
        <p
          className="info-field-button-text"
          onClick={onClick}
          style={{ color: buttonTextColour ? buttonTextColour : "initial" }}
        >
          {buttonText}
        </p>
      </div>
    </div>
  );
};

export default InfoField;
