import Router from "./router";
import "./styles/typography.css";
import { useEffect, useState } from "react";
import Modal from "./components/Modal";
import modalState, { secondaryModalState } from "./state-observables/modal/ModalState";
import draggableModalState from "./state-observables/modal/DraggableModalState";
import {
  ModalEventTopics,
  ModalStateData,
} from "./state-observables/modal/ModalObserver";

import analyticsConsentChecking from "./utils/analytics/analytics-consent-checking";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import DraggableResizableModal from "./components/DraggableModal";
import { BrowserRouter } from "react-router-dom";

export default function App() {
  const [modalData, setModalData] = useState<null | ModalStateData>(null);
  const [draggableModalData, setDraggableModalData] = useState<null | ModalStateData>(null);
  const [secondaryModalData, setSecondaryModalData] = useState<null | ModalStateData>(null);
  const [shouldCloseModal, setShouldCloseModal] = useState(false);
  const [shouldCloseSecondaryModal, setShouldCloseSecondaryModal] = useState(false);
  const [shouldCloseDraggableModal, setShouldCloseDraggableModal] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  useEffect(() => {
    // check if the app is running on an iOS mobile device
    // and if so, send an error message saying this is not supported
    if (
      /iPad|iPhone|iPod/.test(navigator.userAgent) &&
      //@ts-ignore
      !window.MSStream &&
      //@ts-ignore
      !window.navigator.standalone
    ) {
      toast.error(
        "This web application is not supported on iOS devices. Please download Marty the Robot V2 from the Apple store instead.",
        {
          position: "top-center",
          autoClose: false
        }
      );
    }
  }, []);

  const alertUser = (e: Event) => {
    e.preventDefault();
    return "Are you sure you want to exit?";
  };

  const modalSubscriptionHelper = {
    notify(eventTopic: ModalEventTopics, eventData: ModalStateData) {
      switch (eventTopic) {
        case "SetModal":
          setModalData(eventData);
          break;
        case "CloseModal":
          setShouldCloseModal(true);
          break;
        case "UpdateModalProps":
          setModalData((prevModalData) => {
            if (prevModalData) {
              return {
                ...prevModalData,
                ...eventData,
              };
            }
            return prevModalData;
          });
          break;
      }
    },
  };

  const secondaryModalSubscriptionHelper = {
    notify(eventTopic: ModalEventTopics, eventData: ModalStateData) {
      switch (eventTopic) {
        case "SetModal":
          setSecondaryModalData(eventData);
          break;
        case "CloseModal":
          setShouldCloseSecondaryModal(true);
          break;
        case "UpdateModalProps":
          setSecondaryModalData((prevModalData) => {
            if (prevModalData) {
              return {
                ...prevModalData,
                ...eventData,
              };
            }
            return prevModalData;
          });
          break;
      }
    },
  };

  const draggableModalSubscriptionHelper = {
    notify(eventTopic: ModalEventTopics, eventData: ModalStateData) {
      switch (eventTopic) {
        case "SetModal":
          setDraggableModalData(eventData);
          break;
        case "CloseModal":
          setShouldCloseDraggableModal(true);
          break;
      }
    },
  };

  const resetModal = () => {
    setShouldCloseModal(false);
    setModalData(null);
  };

  const resetSecondaryModal = () => {
    setShouldCloseSecondaryModal(false);
    setSecondaryModalData(null);
  };

  const closeDraggableModal = () => {
    setShouldCloseDraggableModal(false);
    setDraggableModalData(null);
  };

  // Create subscription to the modal states
  useEffect(() => {
    // Subscribe
    modalState.subscribe(modalSubscriptionHelper, ["SetModal", "CloseModal", "UpdateModalProps"]);
    secondaryModalState.subscribe(secondaryModalSubscriptionHelper, ["SetModal", "CloseModal", "UpdateModalProps"]);
    draggableModalState.subscribe(draggableModalSubscriptionHelper, ["SetModal", "CloseModal"]);
    // Return unsubscribe function
    return () => {
      modalState.unsubscribe(modalSubscriptionHelper);
      secondaryModalState.unsubscribe(secondaryModalSubscriptionHelper);
      draggableModalState.unsubscribe(draggableModalSubscriptionHelper);
    };
  }, []);

  // checking if there is an analytics consent cookie decision.
  // if there is not, we present the analytics consent modal
  useEffect(() => {
    analyticsConsentChecking();
  }, []);

  const ModalContent = modalData?.modalContent;
  const SecondaryModalContent = secondaryModalData?.modalContent;
  const DraggableModalContent = draggableModalData?.modalContent;

  return (
    <BrowserRouter>
      <ToastContainer />
      {!!ModalContent && (
        <Modal
          title={modalData ? modalData.modalTitle || "" : ""}
          modalResetter={resetModal}
          shouldCloseModal={shouldCloseModal}
          withLogo
          withCloseButton={modalData?.withCloseButton}
        >
          {ModalContent}
        </Modal>
      )}

      {!!SecondaryModalContent && (
        <Modal
          title={secondaryModalData ? secondaryModalData.modalTitle || "" : ""}
          modalResetter={resetSecondaryModal}
          shouldCloseModal={shouldCloseSecondaryModal}
          withLogo
          containerId="secondary-modal-root"
          withCloseButton={secondaryModalData?.withCloseButton}
        >
          {SecondaryModalContent}
        </Modal>
      )}

      {!!DraggableModalContent && (
        <DraggableResizableModal modalClose={closeDraggableModal} shouldCloseModal={shouldCloseDraggableModal}
          title={draggableModalData.modalTitle || ""} newWindowLink={draggableModalData.newWindowLink}
        >
          {DraggableModalContent}
        </DraggableResizableModal>
      )}
      <Router />
    </BrowserRouter>
  );
}
