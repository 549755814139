import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { FaRegWindowMaximize } from "react-icons/fa";
import { GiRobotHelmet } from "react-icons/gi";
import BugReportModal from "../BugReportModal";
import ServiceProgramRobotIssueReportModal from "../ServiceProgramRobotIssueReportModal";
import martyConnector from "../../../MartyConnector/MartyConnector";
import ms from "../../../state-observables/modal/ModalState";
import { isSerialNumberRegistered } from "../../../ServiceProgram/onboarding";

export default function ReportIssueModal() {
    const [modalContent, setModalContent] = useState<"selector" | "robot-issue" | "app-issue">("selector");
    const [isRobotRegisteredInServiceProgram, setIsRobotRegisteredInServiceProgram] = useState<boolean>(false);

    useEffect(() => {
        if (martyConnector.martySerialNo) {
            isSerialNumberRegistered(martyConnector.martySerialNo).then((isRegistered) => {
              setIsRobotRegisteredInServiceProgram(isRegistered);
            }).catch((err) => console.log(err));
          }
    }, []);

    let contentJSX = (<div className={styles.container}>
        <div className={styles.reportIssueContainer}>
            <h3>What type of issue you'd like to report</h3>
            <div className={styles.reportIssue}>
                <button
                    className={styles.reportIssueButton}
                    onClick={() => setModalContent("app-issue")}
                >
                    <FaRegWindowMaximize />
                    <h4>App Issue</h4>
                    <p>Report an application issue.</p>
                </button>

                <button
                    className={[styles.reportIssueButton, isRobotRegisteredInServiceProgram ? "" : styles.disabled].join(" ")}
                    disabled={!isRobotRegisteredInServiceProgram}
                    onClick={() => setModalContent("robot-issue")}
                ><GiRobotHelmet />
                    <h4>Robot Issue</h4>
                    {isRobotRegisteredInServiceProgram ? <p>Report a robot issue.</p> : <p>Register your robot in the service program to report a robot issue.</p>}
                </button>
            </div>
        </div>
        <button className={styles.button} onClick={() => ms.closeModal()}>
            Cancel
        </button>
    </div>);

    if (modalContent === "app-issue") {
        contentJSX = <BugReportModal />;
    } else if (modalContent === "robot-issue") {
        // for the robot issue, we want to make sure that the user is registered in the service program
        contentJSX = <ServiceProgramRobotIssueReportModal martySerialNumber={martyConnector.martySerialNo} />;
    }
    return contentJSX;

}
