import { AnalyticsConsent } from "@robotical/analytics-gatherer/dist";

const ANALYTICS_CONSENT = "@analytics_consent";

export function getStoredAnalyticsConsent(): AnalyticsConsent | null {
  return localStorage.getItem(ANALYTICS_CONSENT) as AnalyticsConsent;
}

export function setStoredAnalyticsConsent(analyticsConsent: AnalyticsConsent) {
  return localStorage.setItem(ANALYTICS_CONSENT, analyticsConsent);
}
