import AWS from "aws-sdk";
import { isVersionGreater_errorCatching } from "./helpers/compare-version";
import { toast } from "react-toastify";


export default class FirmwareVersionChekcer {
    private static BUCKET = "com.robotical.updates";
    private static PREFIX = "live/martyv2";

    static async getAndCheckVersion(version: string): Promise<boolean | null> {
        // time the load from AWS
        const latestVersion = await FirmwareVersionChekcer.loadAllUpdatesFromAWS();
        if (!latestVersion) return null;
        const isGreater = isVersionGreater_errorCatching(latestVersion, version);
        return isGreater;
    }

    static async checkVersion(version: string): Promise<boolean | null> {
        const isGreater = await FirmwareVersionChekcer.getAndCheckVersion(version);
        if (isGreater === null) return null;
        if (isGreater === true) {
            toast.warn(<>
                {"Your Marty is out of date. Please "}
                <a href="https://updates.robotical.io/web-updater/" target="_blank" rel="noopener noreferrer">
                    update to the latest version
                </a>
                {" to get the latest features and bug fixes."}
            </>,
                {
                    autoClose: 5000,
                }
            );
            return false;
        }
        return true;
    }

    static async loadAllUpdatesFromAWS(): Promise<string | undefined> {
        const s3 = new AWS.S3({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID || "",
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || "",
            region: "eu-west-1",
        });

        const listObjectsParams: AWS.S3.ListObjectsV2Request = {
            Bucket: FirmwareVersionChekcer.BUCKET,
            Prefix: FirmwareVersionChekcer.PREFIX,
            MaxKeys: 5000,
        };

        try {
            let response;
            let objects: any = [];
            do {
                response = await s3.listObjectsV2(listObjectsParams).promise();
                objects = objects.concat(response.Contents);
                // process the objects

                // set the NextContinuationToken for the next iteration
                listObjectsParams.ContinuationToken = response.NextContinuationToken;
            } while (response.IsTruncated);
            const updateFileNames: (string | undefined)[] = objects.map(
                (object: any) => object.Key
            );

            return FirmwareVersionChekcer.getLatestVersion(updateFileNames as string[]);
        } catch (err) {
            console.error(err);
        }
    }

    private static getLatestVersion(files: string[]): string {
        let versions: string[] = [];

        files.forEach((file) => {
            const parts = file.split("/");
            const version = parts[3];

            if (!versions.includes(version)) {
                versions.push(version);
            }
        });

        // keep only the revisions that start with v
        // sort by version number like semver
        versions = versions.sort((a, b) => {
            const aParts = a.split(".");
            const bParts = b.split(".");
            const aMajor = parseInt(aParts[0].replace("v", ""));
            const bMajor = parseInt(bParts[0].replace("v", ""));
            const aMinor = parseInt(aParts[1]);
            const bMinor = parseInt(bParts[1]);
            const aPatch = parseInt(aParts[2]);
            const bPatch = parseInt(bParts[2]);

            if (aMajor > bMajor) {
                return -1;
            } else if (aMajor < bMajor) {
                return 1;
            } else {
                if (aMinor > bMinor) {
                    return -1;
                } else if (aMinor < bMinor) {
                    return 1;
                } else {
                    if (aPatch > bPatch) {
                        return -1;
                    } else if (aPatch < bPatch) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            }
        });

        try {
            return versions[1].replace("v", "");
        } catch {
            return "";
        }

    }

}