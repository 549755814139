import "./styles.css";

interface InputProps {
  label?: string;
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const LabeledInput = ({ label = "", value = "", placeholder, onChange }: InputProps) => (
  <div className="labeled-input-conatiner">
    <p className="labeled-input-label">{label}</p>
    <input className="labeled-input-input" value={value} placeholder={placeholder} onChange={onChange}/>
  </div>
);

export default LabeledInput;
