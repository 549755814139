import styled from "styled-components";

import {
  GREY,
  PALE_WHITE,
  BLACK,
  DASHBORDER_BLUE,
  INDICATOR_RED,
} from "../../styles/colors";

export const Container = styled.div`
  display: grid;
  justify-items: center;
`;

export const Card = styled.div`
  width: 85%;
  margin-bottom: 0.2rem;
  margin-top: 1.5%;
  margin-bottom: 1.5%;
  border-radius: 0.6rem;
  border: 2px solid ${DASHBORDER_BLUE};
  background-color: ${PALE_WHITE};
  border-width: 0.2rem;
  padding-left: 0.5rem;
  align-self: center;
  justify-content: center;
`;

export const CardTitleContainer = styled.div`
  padding: 2% 2% 0% 2%;
  height: 12%;
`;

export const CardTitleText = styled.p`
  text-align: center;
  font-weight: bold;
  font-family: "Lato Regular";
  font-size: 2rem;
  padding-bottom: 2rem;
  color: ${BLACK};
`;

export const CardTextContainer = styled.div`
  display: grid;
  justify-items: center;
  row-gap: 1rem;
  padding: 0% 2% 3% 2%;
  height: 88%;
  width: 98%;
  align-self: center;
`;

export const CardText = styled.p`
  text-align: center;
  color: ${BLACK};
  font-family: "Lato Regular";
  line-height: 1.5;
  width: 100%;
`;

export const RedText = styled.span`
  display: inline-block;
  color: ${INDICATOR_RED};
  font-weight: bold;
`;

export const CardImage = styled.div`
  display: grid;
  justify-self: center;
  margin-bottom: 1%;
  align-self: center;
`;

export const BottomRowContainer = styled.div`
  width: 62%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;

  svg {
    cursor: pointer;
  }
`;

export const BottomText = styled.p`
  text-align: center;
  display: inline-block;
  font-family: "Lato Regular";
  font-weight: bold;
  color: ${BLACK};
  font-style: italic;
  white-space: nowrap;
`;

export const UnpluggedButtonContainer = styled.div`
  width: 25%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const TopContainer = styled.div`
  padding-top: 15px;
  align-items: center;
  justify-content: center;
`;

export const TitleContainer = styled.div``;

export const TextContainer = styled.div`
  flex: 1;
  padding: 16px 16px 0px 16px;
`;

export const Text = styled.p`
  font-size: 15px;
  text-align: center;
  color: ${GREY};
  line-height: 20px;
  margin-top: 8px;
  font-family: "Lato Regular";
  line-height: 22px;
`;

export const TitleText = styled.p`
  text-align: center;
  font-size: 16px;
`;

export const BoldText = styled.p`
  font-weight: bold;
  color: ${BLACK};
`;
