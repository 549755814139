import { createContext, useState } from "react";
import martyConnector from "../MartyConnector/MartyConnector";

export type MartyConnectionContextTypes = {
  isConnectionLoading: boolean;
  setIsConnectionLoading: (isLoading: boolean) => void;
  isConnected: boolean;
  setIsConnected: (isConnected: boolean) => void;
  martyName: string;
  setMartyName: (name: string) => void;
};

const MartyConnectionContext = createContext<MartyConnectionContextTypes>({
  isConnectionLoading: false,
  setIsConnectionLoading: () => {},
  isConnected: false,
  setIsConnected: () => {},
  martyName: "",
  setMartyName: () => {},
});

type MartyConnectionContextProviderTypes = {
  children: React.ReactNode;
};
export function MartyConnectionContextProvider({
  children,
}: MartyConnectionContextProviderTypes) {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isConnectionLoading, setIsConnectionLoading] = useState<boolean>(false);
  const [martyName, setMartyName] = useState("");

  const isConnectedHandler = (isConn: boolean) => {
    setIsConnected(isConn);
  };

  const setIsConnectionLoadingHandler = (isLoading: boolean) => {
    setIsConnectionLoading(isLoading);
  };

  const setMartyNameHandler = (name: string) => {
    setMartyName(name);
  };

  const context = {
    isConnectionLoading: isConnectionLoading,
    setIsConnectionLoading: setIsConnectionLoadingHandler,
    isConnected: isConnected,
    setIsConnected: isConnectedHandler,
    martyName: martyName,
    setMartyName: setMartyNameHandler,
  };
  // we need to wire the context to the marty connector in
  // order to be able to use the context/store outside of react components
  martyConnector.setConnectionCtx(context);

  return (
    <MartyConnectionContext.Provider value={context}>
      {children}
    </MartyConnectionContext.Provider>
  );
}

export default MartyConnectionContext;
