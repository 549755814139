import modalState from "../../state-observables/modal/ModalState";
import { getStoredAnalyticsConsent } from "./cookies/analytics-consent";
import AnalyticsConsentModal from "../../components/modals/AnalyticsConsentModal";
import { DatabaseManager } from "@robotical/analytics-gatherer/dist";
import { createElement } from "react";

export default function analyticsConsentChecking() {
  const consent = getStoredAnalyticsConsent();
  if (consent) {
    const dbManager = DatabaseManager.getInstance();
    dbManager.consent = consent;
  } else {
    // consent is not given at all --showing modal to get consent
    modalState.setModal(createElement(AnalyticsConsentModal), "HELP US IMPROVE MARTY");
  }
}
