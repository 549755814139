import { useState } from 'react';
import styles from './styles.module.css';
import { toast } from 'react-toastify';
import AddMoreRobotsInfo from './AddMoreRobotsInfo';
import { Scanner, outline, boundingBox, centerText, IDetectedBarcode } from '@yudiel/react-qr-scanner';
import { mapMartySnToRicSn } from '../../../../utils/marty-serial-numbers-db-map/marty-serial-numbers-db-map';


type Props = {
  setItems: React.Dispatch<React.SetStateAction<(string | BluetoothDevice)[]>>
};

function QRContent({ setItems }: Props) {
  const [tracker, setTracker] = useState<string | undefined>('centerText');
  // const [serialNumbers, setSerialNumbers] = useState<string[]>([]);

  const onQrCodeFound = async (result: IDetectedBarcode[]) => {
    const rawValue = result[0]?.rawValue;
    if (!rawValue) {
      return;
    }

    const fetchedSn = await mapMartySnToRicSn(rawValue);

    if (!fetchedSn) {
      toast.error(`Serial Number ${rawValue} not found in the database. Please add this Robot using Bluetooth.`, { autoClose: false });
      return;
    }

    setItems(prevSerialNumbers => {
      if (prevSerialNumbers.includes(fetchedSn)) {
        toast.warn(`Serial Number ${fetchedSn} is already in the list.`, { autoClose: 1000 });
        return prevSerialNumbers;
      }

      return [...prevSerialNumbers, fetchedSn];
    });
  };

  

  function getTracker() {
    switch (tracker) {
      case 'outline':
        return outline;
      case 'boundingBox':
        return boundingBox;
      case 'centerText':
        return centerText;
      default:
        return undefined;
    }
  }


  return (
    <div className={styles.container}>
      <AddMoreRobotsInfo />
      <div className={styles.scannerContainer}>
        <Scanner
          formats={[
            'qr_code',
            'micro_qr_code',
            'rm_qr_code',
            'maxi_code',
            'pdf417',
            'aztec',
            'data_matrix',
            'matrix_codes',
            'dx_film_edge',
            'databar',
            'databar_expanded',
            'codabar',
            'code_39',
            'code_93',
            'code_128',
            'ean_8',
            'ean_13',
            'itf',
            'linear_codes',
            'upc_a',
            'upc_e'
          ]}
          allowMultiple={true}
          scanDelay={2000}
          onScan={onQrCodeFound}
          components={{
            audio: true,
            onOff: false,
            torch: true,
            zoom: true,
            finder: true,
            tracker: getTracker()
          }}
        />
      </div>
    </div>
  );
};

export default QRContent;
